<template>
	<div class="skeleton-graph__wrap">
		<lottie
			class="lottie-loader"
			:height="120"
			:width="160"
			:options="options"
		/>
	</div>
</template>

<script>
import Lottie from 'vue-lottie/src/lottie.vue'
import animationData from '../assets/images/lottie/mainLoading.json'

export default {
	name: 'SkeletonGraph',
	components: {
		lottie: Lottie,
	},
	props: {},
	data() {
		return {
			options: {
				animationData: animationData,
			},
		}
	},
	setup() {},
	created() {},
	mounted() {},
	unmounted() {},
	methods: {},
}
</script>
