// 'use strict'

import Vue from "vue";
import axios from "axios";
import store from "../store/index";
import router from "../router/index";

const hostName = store.getters["globalStore/getHostName"];

let config = {
  data: {
    Region: hostName,
  },
};

const _axios = axios.create(config);

const clearSessionStorage = (callback, message) => {
  store.commit("globalStore/setIsAlert", true);
  if (message) {
    alert(message);
  }
  sessionStorage.clear();
  callback();
  throw new axios.Cancel();
};

_axios.interceptors.request.use(
  function (config) {
    const accesstoken = sessionStorage.getItem(`accesstoken`)
      ? sessionStorage.getItem(`accesstoken`)
      : "";
    const standardtoken = sessionStorage.getItem(`standardtoken`)
      ? sessionStorage.getItem(`standardtoken`)
      : "";
    config.headers.accesstoken = accesstoken;
    config.headers.standardtoken = standardtoken;
    return config;
  },
  function (error) {
    if (error.response) {
      router.push("/error");
      return Promise.resolve();
    }
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    const isAlert = store.getters["globalStore/getIsAlert"];
    if (!isAlert) {
      if (response.headers.tokenrefresh === undefined) {
        const access_token = response.headers.accesstoken ?? "";
        sessionStorage.removeItem("accesstoken");
        sessionStorage.setItem("accesstoken", access_token);
      }
      switch (response.status) {
        case 261:
          clearSessionStorage(() => {
            if (router.currentRoute.path !== "/login") {
              router.push("/login");
            }
          }, "에러");
          break;
        case 262:
          clearSessionStorage(() => {
            if (router.currentRoute.path !== "/login") {
              router.push("/login");
              //window.location.href = window.location.href;
            }
          }, "사용시간이 만료되었습니다.");
          break;
        case 401:
          clearSessionStorage(() => {
            if (router.currentRoute.path !== "/login") {
              router.push("/login");
            }
          }, "인증 실패");
          break;
        case 265:
          clearSessionStorage(() => {
            if (router.currentRoute.path !== "/login") {
              router.push("/login");
            }
          }, "중복로그인이 감지되었습니다.");
          break;
        default:
          return response;
      }
      return Promise.resolve(response);
    }
  },
  function (error) {
    if (error.response) {
      router.push("/error");
      return Promise.resolve();
    }
    return Promise.reject(error);
  }
);

// Plugin.install = function (Vue) {
// 	Vue.axios = _axios
// 	window.axios = _axios
// 	Object.defineProperties(Vue.prototype, {
// 		axios: {
// 			get() {
// 				return _axios
// 			},
// 		},
// 		$axios: {
// 			get() {
// 				return _axios
// 			},
// 		},
// 	})
// }

// Vue.use(Plugin)

export { _axios };
// export default _axios;
