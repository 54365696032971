<template>
	<!-- 탭 메뉴 넷 -->
	<div id="cont4" class="tabPanel">
		<h1>지체 및 서비스 수준 판정</h1>

		<!-- 테이블1 : 지체 및 서비스 수준 판정 -->
		<table>
			<colgroup>
				<col width="20%" />
				<col
					v-for="(item, idx) in processingData.length * 3"
					:key="idx"
					:width="`${80 / (processingData.length * 3)}%`"
				/>
			</colgroup>
			<thead>
				<tr>
					<th colspan="13">지체 계산 및 서비스 수준 판정</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td rowspan="2">접근로</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.directionName }}
					</td>
				</tr>
				<tr>
					<td v-for="(item, idx) in processingData.length * 3" :key="idx">
						{{ (idx + 1) % 3 === 0 ? 'RT' : (idx + 1) % 3 === 1 ? 'LT' : 'TH' }}
					</td>
				</tr>
				<!-- <tr>
          <td>초기 대기차량 대수</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx">{{item.data[68]?item.data[68].value:''}}</td>
        </tr> -->
				<!-- <tr>
          <td>추가지체유형</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx">{{item.data[69]?item.data[69].value:''}}</td>
        </tr> -->
				<tr>
					<td>균일지체 ( D1 )</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[70] ? item.data[70].value : ''
						}}{{ item.data[71] ? ` / ${item.data[71].value}` : '' }}
					</td>
				</tr>
				<tr>
					<td>증분지체 ( D2 )</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[72] ? item.data[72].value : ''
						}}{{ item.data[73] ? ` / ${item.data[73].value}` : '' }}
					</td>
				</tr>
				<!-- <tr>
          <td>추가 지체</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx">{{item.data[74]?item.data[74].value:''}}</td>
        </tr>
        <tr>
          <td>순행 시간</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx">{{item.data[75]?item.data[75].value:''}}</td>
        </tr>
        <tr>
          <td>적정 옵셋(초)</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx">{{item.data[76]?item.data[76].value:''}}</td>
        </tr>
        <tr>
          <td>옵셋 편의율</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx">{{item.data[77]?item.data[77].value:''}}</td>
        </tr> -->
				<tr>
					<td>연동계수 ( PF )</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[78] ? item.data[78].value : '' }}1
					</td>
				</tr>
				<tr>
					<td>평균제어지체 ( D1 + D2)</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[79] ? item.data[79].value : ''
						}}{{ item.data[80] ? ` / ${item.data[80].value}` : '' }}
					</td>
				</tr>
				<tr>
					<td>차로군 서비스 수준</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[81] ? losType[item.data[81].value] : ''
						}}{{ item.data[82] ? ` / ${losType[item.data[82].value]}` : '' }}
					</td>
				</tr>
			</tbody>
		</table>
		<!-- 테이블2 : 접근로 분석 -->
		<table>
			<colgroup>
				<col width="20%" />
				<col
					v-for="(item, idx) in processingData.length * 3"
					:key="idx"
					:width="`${80 / (processingData.length * 3)}%`"
				/>
			</colgroup>
			<thead>
				<tr>
					<th colspan="13">접근로 지체 및 서비스 수준</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>접근로 지체</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[83] ? item.data[83].value : '' }}
					</td>
				</tr>
				<tr>
					<td>접근로 서비스 수준</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[84] ? losType[item.data[84].value] : '' }}
					</td>
				</tr>
			</tbody>
		</table>

		<!-- 테이블2 : 접근로 분석 -->
		<table>
			<colgroup>
				<col width="20%" />
				<col
					v-for="(item, idx) in processingData.length * 3"
					:key="idx"
					:width="`${80 / (processingData.length * 3)}%`"
				/>
			</colgroup>
			<thead>
				<tr>
					<th colspan="13">교차로 지체 및 서비스 수준</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>접근로 교통량</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[85] ? item.data[85].value : '' }}
					</td>
				</tr>
				<tr>
					<td>교차로 지체</td>
					<td colspan="12">
						{{
							processingData[0].data[86] ? processingData[0].data[86].value : ''
						}}
					</td>
				</tr>
				<tr>
					<td>교차로 서비스 수준</td>
					<td colspan="12">
						{{
							processingData[0].data[87]
								? this.losType[processingData[0].data[87].value]
								: ''
						}}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
export default {
	data() {
		return {
			losType: {
				0: 'A',
				1: 'B',
				2: 'C',
				3: 'D',
				4: 'E',
				5: 'F',
				6: 'FF',
				7: 'FFF',
			},
		}
	},
	props: {
		processingData: {
			type: Array,
			default() {
				return []
			},
		},
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {},
	},
}
</script>
