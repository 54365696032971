<template>
	<div class="analysis">
		<p>시계열 분석</p>
		<div class="leftPanel">
			<intersectionList
				@update="updateIntersection"
				@select="selectIntersection"
			></intersectionList>
		</div>
		<div class="midPanel">
			<div class="mTopPanel">
				<p>개별 접근로 분석</p>
				<ul>
					<li class="on">회전 교통량</li>
					<li>차로별 교통량</li>
					<li>차종분류</li>
					<li>(현시시작)</li>
					<li>종기 대기길이(현시종료)</li>
					<li>점유율</li>
					<li>속도</li>
					<li>제어지체</li>
					<li>서비스 수준</li>
				</ul>
			</div>
			<div class="mBotPanel"></div>
		</div>
		<div class="rightPanel">
			<div class="chartPanel"></div>
		</div>
	</div>
</template>
<script>
import intersectionList from '@/components/Tree/IntersectionList.vue'

export default {
	data() {
		return {
			intersectionSeq: 0,
		}
	},
	components: {
		intersectionList,
	},
	created() {},
	mounted() {},
	methods: {
		updateIntersection(e) {
			this.intersection = e
		},
		selectIntersection(e) {
			this.intersectionSeq = e
		},
	},
}
</script>
