const globalStore = {
  namespaced: true,
  state: {
    mailFailedCount: 0,
    mapEditor: null,
    dialogMap: {},
    isEditCamera: false,
    cameraIntersection: 0,
    hostName: window.location.hostname.split(".")[0],
    // hostName: 'tmaas',
    isAlert: false,
    menu: true,
    lifecycleLog: true,
    devMode: false,
    trainMode: "default", //학습파일 업로드 모드   (ex: trt, default)
    isChanged: false,
    quaterCheck: {
      isQuater: false,
      nextDate: "",
    },
    dialogSuccess: false,
    main: {
      disCard: true,
      menuIdx: 0, // 메뉴 인덱스
      progressShow: false,
      progressNum: 0,
      mainColor: `white`, // white, red
    },
    channel: {
      index: 0, // 체널 인덱스
      beforeIndex: 0, // 직전의 체널 인덱스
      ofNumber: 0, // 체널 개수
      viewAllScreens: true, // 모니터링 / 셋팅 모드
      disableTab: [], // 메뉴탭 비활성화
    },
    target: {
      intersection: 0,
      avenue: 0,
    },
    cameraTarget: {
      intersectionSeq: 0,
      avenueSeq: 0,
    },
  },
  getters: {
    getMailFailedCount: (state) => {
      return state.mailFailedCount;
    },
    getCameraTarget: (state) => {
      return state.cameraTarget;
    },
    getMapEditor: (state) => {
      return state.mapEditor;
    },
    getDialogMap: (state) => {
      return state.dialogMap;
    },
    getIsEditCamera: (state) => {
      return state.isEditCamera;
    },
    getCameraIntersection: (state) => {
      return state.cameraIntersection;
    },
    getDialogSuccess: (state) => {
      return state.dialogSuccess;
    },
    getHostName: (state) => {
      return state.hostName;
    },
    getIsAlert: (state) => {
      return state.isAlert;
    },
    getTarget: (state) => {
      return state.target;
    },
    getMenu: (state) => {
      return state.menu;
    },
    getQuaterCheck: (state) => {
      return state.quaterCheck;
    },
    getIsChanged: (state) => {
      return state.isChanged;
    },
    GET_LIFECYCLE_EVENTS: (state) => {
      return state.lifecycleLog;
    },
    GET_DEV_MODE: (state) => {
      return state.devMode;
    },
    GET_TRAIN_MODE: (state) => {
      return state.trainMode;
    },
    // main
    GET_DIS_CARD: (state) => {
      return state.main.disCard;
    },
    GET_MENU_IDX: (state) => {
      return state.main.menuIdx;
    },
    GET_PROGRESS_SHOW: (state) => {
      return state.main.progressShow;
    },
    GET_PROGRESS_NUM: (state) => {
      return state.main.progressNum;
    },
    GET_MAIN_COLOR: (state) => {
      return state.main.mainColor;
    },

    //channel
    GET_CH_INDEX: (state) => {
      let tempIndex = state.channel.index;
      if (state.channel.index === undefined)
        tempIndex = String(state.beforeIndex);
      else state.beforeIndex = state.channel.index;
      return tempIndex;
    },
    GET_CH_OF_NUMBER: (state) => {
      return state.channel.ofNumber;
    },
    GET_CH_VIEW_ALL_SCREENS: (state) => {
      return state.channel.viewAllScreens;
    },
    GET_CH_DISABLE_TAB: (state) => {
      return state.channel.disableTab;
    },
  },
  mutations: {
    setMailFailedIncrement: (state) => {
      state.mailFailedCount++;
    },
    setMailFailedReset: (state) => {
      state.mailFailedCount = 0;
    },
    setCameraTarget: (state, payload) => {
      state.cameraTarget = payload;
    },
    setMapEditor: (state, payload) => {
      state.mapEditor = payload;
    },
    setDialogMap: (state, payload) => {
      state.dialogMap = payload;
    },
    setIsEditCamera: (state, payload) => {
      state.isEditCamera = payload;
    },
    setCameraIntersection: (state, payload) => {
      state.cameraIntersection = payload;
    },
    setHostName: (state, payload) => {
      state.hostName = payload;
    },
    setQuaterCheck: (state, payload) => {
      state.quaterCheck = {
        isQuater: payload.isQuater,
        nextDate: payload.nextDate,
      };
    },
    setIsAlert: (state, payload) => {
      state.isAlert = payload;
    },
    setDialogSuccess: (state, payload) => {
      state.dialogSuccess = payload;
    },
    setTarget: (state, payload) => {
      state.target.intersection = payload.intersection;
      state.target.avenue = payload.avenue;
      state.target = { ...state.target };
    },
    setMenu: (state, payload) => {
      state.menu = payload;
    },
    setIsChanged: (state, payload) => {
      state.isChanged = payload;
    },
    SET_MAIN: (state, payload) => {
      if (payload.key === `progressShow` && payload.val === false) {
        state.main.progressNum = 0;
      }
      state.main[payload.key] = payload.val;
    },
    SET_CHANNEL: (state, payload) => {
      if (payload.key === `disableTab`) {
        if (payload.val === null) {
          state.channel.disableTab = [];
        } else {
          for (let i in payload.val) {
            const ch = payload.val[i].Channel;
            if (payload.val[i].State !== undefined) {
              if (payload.val[i].State === `analysis`)
                state.channel.disableTab[ch] = true; // 동작중
              else if (payload.val[i].State === null)
                state.channel.disableTab[ch] = true; // 연결전
              else state.channel.disableTab[ch] = false; // 그 외
            }
          }
        }
        state.channel.disableTab.push();
        return;
      }
      state.channel[payload.key] = payload.val;
    },
  },
  actions: {
    changeMailFailedCount({ commit }, payload) {
      commit("setMailFailedCount", payload);
    },
    changeCameraTarget({ commit }, payload) {
      commit("setCameraTarget", payload);
    },
    changeMapEditor({ commit }, payload) {
      commit("setMapEditor", payload);
    },
    changeDialogMap({ commit }, payload) {
      commit("setDialogMap", payload);
    },
    changeIsEditCamera({ commit }, payload) {
      commit("setIsEditCamera", payload);
    },
    changeCameraIntersection({ commit }, payload) {
      commit("setCameraIntersection", payload);
    },
    changeDialogSuccess({ commit }, payload) {
      commit("setDialogSuccess", payload);
    },
    changeHostName({ commit }, payload) {
      commit("setHostName", payload);
    },
    changeTarget({ commit }, payload) {
      commit("setTarget", payload);
    },
    changeMenu({ commit }, payload) {
      commit("setMenu", payload);
    },
    changeIsChanged({ commit }, payload) {
      commit("setIsChanged", payload);
    },
    changeQuaterCheck({ commit }, payload) {
      commit("setQuaterCheck", payload);
    },
    AC_MAIN({ commit }, payload) {
      commit("SET_MAIN", payload);
    },
    AC_CHANNEL({ commit }, payload) {
      commit("SET_CHANNEL", payload);
    },
  },
};

export default globalStore;
