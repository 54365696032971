<template>
	<div class="">
		<video id="hlsPlayer" controls width="1200" height="900">
			<!-- <source src="/media/cc0-videos/flower.webm" type="video/webm">
      <source src="/media/cc0-videos/flower.mp4" type="video/mp4"> -->
			<!-- <source src="/storage/streaming/test.mp4" type="video/mp4"> -->
			<source
				src="localhost:9300/storage/streaming/tmp.m3u8"
				type="application/x-mpegURL"
			/>
			Sorry, your browser doesn't support embedded videos.
		</video>
	</div>
</template>
<script>
export default {
	data() {
		return {
			//menu: false
		}
	},
	created() {},
	mounted() {
		// var player = videojs('hlsPlayer');
		//  player.play();
	},
}
</script>
