import { mapActions } from 'vuex'

export const dialogMixins = {
	data() {
		return {
			isDialog: false,
			dialogSubmit: false,
			dialogOption: {
				title: '',
				body: '',
				validate: false,
				dupleCheck: false,
				mode: '',
			},
		}
	},
	methods: {
		...mapActions('globalStore', ['changeQuaterCheck', 'changeDialogSuccess']),
		initCheck() {
			this.dialogOption.validate = false
			this.dialogOption.dupleCheck = false
		},
		dialogCloseHandler() {
			this.changeDialogSuccess(false)
			this.isDialog = false
			const actionParams = {
				isQuater: false,
				nextDate: '',
			}
			this.dialogOption = {
				title: '',
				body: '',
				validate: false,
				dupleCheck: false,
				mode: '',
			}
			this.dialogSubmit = false
			this.changeQuaterCheck(actionParams)
		},
	},
}
