<template>
	<article class="meteringWrapper">
		<section>
			<div class="metering-header-top">
				<h1>Metering</h1>
				<div class="metering-header-right">
					<v-dialog v-model="dialogStatus" width="800">
						<template v-slot:activator="{ attrs }">
							<a
								class="metering-price-btn"
								v-bind="attrs"
								v-on:click="dialogHandler"
							>
								요금정책
							</a>
						</template>
						<v-btn icon dark @click="dialogStatus = false">
							<v-icon x-large>mdi-close</v-icon>
						</v-btn>
						<MeteringPriceList />
					</v-dialog>
					<ManagementDatePicker
						@changeDateHander="changeDateHandler($event)"
						v-model="startDate"
						:config="config"
					/>
				</div>
			</div>
			<div class="meteringBottomBody">
				<MeteringHeader :header-dummy-data="totalList" />
				<div class="meteringMiddlePie">
					<div class="meteringContentHeader">
						<span>Charge Distribution</span>
					</div>
					<MeteringPieChart
						:echart-data="planChartProps"
						:pieTrigger="pieTrigger"
					/>
				</div>
				<div class="meteringMiddleGauge">
					<div class="meteringContentHeader">
						<span>DB Usage</span>
					</div>
					<MeteringGaugeChart :echart-data="gaugeChartProps" />
				</div>
			</div>
			<div class="meteringMiddleBody">
				<div class="meteringMiddleGraph">
					<div class="meteringContentHeader">
						<span>API call & Traffic</span>
					</div>
					<MeteringChart
						:echart-data="echartProps"
						:graphTrigger="graphTrigger"
					/>
				</div>
				<div class="meteringMiddleTable">
					<MeteringDbSelect
						:tableData="tableData"
						@priceDialogHandler="priceDialogHandler"
					/>
				</div>
			</div>
			<v-dialog v-model="priceDialogInfo.status" width="400" persistent>
				<v-btn
					icon
					dark
					@click="
						() => {
							priceDialogInfo.status = false
							priceDialogInfo.check = false
						}
					"
				>
					<v-icon x-large>mdi-close</v-icon>
				</v-btn>
				<div class="metering-check-dialog">
					<div>
						<span class="metering-check-title">변경확인</span>
						<div class="metering-check-body">
							<div class="metering-chack-block">
								<span>Volume</span>
								<span>{{ this.priceDialogInfo.volume }} GB</span>
							</div>
							<div class="metering-chack-block">
								<span>Price</span>
								<span>₩ {{ this.priceDialogInfo.price | comma }}</span>
							</div>
							<div>
								<label for="price-change-check">변경사항을 확인했습니다.</label>
								<input
									v-model="priceDialogInfo.check"
									id="price-change-check"
									type="checkbox"
								/>
							</div>
							<div
								:class="[
									priceDialogInfo.check
										? 'metering-check-btn price-checked'
										: 'metering-check-btn price-unchecked',
								]"
								role="button"
								:aria-disabled="priceDialogInfo.check ? 'false' : 'true'"
								@click="selectDBCapacity"
							>
								변경
							</div>
						</div>
					</div>
				</div>
			</v-dialog>
		</section>
	</article>
</template>

<script>
import MeteringHeader from '@/components/MeteringComponent/MeteringHeader.vue'
import MeteringChart from '@/components/MeteringComponent/MeteringChart.vue'
import MeteringGaugeChart from '@/components/MeteringComponent/MeteringGaugeChart.vue'
import MeteringPieChart from '@/components/MeteringComponent/MeteringPieChart.vue'
import ManagementDatePicker from '@/components/Management/ManagementDatePicker.vue'
import MeteringPriceList from '@/components/MeteringComponent/MeteringPriceList.vue'
import MeteringDbSelect from '../../components/MeteringComponent/MeteringDbSelect.vue'
import {
	getDatabaseAPI,
	getApiCallAPI,
	getCapacityDB,
	selectDBAPI,
	getTotalPrice,
	getTrafficAPI,
} from '@/api/index'
import {
	getCurrentDbUsage,
	getCurrentDbPercentAPI,
} from '../../api/trafficAPI/index'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
const globalStore = 'globalStore'

export default {
	components: {
		MeteringHeader,
		MeteringChart,
		MeteringGaugeChart,
		MeteringPieChart,
		ManagementDatePicker,
		MeteringPriceList,
		MeteringDbSelect,
	},
	data() {
		return {
			selectedDB: '',
			pieTrigger: false,
			graphTrigger: false,
			startDate: dayjs().format('YYYY-MM'),
			dialogStatus: false,
			guageData: 0,
			priceDialogInfo: {
				volume: 0,
				price: 0,
				status: false,
				check: false,
			},
			endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
				.toISOString()
				.substr(0, 10),
			config: {
				datePicker: {
					start: false,
					end: false,
				},
			},
			nowDate: dayjs().format('YYYY-MM'),
			tableData: {},
			totalList: {
				database: {
					name: 'DB Usage (GB)',
					value: 0,
					format: 'GB',
					icon: 'mdi-database',
				},
				traffic: {
					name: 'Traffic',
					value: 0,
					format: 'GB',
					icon: 'mdi-network-strength-3',
				},
				apiCall: {
					name: 'API Call',
					value: 0,
					format: '',
					icon: 'mdi-swap-vertical-variant',
				},
				totalPrice: {
					name: 'Total Price',
					value: 0,
					format: '',
					icon: 'mdi-currency-krw',
				},
			},
			chartData: [],
			chartTrafficData: [],
			planData: {
				dbPrice: 0,
				trafficPrice: 0,
				apiPrice: 0,
			},

			chartGaugeData: {
				color: '#39a5d9',
				series: [
					{
						type: 'gauge',
						progress: {
							show: true,
							width: 18,
						},
						top: '10%',
						axisLine: {
							lineStyle: {
								width: 18,
								color: [
									[0.7, '#39a5d9'],
									[100, '#999'],
								],
							},
						},
						axisTick: {
							show: true,
							splitNumber: 4,
							length: 4,
						},
						pointer: {
							length: '60%',
							width: 3,
						},
						splitLine: {
							length: 11,
							lineStyle: {
								width: 2,
								color: '#fefbf3',
							},
						},
						axisLabel: {
							distance: 14,
							color: '#999',
							fontSize: 14,
						},
						title: {
							show: false,
						},
						detail: {
							valueAnimation: true,
							color: '#999',
							fontSize: 32,
							offsetCenter: [0, '70%'],
							formatter: '{value}%',
						},
						data: [
							{
								value: 70,
							},
						],
						radius: 135,
					},
				],
			},
		}
	},
	computed: {
		...mapGetters(globalStore, ['getHostName']),
		gaugeChartProps() {
			const guageValue = this.guageData
			const propsData = {
				color: '#39a5d9',
				series: [
					{
						...this.chartGaugeData.series[0],
						axisLine: {
							lineStyle: {
								width: 12,
								color: [
									[guageValue / 100, '#39a5d9'],
									[100, '#999'],
								],
							},
						},
						data: [
							{
								value: guageValue.toFixed(2),
							},
						],
					},
				],
			}
			return propsData
		},
		planChartProps() {
			const dbPrice = this.planData.dbPrice
			const trafficPrice = this.planData.trafficPrice
			const apiPrice = this.planData.apiPrice
			const sourceData = {
				backgroundColor: '#191A1B',
				tooltip: {
					trigger: 'item',
				},
				legend: {
					orient: 'vertical',
					left: 'left',
				},
				grid: {
					containLabel: true,
				},
				series: [
					{
						name: 'Access From',
						type: 'pie',
						data: [
							{ value: dbPrice, name: 'Database' },
							{ value: trafficPrice, name: 'Traffic' },
							{ value: apiPrice, name: 'API call' },
						],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)',
							},
						},
						radius: 110,
					},
				],
			}
			return sourceData
		},
		echartProps() {
			const chartData = this.chartData?.map(v => v[0])
			const apiCallValue = this.chartData?.map(v => v[1])
			const trafficValue = this.chartTrafficData.map(v => v[1])

			if (apiCallValue.length > 0 && trafficValue.length > 0) {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.graphTrigger = true
			}

			const chartOption = {
				// backgroundColor: "#121212",
				color: ['#39a5d9', '#7ac042'],
				xAxis: {
					type: 'category',
					data: chartData,
					axisLabel: {
						fontSize: 14,
					},
				},
				yAxis: [
					{
						name: 'traffic (GB)',
						type: 'value',
						axisLabel: {
							fontSize: 14,
						},
						nameTextStyle: {
							fontSize: 18,
							fontWeight: 'bold',
							lineHeight: 40,
						},
					},
					{
						name: 'API call',
						type: 'value',
						splitLine: {
							// show: false,
						},
						axisLabel: {
							fontSize: 14,
						},
						nameTextStyle: {
							fontSize: 16,
							fontWeight: 'bold',
							lineHeight: 40,
						},
					},
				],
				legend: {
					data: ['traffic', 'API call'],
					textStyle: {
						color: '#fff',
						fontSize: 16,
					},
					right: '14%',
				},
				series: [
					{
						name: 'traffic',
						data: trafficValue,
						type: 'line',
						smooth: false,
						symbolSize: 6,
						areaStyle: {
							color: 'rgba(57, 165, 217, 0.5)',
						},
						lineStyle: {
							color: '#39a5d9',
						},
					},
					{
						name: 'API call',
						data: apiCallValue,
						yAxisIndex: 1,
						type: 'line',
						smooth: false,
						symbolSize: 6,
						lineStyle: {
							color: '#7ac042',
						},
						areaStyle: {
							color: 'rgba(122, 192, 66, 0.3)',
						},
					},
				],
				grid: {
					left: '5%',
					right: '5%',
					bottom: '4%',
					containLabel: true,
				},
				tooltip: {
					trigger: 'item',
				},
				textStyle: {
					color: '#f8f9fa',
				},
			}
			return chartOption
		},
	},
	created() {},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			this.initCapacity()
			if (this.nowDate === this.startDate) {
				this.currentDbUsage()
			} else {
				this.totalHeaderDB()
			}
			this.getCurrentGuageDb()
			this.totalHeaderApiCall()
			this.totalPriceAPI()
			this.capacityDB()
			this.totalHeaderTraffic()
		},
		initCapacity() {
			this.tableData = {
				10: {
					id: 1,
					volume: 10,
					btnText: 'Change',
					price: 30000,
					status: 'Available',
					available: true,
				},
				20: {
					id: 2,
					volume: 20,
					btnText: 'Change',
					price: 50000,
					status: 'Available',
					available: true,
				},
				50: {
					id: 3,
					volume: 50,
					btnText: 'Change',
					price: 80000,
					status: 'Available',
					available: true,
				},
				100: {
					id: 4,
					volume: 100,
					btnText: 'Change',
					price: 100000,
					status: 'Available',
					available: true,
				},
			}
		},
		currentDbUsage() {
			getCurrentDbUsage().then(res => {
				this.totalList.database.value = this.fixedFloatFormatter(
					res.data.data[0]?.TotalUsage || 0,
				)
			})
		},
		getCurrentGuageDb() {
			getCurrentDbPercentAPI().then(res => {
				this.guageData = res.data.data
			})
		},
		changeSoureData(dbPrice, trafficPrice, apiPrice) {
			const option = {
				...this.sourceData,
			}
			option.series[0].data[0] = dbPrice
			option.series[0].data[1] = trafficPrice
			option.series[0].data[2] = apiPrice
			return option
		},
		totalPriceAPI() {
			getTotalPrice(this.getHostName, this.startDate).then(res => {
				this.totalList.totalPrice.value = this.numberFormatter(
					(res.dbData[0]?.price || 0) + res.callData + res.trafficData,
				)
				this.planData.dbPrice = res.dbData[0]?.price || 0
				this.planData.trafficPrice = res.trafficData
				this.planData.apiPrice = res.callData
				this.pieTrigger = true
			})
		},
		totalHeaderTraffic() {
			getTrafficAPI(this.getHostName, this.startDate).then(res => {
				this.totalList.traffic.value = this.fixedFloatFormatter(
					res.data?.data[1][0]?.MonthTraffic || 0,
				)
				this.chartTrafficData = res.data.data[0].map(v => {
					return [v.CollectedDate.match(/(^\d*-\d*-\d*)/)[0], v.DailyTraffic]
				})
			})
		},
		totalHeaderDB() {
			getDatabaseAPI(this.startDate).then(res => {
				this.totalList.database.value = this.fixedFloatFormatter(
					res.data.data[0]?.UsedVolume || 0,
				)
			})
		},
		totalHeaderApiCall() {
			getApiCallAPI(this.getHostName, this.startDate).then(res => {
				this.totalList.apiCall.value = res.data.data[1][0].TotalApiCall || 0
				this.chartData = res.data.data[0].map(v => {
					return [v.MonthApiCall.match(/(^\d*-\d*-\d*)/)[0], v.Apicall]
				})
			})
		},
		capacityDB() {
			getCapacityDB(this.getHostName).then(res => {
				const capacity = res.data.data[0]?.UsedDbCapacity || 10
				this.tableData[capacity].btnText = 'Used'
				this.tableData[capacity].status = 'In Used'
				this.tableData[capacity].available = false
			})
		},
		selectDBCapacity() {
			const changeTargetDB = this.priceDialogInfo.volume
			selectDBAPI(changeTargetDB).then(res => {
				if (res.data.success) {
					this.priceDialogInfo.check = false
					this.priceDialogInfo.status = false
					alert('변경을 성공했습니다.')
					this.init()
				}

				switch (res.status) {
					case 240:
						alert('현재 사용량보다 낮은 용량은 선택할 수 없습니다.')
						this.priceDialogInfo.status = false
						this.priceDialogInfo.check = false
						break
				}
			})
		},
		priceDialogHandler(volume, price) {
			this.priceDialogInfo.status = true
			this.priceDialogInfo.volume = volume
			this.priceDialogInfo.price = price
		},
		changeDateHandler(mode) {
			const nextDate = dayjs(this.startDate).add(1, 'month').format('YYYY-MM')
			const diffDate = dayjs().diff(nextDate)
			if (mode === 'next' && diffDate >= 0) {
				this.startDate = nextDate
			} else if (mode === 'prev') {
				this.startDate = dayjs(this.startDate)
					.subtract(1, 'month')
					.format('YYYY-MM')
			}
		},
		dialogHandler() {
			this.dialogStatus = true
		},
		numberFormatter(value) {
			const formatNumber = value
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
			return formatNumber
		},
		fixedFloatFormatter(value) {
			const returnValue = value.toFixed(2)
			return returnValue
		},
	},
	watch: {
		startDate: _.debounce(function (value) {
			if (value === this.nowDate) {
				this.currentDbUsage()
			} else {
				this.totalHeaderDB()
			}
			this.getCurrentGuageDb()
			this.totalHeaderApiCall()
			this.totalHeaderTraffic()
			this.totalPriceAPI()
		}, 300),
	},
}
</script>
