<template>
	<div class="">차량카운팅영역설정</div>
</template>
<script>
export default {
	data() {
		return {
			//menu: false
		}
	},
	created() {},
}
</script>
