<template>
	<div class="">교차로정보 입력</div>
</template>
<script>
export default {
	data() {
		return {
			//menu: false
		}
	},
	created() {},
}
</script>
