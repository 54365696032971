<template>
	<!-- 탭 메뉴 둘 -->
	<div id="cont2" class="tabPanel">
		<h1>교통량 보정 및 차로군 분류</h1>
		<!-- 테이블1: 교통량 보정 -->
		<table>
			<colgroup>
				<col width="20%" />
				<col
					v-for="(item, idx) in processingData.length * 3"
					:key="idx"
					:width="`${80 / (processingData.length * 3)}%`"
				/>
			</colgroup>
			<thead>
				<tr>
					<th :colspan="processingData.length * 3 + 1">교통량 보정</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td rowspan="2">교통량보정</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.directionName }}
					</td>
				</tr>
				<tr>
					<td v-for="(item, idx) in processingData.length * 3" :key="idx">
						{{ (idx + 1) % 3 === 0 ? 'RT' : (idx + 1) % 3 === 1 ? 'LT' : 'TH' }}
					</td>
				</tr>
				<tr>
					<td>교통량(vph)</td>
					<td v-for="(item, idx) in processingData.length * 3" :key="idx">
						{{
							processingData[Math.floor(idx / 3)]
								? processingData[Math.floor(idx / 3)].data[4 + (idx % 3)]
									? processingData[Math.floor(idx / 3)].data[4 + (idx % 3)]
											.value
									: ''
								: ''
						}}
					</td>
				</tr>
				<tr>
					<td>차로이용률계수</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[21] ? item.data[21].value : '' }}
					</td>
				</tr>
				<tr>
					<td>RTOR보정</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[22] ? item.data[22].value : '' }}
					</td>
				</tr>
				<tr>
					<td>보정교통량</td>
					<td v-for="(item, idx) in processingData.length * 3" :key="idx">
						{{
							processingData[Math.floor(idx / 3)]
								? processingData[Math.floor(idx / 3)].data[4 + (idx % 3)]
									? processingData[Math.floor(idx / 3)].data[4 + (idx % 3)]
											.value
									: ''
								: ''
						}}
					</td>
				</tr>
			</tbody>
		</table>

		<!-- 테이블2: 차로군 분류 -->
		<table>
			<colgroup>
				<col width="20%" />
				<col
					v-for="(item, idx) in processingData.length * 3"
					:key="idx"
					:width="`${80 / (processingData.length * 3)}%`"
				/>
			</colgroup>
			<thead>
				<tr>
					<th :colspan="processingData.length * 3 + 1">차로군 분류</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td rowspan="2">접근로<br /><br />차로수</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.directionName }}
					</td>
				</tr>
				<tr>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[26] ? item.data[26].value : '' }}
					</td>
				</tr>
				<!-- <tr>
          <td>보정 대항직진교통량</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx">{{item.data[27]?item.data[27].value:''}}</td>
        </tr>
        <tr>
          <td>비보호간격수락률</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx">{{item.data[28]?item.data[28].value:''}}</td>
        </tr> -->
				<tr>
					<td>좌회전직진환산 계수</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[29] ? item.data[29].value : '' }}
					</td>
				</tr>
				<tr>
					<td>좌회전 곡선반경 영향</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[30] ? item.data[30].value : '' }}
					</td>
				</tr>
				<tr>
					<td>U턴 영향</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[31] ? item.data[31].value : '' }}
					</td>
				</tr>
				<tr>
					<td>좌회전 종합 직진환산계수</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[32] ? item.data[32].value : '' }}
					</td>
				</tr>
				<!-- <tr>
          <td>진출입로 방향</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx"></td>
        </tr>
        <tr>
          <td>버스정차 방향</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx"></td>
        </tr>
        <tr>
          <td>버스정류장 위치</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx"></td>
        </tr>
        <tr>
          <td>버스 영향</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx"></td>
        </tr>
        <tr>
          <td>노선주차영향</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx"></td>
        </tr> -->
				<tr>
					<td>노변마찰의 영향</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[33] ? item.data[33].value : '' }}0
					</td>
				</tr>
				<tr>
					<td>우회전 횡단차단</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[34] ? item.data[34].value : '' }}
					</td>
				</tr>
				<tr>
					<td>우회전차로 직진환산계수</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[35] ? item.data[35].value : '' }}
					</td>
				</tr>
				<tr>
					<td>VLF</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[36] ? item.data[36].value : '' }}
					</td>
				</tr>
				<tr>
					<td>VRF</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[37] ? item.data[37].value : '' }}
					</td>
				</tr>
				<tr>
					<td>VSTL</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[38] ? item.data[38].value : '' }}
					</td>
				</tr>
				<tr>
					<td>VSTR</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[39] ? item.data[39].value : '' }}
					</td>
				</tr>
				<tr>
					<td>차로군분류</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[40] ? item.data[40].value : '' }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	props: {
		processingData: {
			type: Array,
			default() {
				return []
			},
		},
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {},
	},
}
</script>
