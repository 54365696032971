<template>
  <div class="signal">
    <div class="container">
      <h1>전후 영향 분석</h1>
      <div class="wrap">
        <ul class="setting_box">
          <li>
            <strong>교차로 명칭</strong>
            <div class="search_box">
              <div class="search">
                <v-select
                  v-model="intersectionSeq"
                  :items="intersectionItem"
                  dense
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#1976d2"
                  dark
                ></v-select>
              </div>
            </div>
          </li>
          <li>
            <strong>기준 날짜</strong>
            <v-menu
              v-model="config.datePicker.start"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="#1976d2"
                  dark
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                @input="config.datePicker.start = false"
              >
              </v-date-picker>
            </v-menu>
          </li>
          <li>
            <strong>비교 날짜</strong>
            <v-menu
              v-model="config.datePicker.end"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="#1976d2"
                  dark
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                @input="config.datePicker.end = false"
              >
              </v-date-picker>
            </v-menu>
          </li>
          <li>
            <strong>비교 시간대</strong>
            <v-menu
              ref="menu"
              v-model="config.tiemPicker.start"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startTime"
                  prepend-icon="mdi-calendar-clock"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="#1976d2"
                  dark
                >
                </v-text-field>
              </template>
              <v-time-picker
                v-model="startTime"
                @click:minute="$refs.menu.save(startTime)"
                ampm-in-title
                scrollable
              >
              </v-time-picker>
            </v-menu>
            <p>~</p>
            <v-menu
              ref="menu"
              v-model="config.tiemPicker.end"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endTime"
                  prepend-icon="mdi-calendar-clock"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="#1976d2"
                  dark
                >
                </v-text-field>
              </template>
              <v-time-picker
                v-model="endTime"
                @click:minute="$refs.menu.save(endTime)"
                ampm-in-title
                scrollable
              >
              </v-time-picker>
            </v-menu>
          </li>
        </ul>
        <div class="analysis_wrap">
          <div class="table_wrap">
            <div class="table_content">
              <strong>전/후 교통 정보</strong>
              <table>
                <caption class="hidden">
                  전/후 교통 정보
                </caption>
                <thead>
                  <tr>
                    <th>일자</th>
                    <th>교통량</th>
                    <th>제어지체</th>
                    <th>LOS</th>
                    <th>속도</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ startDate }}</td>
                    <td>
                      {{ beforeTotalTrafficValue | comma }}
                    </td>
                    <td>{{ beforeDelayValue.value }}</td>
                    <td>{{ beforeDelayValue.level }}</td>
                    <td>{{ beforeSpeedData }}</td>
                  </tr>
                  <tr>
                    <td>{{ endDate }}</td>
                    <td>{{ afterTotalTrafficValue | comma }}</td>
                    <td>{{ afterDelayValue.value }}</td>
                    <td>{{ afterDelayValue.level }}</td>
                    <td>{{ afterSpeedData }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table_content">
              <strong>전/후 차종별 교통 편익 분석</strong>
              <table>
                <caption class="hidden">
                  전/후 차종별 교통 편익 분석
                </caption>
                <thead>
                  <tr>
                    <th>일자</th>
                    <th>승용차</th>
                    <th>소형트럭</th>
                    <th>대형트럭</th>
                    <th>소형버스</th>
                    <th>대형버스</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>{{ startDate }}</th>
                    <td>2,549</td>
                    <td>251</td>
                    <td>38</td>
                    <td>0</td>
                    <td>80</td>
                  </tr>
                  <tr>
                    <th>{{ endDate }}</th>
                    <td>3,041</td>
                    <td>302</td>
                    <td>47</td>
                    <td>3</td>
                    <td>97</td>
                  </tr>
                  <tr>
                    <th>편익 금액</th>
                    <td>4,891,956원</td>
                    <td>835,074원</td>
                    <td>147,366원</td>
                    <td>67,494원</td>
                    <td>382,466원</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="map_wrap">
            <div class="map_layout">
              <div id="map" class="darkmode"></div>
            </div>
          </div>
        </div>
        <div class="chart_wrap">
          <strong>전/후 교통 정보 통계</strong>
          <ul>
            <li>
              <SignReportChart :chartData="costChartData" />
            </li>
            <li>
              <SignReportChart :chartData="speedChartData" />
            </li>
            <li>
              <SkeletonGraph v-if="isLoading.los" />
              <SignReportChart
                v-show="!isLoading.los"
                :chartData="losChartData"
              />
            </li>
            <li>
              <SkeletonGraph v-if="isLoading.traffic" />
              <SignReportChart
                v-show="!isLoading.traffic"
                :chartData="trafficChartData"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getIntersectionListAPI } from "../../api/trafficAPI";
import { getEachDataAPI } from "../../api/index";
import { mapGetters } from "vuex";
import SignReportChart from "../../components/Chart/SignReportChart.vue";
import SkeletonGraph from "../../components/SkeletonGraph.vue";

const globalStore = "globalStore";
let map = null;
export default {
  name: "SignReport",
  components: {
    SignReportChart,
    SkeletonGraph,
  },
  data() {
    return {
      startDate: "2023-11-16",
      endDate: "2023-11-16",
      startTime: "00:00",
      endTime: "23:00",
      config: {
        datePicker: {
          start: false,
          end: false,
        },
        tiemPicker: {
          start: false,
          end: false,
        },
      },
      intersectionSeq: 13,
      intersectionItem: null,
      trafficData: null,
      delayData: null,
      beforeSpeedData: null,
      afterSpeedData: null,
      losLevel: null,
      isLoading: {
        traffic: false,
        los: false,
      },
      costChartData: {
        chartData: null,
        name: "costChart",
        title: "일일 교통 편익비용 증감 추세",
        axisData: null,
      },
      trafficChartData: {
        chartData: null,
        name: "trafficChart",
        title: "교통량 그래프",
        axisData: null,
      },
      losChartData: {
        chartData: null,
        name: "losChart",
        title: "제어지체",
      },
      speedChartData: {
        chartData: null,
        name: "speedChart",
        title: "속도",
      },
    };
  },
  setup() {},
  created() {},
  mounted() {
    window.kk = this;
    if (window.kakao && window.kakao.maps) {
      this.initMap();
    }
    window.addEventListener("resize", () => {
      map.relayout();
      map.setCenter(
        new kakao.maps.LatLng(37.40060059111524, 126.93756672607128)
      );
    });
    this.init();
  },
  unmounted() {},
  methods: {
    init() {
      this.getIntersectionMenuData();
      this.getTrafficData();
      this.getDelayData();
      this.getSpeedData();
      this.getCostData();
    },
    initMap() {
      const container = document.getElementById("map");
      const options = {
        center: new kakao.maps.LatLng(37.40060059111524, 126.93756672607128),
        level: 7,
      };
      map = new kakao.maps.Map(container, options);
    },
    getIntersectionMenuData() {
      getIntersectionListAPI({
        intersectionSeq: "",
      })
        .then((res) => {
          if (res.data.success) {
            const temp = [];
            const data = res.data.data;
            data[0].forEach((el) => {
              temp.push({
                text: el.IntersectionName,
                value: el.IntersectionSeq,
              });
            });
            this.intersectionItem = temp;
            this.intersectionSeq = temp[0].value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSpeedData() {
      this.beforeSpeedData = 20 + this.makeRandomValue(1, 10);
      this.afterSpeedData = 20 + this.makeRandomValue(1, 10);
      this.speedChartData.chartData = [
        this.beforeSpeedData,
        this.afterSpeedData,
      ];
    },
    getCostData() {
      const startTimeValue = Number(this.startTime.split(":")[0]);
      const endTimeValue = Number(this.endTime.split(":")[0]);
      const diffTime = endTimeValue - startTimeValue;
      const beforeValueArr = [];
      const afterValueArr = [];
      const xAxisValue = [];
      if (diffTime) {
        for (let i = 0; i < diffTime; i++) {
          const beforeRandomValue = this.makeRandomValue(100, 2000);
          const afterRandomValue =
            beforeRandomValue >= 600
              ? beforeRandomValue - this.makeRandomValue(100, 600)
              : 0;
          beforeValueArr.push(beforeRandomValue);
          afterValueArr.push(afterRandomValue);
          xAxisValue.push(`${i}:00`);
        }
        this.costChartData.axisData = xAxisValue;
        this.costChartData.chartData = [beforeValueArr, afterValueArr];
      }
      return;
    },
    getTrafficData() {
      const url = "/api/traffic/analysis/each/traffic";
      const apiParams = {
        intersectionSeq: this.intersectionSeq,
        unit: 3,
        startDate: `${this.startDate} ${this.startTime}`,
        endDate: `${this.endDate} ${this.endTime}:59`,
      };
      this.isLoading.traffic = true;
      getEachDataAPI(url, apiParams).then((res) => {
        if (res.data.success) {
          const data = res.data.data;
          this.trafficData = data;
        }
        this.isLoading.traffic = false;
      });
    },
    getDelayData() {
      const url = "/api/los/analysis/each/trafficByDelay";
      const apiParams = {
        intersectionSeq: this.intersectionSeq,
        unit: 3,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      this.isLoading.los = true;
      getEachDataAPI(url, apiParams).then((res) => {
        if (res.data.success) {
          const data = res.data.data;
          const compData = Math.floor(data[0]?.Value);
          this.delayData = data;
          this.losChartData.chartData = [
            compData,
            compData + this.makeRandomValue(1, 20),
          ];
          this.isLoading.los = false;
        }
      });
    },
    makeRandomValue(min, max) {
      return Math.floor(Math.random() * max) + min;
    },
  },
  computed: {
    ...mapGetters(globalStore, ["getHostName"]),
    beforeTotalTrafficValue() {
      const value = this.trafficData?.reduce(
        (acc, cur) => (acc += cur.Value),
        0
      );
      return value;
    },

    afterTotalTrafficValue() {
      const value = this.trafficData?.reduce(
        (acc, cur) => (acc += cur.Value + this.makeRandomValue(1, 100)),
        0
      );
      return value;
    },
    beforeDelayValue() {
      if (this.delayData) {
        const value = Math.floor(this.delayData[0]?.Value);
        const level = this.delayData[0]?.LevelofService;
        return {
          value,
          level,
        };
      }
      return {
        value: "",
        level: "",
      };
    },
    afterDelayValue() {
      if (this.delayData) {
        const level = this.delayData[0]?.LevelofService;
        const value = Math.floor(
          this.delayData[0]?.Value + this.makeRandomValue(1, 10)
        );
        return {
          value,
          level,
        };
      }
      return {
        value: "",
        level: "",
      };
    },
  },
  watch: {
    trafficData(value) {
      const beforeChartArr = [];
      const afterChartArr = [];
      const axisData = [];
      for (let i = 0; i < value.length; i++) {
        beforeChartArr.push(value[i].Value);
        afterChartArr.push(
          value[i].Value >= 500
            ? value[i].Value -
                value[i].Value / 30 -
                this.makeRandomValue(1, 500)
            : 0
        );
        axisData.push(`${i}:00`);
      }

      this.trafficChartData.chartData = [beforeChartArr, afterChartArr];
      this.trafficChartData.axisData = axisData;
    },
    intersectionSeq() {
      this.getTrafficData();
      this.getDelayData();
    },
    startDate() {
      this.getTrafficData();
      this.getDelayData();
    },
    endDate() {
      this.getTrafficData();
      this.getDelayData();
    },
    startTime() {
      this.getTrafficData();
      this.getCostData();
    },
    endTime() {
      this.getTrafficData();
      this.getCostData();
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0px;
  color: #afac89;
  display: inline-block;
  width: 200px;
}
.signal {
  overflow: hidden;
}

.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.analysis_wrap {
  display: flex;
  width: 100%;
}
.setting_box {
  display: flex;
  padding: 0;
  align-items: center;
  margin-bottom: 1rem;
}

.setting_box li {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.setting_box strong {
  padding-left: 1rem;
}

.table_wrap {
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1rem 1rem;
  background-color: #343a4030;
}

.table_content {
  width: 100%;
}

.table_content table th {
  min-width: 100px;
}
.chart_wrap strong,
.table_content strong {
  padding: 0.5rem;
  display: block;
}
.map_wrap {
  width: 35%;
  padding: 1rem;
  background-color: #343a4030;
}

.map_layout {
  width: 100%;
  height: 100%;
}

#map {
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.8;
}

.chart_wrap {
  width: 100%;
  padding: 0 1rem;
  background-color: #343a4030;
}

.chart_wrap ul {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  padding: 0;
}

.chart_wrap ul > li {
  height: 300px;
  width: 100%;
  overflow: hidden;
}

.chart_wrap ul > li > div {
  margin-top: 1rem;
}
</style>
