<template>
  <div class="tree" >
    <p class="treeTitle">{{title}}</p>
    <ul>
      <li v-for="(item,idx) in analysisList" :key="idx"
      :class="{on : item.classIf, 
      type01 : item.type===1, 
      type02 : item.type===2, 
      type03 : item.type===3, 
      type04 : item.type===4, 
      type05 : item.type===5, 
      type06 : item.type===6, 
      type07 : item.type===7}"
      @click="clickItem(item, idx)">{{item.analysisName}}</li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'viewDataList',
    data () {
      return {
        analysisList : []

      }
    },
    props:{
      title : {
        type :String,
        default : '분석'
      },
      type : {
        type :String,
        default : 0
      },
      target : {
        type :Number,
        default : 0
      }
    },
    mounted(){
      this.init();
    },
    methods: {
      init(){
        let analysisListType = [
          [['교차로 교통량', 1], ['접근로별 교통량', 1], ['차종분류', 2], ['교차로 제어지체', 3], ['접근로 제어지체', 3], ['교차로 서비스 수준', 4], ['접근로 서비스 수준', 4]],
          [['교통량', 1], ['교통량(접근로)', 1], ['교통량(회전)', 1], ['차량종류', 2], ['제어지체', 3], ['서비스 수준', 4]],
          [['교차로 교통량', 1], ['접근로 별 교통량', 1], ['차종분류', 2], ['교차로 제어지체', 3], ['접근로 제어지체', 3], ['교차로 서비스 수준', 4], ['접근로 서비스 수준', 4]],
          [['회전 교통량', 1], ['차로별 교통량', 1], ['차종분류', 2], ['초기 대기길이(현시시작)', 5], ['종기 대기길이(현시종료)', 5], ['점유율', 6], ['속도', 7], ['제어지체', 3], ['서비스 수준', 4]],
          [['교차로 교통량', 1], ['접근로 별 교통량', 1], ['차종분류', 2], ['교차로 제어지체', 3], ['교차로 서비스 수준', 4]]
        ]
        
        analysisListType[this.type].forEach((el,idx)=>{
          if(this.target === idx){
            this.analysisList.push({analysisName : el[0], classIf:true, type:el[1]})
          }else{
            this.analysisList.push({analysisName : el[0], classIf:false, type:el[1]})
          }
        })
      },
      clickItem(e, idx){
        
        this.analysisList.forEach(el => {
          el.classIf = false
        });
        
        e.classIf = true
        this.$emit("select", idx + 1)
      }
    }
  }
</script>