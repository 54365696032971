<template>
	<div>
		<!-- 테이블2: 교통량 및 도로 -->
		<table>
			<colgroup>
				<col width="20%" />
				<col
					v-for="(item, idx) in processingData.length * 3"
					:key="idx"
					:width="`${80 / (processingData.length * 3)}%`"
				/>
			</colgroup>
			<thead>
				<tr>
					<th :colspan="processingData.length * 3 + 1">교통량 및 도로</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td rowspan="2">접근류 및 이동류</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.directionName }}
					</td>
				</tr>
				<tr>
					<td v-for="(item, idx) in processingData.length * 3" :key="idx">
						{{ (idx + 1) % 3 === 0 ? 'RT' : (idx + 1) % 3 === 1 ? 'LT' : 'TH' }}
					</td>
				</tr>
				<tr>
					<td>교통량(vph)</td>
					<td v-for="(item, idx) in processingData.length * 3" :key="idx">
						{{
							processingData[Math.floor(idx / 3)]
								? processingData[Math.floor(idx / 3)].data[4 + (idx % 3)]
									? processingData[Math.floor(idx / 3)].data[4 + (idx % 3)]
											.value
									: ''
								: ''
						}}
					</td>
				</tr>
				<tr>
					<td>U턴교통량(vph)</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[7] ? item.data[7].value : '' }}
					</td>
				</tr>
				<!-- <tr>
          <td>진입교통량(vph)</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx"></td>
        </tr>
        <tr>
          <td>진출교통량(vph)</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx"></td>
        </tr>
        <tr>
          <td>버스정차대수(vph)</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx"></td>
        </tr>
        <tr>
          <td>주차여부(O,X)</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx"></td>
        </tr>
        <tr>
          <td>주차활동대수(vph)</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx"></td>
        </tr>
        <tr>
          <td>횡단보행자수(인/시)</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx"></td>
        </tr> -->
				<tr>
					<td>보행신호시간(초)</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[8] ? item.data[8].value : '' }}
					</td>
				</tr>
				<!-- <tr>
          <td>상류링크길이(m))</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx">{{item.data[9]?item.data[9].value:''}}</td>
        </tr>
        <tr>
          <td>순행속도(kph)</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx">{{item.data[10]?item.data[10].value:''}}</td>
        </tr> -->
				<tr>
					<td>경사(%)</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[11] ? item.data[11].value : '' }}0
					</td>
				</tr>
				<tr>
					<td>평균차로폭(m)</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[12] ? item.data[12].value : '' }}3.3
					</td>
				</tr>
				<!-- <tr>
          <td>버스정류장길이(m)</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx"></td>
        </tr> -->
				<tr>
					<td>좌회전곡선반경(m)</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[13] ? item.data[13].value : '' }}
					</td>
				</tr>
			</tbody>
		</table>
		<br />
		<!-- 테이블3: 신호현시 및 도로기하구조 -->
		<table class="signalTb">
			<colgroup>
				<col width="13%" />
				<col width="7%" />
				<col width="7%" />
				<col width="7%" />
				<col width="7%" />
				<col width="7%" />
				<col width="7%" />
				<col width="7%" />
				<col width="7%" />
				<col width="7%" />
			</colgroup>
			<thead>
				<tr>
					<th colspan="11">신호현시 및 도로기하구조</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td colspan="2">주기시간</td>
					<td colspan="8">{{ this.cycle }}</td>
				</tr>
				<tr>
					<td colspan="2">현시</td>
					<td v-for="idx in 8" :key="idx">{{ idx }}</td>
					<td rowspan="7">
						<!-- <button type="button" onclick="location.href='added.html'">기하구조설정</button> -->
					</td>
				</tr>
				<tr>
					<td rowspan="2">녹색시간(초)</td>
					<td>A링</td>
					<td v-for="(item, idx) in signalData.greenTime" :key="idx">
						{{ item.ARingTime }}
					</td>
					<td
						v-for="idx in 8 - signalData.greenTime.length"
						:key="idx + signalData.greenTime.length"
					></td>
				</tr>
				<tr>
					<td>B링</td>
					<td v-for="(item, idx) in signalData.greenTime" :key="idx">
						{{ item.BRingTime }}
					</td>
					<td
						v-for="idx in 8 - signalData.greenTime.length"
						:key="idx + signalData.greenTime.length"
					></td>
				</tr>
				<tr>
					<td rowspan="2">황색시간(초)</td>
					<td>A링</td>
					<td v-for="(item, idx) in signalData.greenTime" :key="idx">3</td>
					<td
						v-for="idx in 8 - signalData.greenTime.length"
						:key="idx + signalData.greenTime.length"
					></td>
				</tr>
				<tr>
					<td>B링</td>
					<td v-for="(item, idx) in signalData.greenTime" :key="idx">3</td>
					<td
						v-for="idx in 8 - signalData.greenTime.length"
						:key="idx + signalData.greenTime.length"
					></td>
				</tr>
				<tr>
					<td rowspan="2">이동류</td>
					<td>A링</td>
					<td
						class="aproch"
						v-for="(item, idx) in signalData.movement"
						:key="idx"
					>
						<img :src="aprochDirection[item.ARingADType]" />
					</td>
					<td
						v-for="idx in 8 - signalData.movement.length"
						:key="idx + signalData.movement.length"
					></td>
				</tr>
				<tr>
					<td>B링</td>
					<td
						class="aproch"
						v-for="(item, idx) in signalData.movement"
						:key="idx"
					>
						<img :src="aprochDirection[item.BRingADType]" />
					</td>
					<td
						v-for="idx in 8 - signalData.movement.length"
						:key="idx + signalData.movement.length"
					></td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
export default {
	data() {
		return {
			aprochDirection: {
				0: require('@/assets/images/icon/etc/ad0.png'),
				1: require('@/assets/images/icon/etc/ad1.png'),
				2: require('@/assets/images/icon/etc/ad2.png'),
				3: require('@/assets/images/icon/etc/ad3.png'),
				4: require('@/assets/images/icon/etc/ad4.png'),
				5: require('@/assets/images/icon/etc/ad5.png'),
				6: require('@/assets/images/icon/etc/ad6.png'),
				7: require('@/assets/images/icon/etc/ad7.png'),
				8: require('@/assets/images/icon/etc/ad8.png'),
			},
		}
	},
	props: {
		processingData: {
			type: Array,
			default() {
				return []
			},
		},
		signalData: {
			type: Object,
			default() {
				return {
					greenTime: [],
					yellowTime: [],
					movement: [],
				}
			},
		},
		cycle: {
			type: Number,
			default: 0,
		},
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {},
	},
}
</script>
