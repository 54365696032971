<template>
  <div class="avenue">
    <p>전체교차로 분석</p>
    <div class="leftPanel">
      <div class="searchPanel">
        <div class="fLeft">
          <span>조회 기간</span>
          <p>
            <v-menu
              v-model="config.datePicker.start"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                @input="config.datePicker.start = false"
              ></v-date-picker>
            </v-menu>
          </p>
          <p>
            <v-menu
              ref="menu"
              v-model="config.timePicker.start"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startTime"
                  prepend-icon="mdi-calendar-clock"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="startTime"
                @click:minute="$refs.menu.save(startTime)"
                ampm-in-title
                scrollable
              ></v-time-picker>
            </v-menu>
          </p>
          <p>~</p>
          <p>
            <v-menu
              ref="menu2"
              v-model="config.timePicker.end"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endTime"
                  prepend-icon="mdi-calendar-clock"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="endTime"
                @click:minute="$refs.menu2.save(endTime)"
                ampm-in-title
                scrollable
              ></v-time-picker>
            </v-menu>
          </p>
          <a class="btnStyle01 wg searchBtn" @click="getData()">조회</a>
          <!-- <a class="btnStyle01 wg downBtn" @click="excelDownload()">다운로드</a> -->
        </div>
      </div>
      <div class="chartPanel">
        <globalChart :chartData="this.chartData"></globalChart>
      </div>
    </div>
    <div class="rightPanel">
      <div class="rTopPanel">
        <viewDataList
          title="전체 교차로 분석"
          type="1"
          @select="selectAnalysis"
        ></viewDataList>
      </div>
    </div>
  </div>
</template>
<script>
import viewDataList from "@/components/Tree/ViewDataList.vue";
import globalChart from "@/components/Chart/GlobalChart.vue";
import { mapGetters } from "vuex";
import { getIntersectionAnalysisAPI } from "../../api/index";
import { downloadExcelAPI } from "../../api/trafficAPI/index";
const globalStore = "globalStore";
export default {
  data() {
    return {
      startDate: "2023-11-16",
      endDate: "2023-11-16",
      startTime: "00:00",
      endTime: "23:59",
      picker: {
        start: false,
        end: false,
      },
      config: {
        datePicker: {
          start: false,
          end: false,
        },
        timePicker: {
          start: false,
          end: false,
        },
      },
      analysis: 1,
      chartData: {
        chartType: "barChart",
        data: [],
      },
    };
  },
  components: {
    viewDataList,
    globalChart,
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getData();
    },
    getData() {
      let path = "";
      let url = "";
      let title = "교통량";
      let chartType = "barChart";
      let chartNum = 0;
      let legend = [];
      switch (this.analysis) {
        case 1:
          path = "traffic";
          url = "traffic";
          title = "교통량";
          break;
        case 2:
          path = "traffic";
          url = "trafficByAvenue";
          chartType = "stackChart";
          title = "교통량(접근로)";
          legend = ["북", "동", "남", "서", "북동", "남동", "남서", "북서"];
          break;
        case 3:
          path = "traffic";
          url = "trafficByMovement";
          chartType = "stackChart";
          title = "교통량(회전)";
          legend = ["직진", "좌회전", "우회전", "유턴"];
          break;
        case 4:
          path = "traffic";
          url = "trafficByCarModel";
          chartType = "stackChart";
          title = "차량종류";
          legend = [
            "승용차",
            "버스",
            "소형트럭",
            "대형트럭",
            "특수차",
            "오토바이",
          ];
          break;
        case 5:
          path = "los";
          title = "제어지체";
          url = "trafficByDelay";
          chartType = "losChart";
          break;
        case 6:
          path = "los";
          url = "trafficByDelay";
          chartType = "losChart";
          title = "서비스수준";
          chartNum = 1;
          break;
      }
      const apiParams = {
        startDate: `${this.startDate} ${this.startTime}`,
        endDate: `${this.startDate} ${this.endTime}:59`,
      };

      getIntersectionAnalysisAPI(path, url, apiParams)
        .then((res) => {
          if (res.data.success) {
            let data = res.data.data;
            this.chartData = {
              title: title,
              chartType: chartType,
              unit: 4,
              chartNum: chartNum,
              data: data,
              legend: legend,
            };
          }
        })
        .catch(function (error) {
          alert("error :", error);
        });
    },
    excelDownload() {
      const url = "excelDownload";
      const excelDataParams = this.chartData;
      downloadExcelAPI(excelDataParams, url)
        .then((res) => {
          if (res.data.success) {
            let excelUri =
              "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
            let excelUrl = excelUri + res.data.content; // resp.content는 엑셀 데이터입니다.
            let filename = "ExcelDownLaod-File"; // 다운받는 파일의 이름 지정
            setTimeout(function () {
              var a = document.createElement("a");
              a.href = excelUrl;
              a.download = filename;
              a.click();
            }, 100);
          }
        })
        .catch(function (error) {
          alert("error :", error);
        });
    },
    selectAnalysis(e) {
      this.analysis = e;
    },
  },
  computed: {
    ...mapGetters(globalStore, ["getHostName"]),
  },
  watch: {
    analysis: function () {
      this.getData();
    },
  },
};
</script>
