<template>
  <div>
    <table>
      <thead>
        <tr>
          <th v-for="headers in tableData.headerData" :key="headers">
            {{ headers }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(content, idx) in tableData.contents" :key="idx">
          <td v-for="values in content" :key="values">{{ values }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  props: {
    tableData: {
      headerData: [],
      contents: [],
    },
  },
  data() {
    return {
      sampleData: "",
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {},
};
</script>
