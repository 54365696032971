<template>
  <div class="report_wrapper">
    <div class="container">
      <h1>월간 보고서</h1>
      <div class="inner_wrapper">
        <div class="report_panel">
          <ul class="toolbar">
            <li class="report-mode">
              <div class="select_box">
                <div class="selector">
                  <v-select
                    v-model="selectMode"
                    :items="selectModeList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="#1976d2"
                    dark
                  ></v-select>
                </div>
              </div>
            </li>
            <li class="top-wrapper">
              <div>
                <v-menu
                  v-model="pickerTrigger"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selectDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      color="#1976d2"
                      dark
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selectDate"
                    type="month"
                    @input="pickerTrigger = false"
                  ></v-date-picker>
                </v-menu>
                <div>
                  <button @click="init">조회</button>
                </div>
              </div>
              <button class="export-btn" @click="exportToPDF">Export</button>
            </li>
          </ul>
          <div class="report-wrapper" ref="pdfarea">
            <div class="report-container">
              <div class="report-header">LAON ROAD REPORT</div>
              <div class="content-title">1. 월간 교통량 분석</div>
              <div class="chart-wrapper">
                <strong>1-1. 전체 교차로 교통량 분석</strong>
                <GlobalChart :chart-data="trafficTotalChartData" />
                <!-- chart -->
              </div>
              <div class="chart-wrapper excel-chart">
                <!-- <GlobalChart :chart-data="chart[`allTraffic`].excelData" /> -->
                <!-- chart -->
              </div>
              <div class="table-wrapper" id="table-content1">
                <ReportTable :table-data="trafficTotalChartData.tableData" />
              </div>
              <div class="chart-wrapper">
                <strong>1-2. 개별 교차로 교통량 Top10</strong>
                <GlobalChart :chart-data="trafficRankChartData" />
                <!-- chart -->
              </div>
              <div class="chart-wrapper excel-chart">
                <!-- <GlobalChart :chart-data="chart[`interTraffic`].excelData" /> -->
                <!-- chart -->
              </div>
              <div class="table-wrapper" id="table-content2">
                <ReportTable :table-data="trafficRankChartData.tableData" />
              </div>

              <div class="content-title">2. 월간 이벤트 분석</div>
              <div class="chart-wrapper">
                <strong>2-1. 개별 이벤트 분석</strong>
                <SignReportChart :chart-data="eachEventChartData" />
                <!-- chart -->
              </div>
              <div class="table-wrapper" id="table-content5">
                <ReportTable :table-data="eachEventChartData.tableData" />
              </div>
              <div class="chart-wrapper">
                <strong>2-2. 개별 교차로 이벤트</strong>
                <SignReportChart :chart-data="eachIntersectionEventData" />
              </div>
              <div class="table-wrapper" id="table-content6">
                <ReportTable
                  :table-data="eachIntersectionEventData.tableData"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getIntersectionAnalysisAPI } from "../../api";
import { getEventLogAPI } from "../../api/trafficAPI";
import GlobalChart from "../../components/Chart/GlobalChart.vue";
import ReportTable from "./ReportTable.vue";
import SignReportChart from "../../components/Chart/SignReportChart.vue";
import html2pdf from "html2pdf.js";

export default {
  name: "",
  components: { GlobalChart, ReportTable, SignReportChart },
  data() {
    return {
      chart: {
        allTraffic: {},
        interTraffic: {},
        aveTraffic: {},
        allEvent: {},
        eachEvent: {},
        mainEvent1: { chartData: { data: [{ SeriesName: "" }] } },
        mainEvent2: { chartData: { data: [{ SeriesName: "" }] } },
        mainEvent3: { chartData: { data: [{ SeriesName: "" }] } },
        mainEvent4: { chartData: { data: [{ SeriesName: "" }] } },
        interEvent: {},
        aveEvent: {},
      },
      selectDate: "2023-11",
      trafficTotalChartData: {
        title: "",
        chartType: "",
        data: [],
        tableData: [],
      },
      trafficRankChartData: {
        title: "",
        chartType: "",
        data: [],
        tableData: [],
      },
      eachEventChartData: {
        title: "",
        chartType: "",
        data: [],
        tableData: [],
      },
      eachIntersectionEventData: {
        title: "",
        chartType: "",
        data: [],
        tableData: [],
      },
      dateFormat: {
        start: "",
        end: "",
      },
      selectModeList: ["월간리포트"],
      selectMode: null,
      pickerTrigger: false,
    };
  },
  setup() {},
  created() {},
  mounted() {
    this.init();
  },
  unmounted() {},
  methods: {
    init() {
      this.selectMode = this.selectModeList[0];
      this.getTrafficData();
      this.getEventLogData();
    },
    getTrafficData() {
      const targetDate = this.selectDate;
      const lastDay = new Date(
        targetDate.split("-")[0],
        targetDate.split("-")[1],
        0
      ).getDate();
      this.dateFormat.start = `${targetDate}-01`;
      this.dateFormat.end = `${targetDate}-${lastDay}`;
      const path = "traffic";
      const apiParams = {
        startDate: this.dateFormat.start,
        endDate: this.dateFormat.end,
      };
      const chartType = "barChart";
      getIntersectionAnalysisAPI(path, path, apiParams).then((res) => {
        const response = res.data.data;
        const rankValue = [...response]
          .sort((a, b) => b.Value - a.Value)
          .slice(0, 10);
        const tableValue = response.map((v, idx) => {
          return {
            seq: v.IntersectionSeq,
            name: v.Name,
            value: v.Value,
          };
        });
        const rankTableValue = [...tableValue]
          .sort((a, b) => b.value - a.value)
          .slice(0, 10);
        this.trafficTotalChartData = {
          title: "",
          chartType: chartType,
          unit: 4,
          chartNum: 0,
          data: response,
          legend: [],
          tableData: {
            headerData: ["교차로 번호", "교차로 명", "교통량"],
            contents: tableValue,
          },
        };

        this.trafficRankChartData = {
          title: "",
          chartType: chartType,
          unit: 4,
          chartNum: 0,
          data: rankValue,
          legend: [],
          tableData: {
            headerData: ["교차로 번호", "교차로 명", "교통량"],
            contents: rankTableValue,
          },
        };

        this.chart["allTraffic"].excelData = {
          ...this.trafficTotalChartData,
          excel: true,
        };

        this.chart["interTraffic"].excelData = {
          ...this.trafficRankChartData,
          excel: true,
        };
      });
    },

    getEventLogData() {
      const apiParams = {
        category: "",
        intersectionSeq: "",
        scale: 1000,
        startDate: this.dateFormat.start,
        endDate: this.dateFormat.end,
        startPage: 0,
      };
      getEventLogAPI(apiParams).then((res) => {
        const intersectionEachData = res.data.data[0]?.reduce((acc, cur) => {
          if (!acc[cur.IntersectionName]) {
            acc[cur.IntersectionName] = {
              value: 1,
              name: cur.IntersectionName,
            };
          } else {
            acc[cur.IntersectionName].value += 1;
          }
          return acc;
        }, {});

        const chartValues = res.data.data[0]?.reduce((acc, cur) => {
          if (!acc[cur.Description]) {
            acc[cur.Description] = { value: 1, name: cur.Description };
          } else {
            acc[cur.Description].value += 1;
          }
          return acc;
        }, {});
        const interValues = Object.values(intersectionEachData).sort(
          (a, b) => b.value - a.value
        );
        const dataValues = Object.values(chartValues).sort(
          (a, b) => b.value - a.value
        );
        const interRankValues = interValues.map(
          (v, idx) => (v = { rank: idx + 1, ...v })
        );
        const rankValues = dataValues.map(
          (v, idx) => (v = { rank: idx + 1, ...v })
        );

        const chartType = "pie";
        const chartData = {
          title: "",
          name: "pie",
          chartType: chartType,
          data: rankValues,
          tableData: {
            headerData: ["순위", "위반건수", "이벤트명"],
            contents: rankValues,
          },
        };
        const eachInterData = {
          title: "",
          name: "pie2",
          chartType: chartType,
          data: interRankValues,
          tableData: {
            headerData: ["순위", "위반건수", "교차로명"],
            contents: interRankValues,
          },
        };
        this.eachEventChartData = chartData;
        this.eachIntersectionEventData = eachInterData;
      });
    },
    exportToPDF() {
      //window.scrollTo(0, 0);
      html2pdf(this.$refs.pdfarea, {
        margin: 0,
        filename: "document.pdf",
        image: { type: "jpg", quality: 0.95 },
        html2canvas: {
          scrollY: 0,
          scale: 1,
          dpi: 300,
          letterRendering: true,
          allowTaint: false,
        },
        jsPDF: {
          orientation: "portrait",
          unit: "mm",
          format: "a2",
          compressPDF: true,
        },
      });
    },
  },
  watch: {
    selectDate() {
      this.init();
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0px;
  color: #afac89;
  display: inline-block;
  width: 200px;
}
.toolbar {
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  width: 100%;
  gap: 1rem;
  background-color: rgba(151, 172, 160, 0.15);
  border: 1px solid rgba(151, 172, 160, 0.15);
}

.toolbar li {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 100%;
}

.report-mode::after {
  content: "";
  width: 1px;
  background-color: rgba(151, 172, 160, 0.15);
  height: 28px;
}

.top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.top-wrapper div {
  display: flex;
}

.v-input__control div {
  margin: 0;
}

.report-wrapper {
  padding-top: 1.25rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #121212;
}

.report-wrapper::-webkit-scrollbar {
  height: 5px;
  width: 10px;
}

.report-wrapper::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
}

.report-wrapper::-webkit-scrollbar-track {
  background-color: #fff;
}

.report-container {
  position: relative;
  width: 1080px;
  margin: 0 auto 100px;
  padding: 2rem;
  overflow-y: hidden;
  border: 1px dashed rgba(151, 172, 160, 0.5);
}

.report-header {
  text-align: center;
  background-color: #292c2a;
  padding: 0.625rem;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1.25rem;
}

.content-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1.875rem;
  margin-bottom: 1.25rem;
}

.chart-wrapper {
  position: relative;
  width: 100%;
  height: 23.75rem;
  margin-bottom: 3rem;
}

h4 {
  font-size: 1.125rem;
  padding: 1.25rem 0 0 1.25rem;
}

strong {
  margin-bottom: 1rem;
}

.table-wrapper {
  width: 100%;
  height: 100%;
  border: none;
  margin-bottom: 6.25rem;
}

.excel-chart {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  visibility: hidden;
}

.inner_wrapper {
  margin-top: 1rem;
}
</style>
