import Vue from 'vue'
import { JSON_WS_BUS } from '@/bus/jsonWsBus.js'

// - 전역으로 사용하는 함수들 여기다 선언해서 사용
// - EVENT_BUS로 on / emit 으로 간단한 컴포넌트간 통신
const rfdc = require('rfdc')() //깊은 복사 api

export const EVENT_BUS = new Vue({
	data() {
		return {
			protocolGetAllTest: {
				default: {
					Serial: 0,
					CommendType: 'get_all',

					AppInfo: {
						Version: '1.0.0.0',
						Type: 'server',
						Customer: 'anyang', // anyang, sejong, gwangju
						MaxChannelNumber: 4,
						BuildTime: '2020.01.01 00:00:00',
					},
					CommonOption: {
						UISendResult: true,
						BoxDraw: 'org_box',
						FPSDraw: true,
						ViewCount: 4,
						SelectViewChannel: -1,
					},
					TrainFile: {
						ApplyFile: `default`,
					},
					IPConfig: {
						Type: 'static', // dhcp
						Address: '10.30.2.201',
						SubnetMask: '10.30.2.211',
						Gateway: '10.30.2.214',
					},
					CenterInfo: {
						IP: null,
						Connect: false,
						IsConnected: false,
					},
					SignalInfo: {
						IP: null,
						Connect: false,
						IsConnected: false,
					},
					VMSInfo: {
						Address: null,
						ID: null,
						DirectionA: null,
						DirectionB: null,
						Text: null,
						Connect: false,
						IsConnected: false,
					},
					VoiceGuideInfo: {
						Address: null,
						ID: null,
						Text: null,
						Connect: false,
						IsConnected: false,
					},
					SpotLightInfo: {
						Address: null,
						ID: null,
						Text: null,
						Connect: false,
						IsConnected: false,
					},
					FloorIndicatorInfo: {
						Address: null,
						ID: null,
						Bright: false,
						Connect: false,
						IsConnected: false,
					},

					PTZ: {
						FocusMode: 'auto',
						WhiteBalance: 'auto',
						AutoExposure: 'auto',
					},

					VideoSource: [],
					ChannelState: [],
					Action: [],
					Approach: [],
					CarDetectROI: [],
					CarCountROI: [],
					PersonDetectROI: [],
					ChannelOption: [],
					Signal: [],

					CarState: [],
					PersonState: [],
				},

				set: {
					Serial: 0,
					CommendType: 'get_all',

					AppInfo: {
						Version: '1.1.1.1',
						Type: 'server',
						Customer: 'sejong', // anyang, sejong
						MaxChannelNumber: 4,
						BuildTime: '2020.01.01 00:00:00',
					},

					CommonOption: {
						UISendResult: false,
						BoxDraw: 'org_box',
						FPSDraw: true,
						ViewCount: 4,
						SelectViewChannel: -1,
					},

					TrainFile: {
						ApplyFile: '/opt/laonpeople/default',
					},

					IPConfig: {
						Type: 'static',
						Address: '10.30.2.201',
						SubnetMask: '10.30.2.211',
						Gateway: '10.30.2.214',
					},

					CenterInfo: {
						IP: '10.30.2.201',
						Connect: true,
						IsConnected: true,
					},
					SignalInfo: {
						IP: '10.30.2.202',
						Connect: true,
						IsConnected: true,
					},
					VMSInfo: {
						Address: '10.30.2.203',
						ID: `0xakakakaka`,
						DirectionA: 'n',
						DirectionB: 's',
						Text: `내가 VMS 다`,
						Connect: true,
						IsConnected: true,
					},
					VoiceGuideInfo: {
						Address: `10.30.2.66`,
						ID: `1xakakakaka`,
						Text: `내가 음성이다`,
						Connect: true,
						IsConnected: true,
					},
					SpotLightInfo: {
						Address: '10.30.2.204',
						ID: `2xakakakaka`,
						Text: `111111111111`,
						Connect: true,
						IsConnected: true,
					},
					FloorIndicatorInfo: {
						Address: '10.30.2.205',
						ID: `3xakakakaka`,
						Bright: true,
						Connect: true,
						IsConnected: true,
					},

					PTZ: {
						FocusMode: 'auto',
						WhiteBalance: 'auto',
						AutoExposure: 'manual',
					},

					VideoSource: [
						{
							Channel: 1,
							Type: 'streaming',
							Address: 'rtsp://10.30.2.201:8554/streaming',
							Resolution: [1111, 555],
						},
						{
							Channel: 3,
							Type: 'file',
							Address: 'rtsp://10.30.2.204:8554/streaming',
							Resolution: [1280, 960],
						},
					],

					ChannelState: [
						{
							Channel: 1,
							State: 'connect',
						},
						{
							Channel: 3,
							State: 'ready',
						},
					],

					Action: [
						{
							Channel: 1,
							Analysis: false,
						},
						{
							Channel: 3,
							Analysis: false,
						},
					],

					Approach: [
						{
							Channel: 1,
							Intersection: {
								ID: '0A245',
								Name: '인 덕원사거리',
							},
							Direction: {
								ID: '2',
								Type: 'n',
								Name: '성남 방면',
							},
						},
						{
							Channel: 3,
							Intersection: {
								ID: '0D245',
								Name: '인덕원사 거리',
							},
							Direction: {
								ID: '4',
								Type: 'w',
								Name: '성남 라면',
							},
						},
					],

					CarDetectROI: [
						{
							Channel: 1,
							Lane: [],
						},
						{
							Channel: 3,
							Lane: [
								{
									Number: 1,
									Type: ['straight'],
									Point: [
										[21, 471],
										[276, 471],
										[297, 32],
										[39, 20],
									],
									StopArea: [
										[9, 887],
										[242, 888],
										[274, 494],
										[30, 485],
									],
								},
								{
									Number: 2,
									Type: ['right', 'straight'],
									Point: [
										[309, 469],
										[623, 474],
										[630, 56],
										[346, 39],
									],
									StopArea: [
										[279, 882],
										[609, 882],
										[614, 500],
										[300, 494],
									],
								},
								{
									Number: 3,
									Type: ['left', 'straight'],
									Point: [
										[639, 474],
										[951, 466],
										[914, 61],
										[669, 59],
									],
									StopArea: [
										[628, 879],
										[976, 879],
										[953, 505],
										[634, 504],
									],
								},
								{
									Number: 0,
									Type: ['exit'],
									Point: [
										[12, 1070],
										[995, 1064],
										[972, 900],
										[16, 900],
									],
								},
							],
						},
					],

					CarCountROI: [
						{
							Channel: 1,
							Area: [],
						},
						{
							Channel: 3,
							Area: [
								{
									Type: 'straight',
									Point: [
										[1504, 20],
										[1508, 136],
										[1885, 137],
										[1885, 25],
									],
									Distance: 0,
								},
								{
									Type: 'right',
									Point: [
										[1511, 143],
										[1509, 378],
										[1899, 381],
										[1885, 212],
										[1781, 152],
									],
									Distance: 10,
								},
								{
									Type: 'left',
									Point: [
										[1518, 397],
										[1527, 732],
										[1890, 741],
										[1901, 663],
										[1788, 627],
										[1801, 455],
									],
									Distance: 30,
								},
								{
									Type: 'uturn',
									Point: [
										[1534, 759],
										[1499, 884],
										[1555, 1037],
										[1776, 1038],
										[1874, 953],
										[1894, 830],
										[1774, 762],
									],
									Distance: 87,
								},
							],
						},
					],

					PersonDetectROI: [
						{
							Channel: 1,
							Area: [],
						},
						{
							Channel: 3,
							Area: [
								{
									Type: 'road',
									Point: [],
								},
								{
									Type: 'crosswalk',
									Point: [
										[495, 140],
										[395, 230],
										[551, 248],
									],
								},
								{
									Type: 'walkway_detect_a',
									Direction: 'e',
									Point: [
										[793, 131],
										[713, 219],
										[858, 227],
									],
								},
								{
									Type: 'walkway_wait_a',
									Direction: 'e',
									Point: [
										[763, 251],
										[690, 321],
										[855, 329],
									],
								},
								{
									Type: 'walkway_danger_a',
									Direction: 'e',
									Point: [
										[723, 365],
										[649, 426],
										[918, 449],
									],
								},
								{
									Type: 'walkway_detect_b',
									Direction: 'nw',
									Point: [
										[100, 943],
										[47, 1002],
										[351, 1015],
										[353, 973],
									],
								},
								{
									Type: 'walkway_wait_b',
									Direction: 'nw',
									Point: [
										[439, 949],
										[426, 1027],
										[558, 1025],
										[630, 972],
									],
								},
								{
									Type: 'walkway_danger_b',
									Direction: 'nw',
									Point: [
										[755, 959],
										[727, 1021],
										[879, 1028],
										[918, 963],
									],
								},
							],
						},
					],

					ChannelOption: [
						{
							Channel: 1,
							DetectThreashold: 50,
							NMSThreshold: 55,
						},
						{
							Channel: 3,
							DetectThreashold: 100,
							NMSThreshold: 100,
						},
					],

					Signal: [
						{
							Channel: 1,
							Person: {
								Type: null,
								RemainingTime: 0,
							},
							Vehicle: {
								Type: null,
								RemainingTime: 0,
							},
						},
						{
							Channel: 3,
							Person: {
								Type: 'red',
								RemainingTime: 44,
							},
							Vehicle: {
								Type: 'green',
								RemainingTime: 77,
							},
						},
					],

					CarState: [
						{
							Channel: 1,
							Time: '2020-10-27_11:52:46',
							Lane: [],
							StopLaneOver: false,
						},
						{
							Channel: 3,
							Time: '2020-10-30_11:52:46',
							Lane: [
								{
									Number: 1,
									Type: [`straight`],
									Occupancy: 40,
								},
								{
									Number: 2,
									Type: [`right`, `straight`],
									Occupancy: 41,
								},
								{
									Number: 3,
									Type: [`left`, `straight`],
									Occupancy: 42,
								},
							],
							StopLaneOver: true,
						},
					],

					PersonState: [
						{
							Channel: 1,
							Time: '2020-10-27_11:52:46',
							Walkway: {
								Wait: null,
								Detect: null,
								Danger: null,
							},
							Crosswalk: {
								RedDetect: null,
								RoadDetect: null,
							},
							PersonInfo: [],
						},
						{
							Channel: 3,
							Time: '2020-10-30_11:52:46',
							Walkway: {
								Wait: 11,
								Detect: 22,
								Danger: 33,
							},
							Crosswalk: {
								RedDetect: 2,
								RoadDetect: 7,
							},
							PersonInfo: [
								{
									ID: '0a32bc24',
									Location: 'road',
									Position: [90, 100],
									SignalViolation: true,
								},
								{
									ID: '0a32aa24',
									Location: 'crosswalk',
									Position: [489, 777],
									SignalViolation: true,
								},
								{
									ID: '0a99aa24',
									Location: 'danger',
									Position: [1, 2],
									SignalViolation: false,
								},
							],
						},
					],
				},
			},
		}
	},
	methods: {
		clone(v) {
			return rfdc(v)
		},

		// 빈 객체 체크
		isEmptyObject(param) {
			return Object.keys(param).length === 0 && param.constructor === Object
		},

		protocolParserTest(protocol, delay) {
			setTimeout(() => {
				console.log(
					`json ws[${JSON_WS_BUS.ws.port}] message: ${JSON.stringify(
						protocol,
					)}`,
				)
				JSON_WS_BUS.protocolParser(protocol)
			}, delay)
		},
	},

	created() {
		// console.log(`created:EVENT_BUS`)
	},
})
