<template>
	<div class="totalViewWrapper">
		<div v-for="data in headerDummyData" :key="data.name" class="totalViewItem">
			<v-icon size="50" class="iconTest">
				{{ data.icon }}
			</v-icon>
			<div class="totalViewTextWrap">
				<div class="totalViewTextTitle">
					{{ data.value | comma }} <span>{{ data.format }}</span>
				</div>
				<span class="totalViewTextSub">{{ data.name }}</span>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	components: {},
	props: {
		headerDummyData: {
			type: [Object, Array],
			default() {
				return
			},
		},
	},
	setup() {},
	data() {
		return {
			sampleData: '',
		}
	},
	created() {},
	mounted() {},
	unmounted() {},
	methods: {
		numberFormatter(value) {
			const formatNumber = value
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
			return formatNumber
		},
		fixedFloatFormatter(value) {
			const returnValue = value.toFixed(2)
			return returnValue
		},
	},
}
</script>
