<template>
	<p class="date-picker-month">
		<button @click="dateHandler('prev')">
			<v-icon x-large>mdi-chevron-left</v-icon>
		</button>
		<v-menu
			transition="scale-transition"
			offset-y
			:close-on-content-click="false"
			nudge-left="200"
			min-width="auto"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-text-field
					v-model="dateProps"
					readonly
					v-bind="attrs"
					v-on="on"
					color="#1976d2"
					dark
				></v-text-field>
			</template>
			<v-date-picker type="month" v-model="dateProps"></v-date-picker>
		</v-menu>
		<button @click="dateHandler('next')">
			<v-icon x-large>mdi-chevron-right</v-icon>
		</button>
	</p>
</template>
<script>
export default {
	name: '',
	components: {},
	data() {
		return {
			newValue: this.value,
		}
	},
	props: {
		value: String,
		config: Object,
	},
	setup() {},
	created() {},
	mounted() {},
	unmounted() {},
	computed: {
		dateProps: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
	},
	methods: {
		dateHandler(prev) {
			this.$emit('changeDateHander', prev)
		},
		myChange() {
			this.$emit('input', this.value)
		},
	},
}
</script>
