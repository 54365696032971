<template>
  <div class="avenue">
    <p @click="kk">접근로별 분석</p>
    <div class="leftPanel">
      <div class="lTopPanel">
        <v-dialog v-model="config.dialog.intersection" width="800">
          <template v-slot:activator="{ on, attrs }">
            <h1 v-bind="attrs" v-on="on">
              <v-icon>mdi-menu</v-icon>{{ avenue.avenueName }}
            </h1>
          </template>
          <v-btn icon dark @click="config.dialog.intersection = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <intersectionList
            @update="updateIntersection"
            @select="selectAvenue"
            :avenueSeq="this.avenue.avenueSeq"
            :isChildrenMode="true"
            :isAvenueMode="true"
          ></intersectionList>
        </v-dialog>
        <!-- <h1>안양여고 사거리 / 안양대교 방면</h1> -->
        <h2>
          분석 일시 : <span>{{ this.startDate }}</span>
        </h2>
      </div>
      <div class="lMidPanel">
        <div class="lMLeftPanel single">
          <div class="avenue-video-img">
            <video
              :src="`/videos/fast1.${(avenue.avenueSeq % 4) + 1}.mp4`"
              loop
              muted
              autoplay
              style="width: 100%; height: 100%; object-fit: fill"
            ></video>
            <!-- <div class="quater-img__box">
              <img src="../../assets/images/laonroad.png" alt="laonroad-logo" />
            </div> -->
          </div>
        </div>
        <div class="lMRightPanel">
          <div>
            <p>교통량</p>
            <span>{{ totalCount | comma }}대</span>
            <table>
              <colgroup>
                <col width="50%" />
                <col width="50%" />
              </colgroup>
              <thead>
                <tr>
                  <th>이동류</th>
                  <th>대수</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in trafficByMovement" :key="idx">
                  <td :class="item.type">{{ item.movementName }}</td>
                  <td class="count">{{ item.count | comma }} 대</td>
                </tr>
              </tbody>
            </table>
            <table>
              <colgroup>
                <col width="50%" />
                <col width="50%" />
              </colgroup>
              <thead>
                <tr>
                  <th>차종구분</th>
                  <th>대수</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in trafficByCarModel" :key="idx">
                  <td :class="item.type">{{ item.carModelName }}</td>
                  <td class="count">{{ item.count | comma }} 대</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="lBotPanel">
        <div style="width: 100%; height: 100%; display: block">
          <table>
            <colgroup></colgroup>
            <thead>
              <tr>
                <th>구분</th>
                <th v-for="(item, idx) in los" :key="idx">{{ item.time }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="tdTitle">제어지체</td>
                <td v-for="(item, idx) in los" :key="idx">
                  {{ item.controlDelay }}
                </td>
              </tr>
              <tr>
                <td class="tdTitle">서비스수준</td>
                <td v-for="(item, idx) in los" :key="idx">
                  {{ item.levelOfService }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="rightPanel">
      <div class="rTopPanel">
        <viewDataList
          title="개별 접근로 분석"
          type="3"
          @select="selectAnalysis"
        ></viewDataList>
      </div>
      <div class="rBotPanel">
        <p class="datePanel">
          <span>조회 기간</span>
          <v-menu
            v-model="config.datePicker.start"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              @input="config.datePicker.start = false"
            ></v-date-picker>
          </v-menu>
        </p>
        <p class="downPanel">
          <!-- <a class="btnStyle01 wg downBtn" @click="excelDownload()">다운로드</a> -->
        </p>
        <!-- <p class="category">
          <span>전체화면</span>
          <span>테이블보기</span>
          <span>화면캡쳐</span>
          <span>다운로드</span>
        </p> -->
        <p class="category2">
          <input type="radio" name="time" id="hour" value="3" v-model="unit" />
          <label for="hour">1시간</label>
          <input
            type="radio"
            name="time"
            id="minute"
            value="2"
            v-model="unit"
          />
          <label for="minute">15분</label>
          <input type="radio" name="time" id="cycle" value="1" v-model="unit" />
          <label v-show="this.analysis < 8" for="cycle">주기</label>
        </p>
        <p class="chartPanel">
          <globalChart :chartData="this.chartData"></globalChart>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
const protocolStore = "protocolStore";
const globalStore = "globalStore";

import { EVENT_BUS } from "@/bus/eventBus.js";
import { JSON_WS_BUS } from "@/bus/jsonWsBus.js";

import intersectionList from "@/components/Tree/IntersectionList.vue";
// import Screen from '@/components/Streaming/Screen'
import viewDataList from "@/components/Tree/ViewDataList.vue";
import globalChart from "@/components/Chart/GlobalChart.vue";
import { downloadExcelAPI, getAvenueInfoAPI } from "../../api/trafficAPI/index";
import { getCurrentAvenue, getAnalysisAvenueAPI } from "../../api/index";

export default {
  data() {
    return {
      startDate: "2023-11-16",
      endDate: "2023-11-16",
      picker: {
        start: false,
        end: false,
      },
      config: {
        dialog: {
          intersection: false,
        },
        datePicker: {
          start: false,
          end: false,
        },
      },
      analysis: 1,
      unit: 3,
      intersection: {
        intersectionSeq: 0,
        intersectionName: "교차로 선택",
        intersectionIP: "localhost",
      },
      avenue: {
        avenueSeq: 0,
        avenueName: "접근로 선택",
        avenueIP: "localhost",
      },
      totalCount: 0,
      chartData: {
        chartType: "barChart",
        data: [],
      },
      numberOfChannel: {
        val: null,
        items: [],
        isSet: false,
      },
      channel: {
        addressDialog: false,
        addressConnect: false,
        headers: [
          { name: `CH`, cols: 1 },
          { name: `Address`, cols: 6 },
          { name: `상태`, cols: 3 },
          { name: `동작`, cols: 2 },
        ],
        items: [],
      },
      los: [
        {
          time: "00:00",
          controlDelay: "",
          levelOfService: "",
        },
        {
          time: "00:01",
          controlDelay: "",
          levelOfService: "",
        },
      ],
      trafficByMovement: [
        {
          movementType: "1",
          movementName: "직진",
          count: "8964",
          type: "type01",
        },
        {
          movementType: "2",
          movementName: "좌회전",
          count: "3756",
          type: "type02",
        },
        {
          movementType: "3",
          movementName: "우회전",
          count: "2246",
          type: "type03",
        },
        {
          movementType: "4",
          movementName: "유턴",
          count: "466",
          type: "type04",
        },
      ],
      trafficByCarModel: [
        {
          carModelName: "승용차",
          count: "",
          type: "type05",
        },
        {
          carModelName: "버스",
          count: "",
          type: "type06",
        },
        {
          carModelName: "소형트럭",
          count: "",
          type: "type07",
        },
        {
          carModelName: "대형트럭",
          count: "",
          type: "type08",
        },
        {
          carModelName: "특수차",
          count: "",
          type: "type09",
        },
        {
          carModelName: "오토바이",
          count: "",
          type: "type10",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(protocolStore, [
      `GET_COMMON_OPTION`,
      `GET_VIDEO_SOURCE`,
      `GET_APP_INFO`,
    ]),
    ...mapGetters(globalStore, [
      `GET_DEV_MODE`,
      `GET_CH_OF_NUMBER`,
      `GET_CH_INDEX`,
      "getHostName",
    ]),
  },
  components: {
    intersectionList,
    // Screen,
    viewDataList,
    globalChart,
  },
  created() {},
  mounted() {
    getAvenueInfoAPI()
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          this.avenue.avenueSeq = data[0][`AvenueSeq`];
          this.avenue.avenueName = `${data[0]["IntersectionID"]}-${data[0]["AvenueID"]}. ${data[0]["IntersectionName"]} / ${data[0]["AvenueName"]}`;
          this.avenue.avenueIP ??= data[0][`CameraIP`].trim();

          this.intersection.intersectionSeq = data[0][`IntersectionSeq`];
          this.intersection.intersectionName = data[0][`IntersectionName`];
          this.intersection.intersectionIP ??= data[0][`ControllerIP`].trim();
        }
        this.init();
      })
      .catch(function (error) {
        alert("error :", error);
      });
  },
  beforedestroyed() {},
  methods: {
    ...mapActions(globalStore, [`AC_MAIN`, `AC_CHANNEL`]),
    kk() {
      // EVENT_BUS.$emit(`busScreen`, `viewModeToggle`, false)
    },
    init() {
      this.getInfo();
      this.getData();
    },
    getInfo() {
      const avenueSeqParams = this.avenue.avenueSeq;
      const targetDateParams = this.startDate;
      getCurrentAvenue(avenueSeqParams, targetDateParams).then((res) => {
        if (res.trafficAvenue.length > 0) {
          this.totalCount = 0;
          res.trafficAvenue[0].forEach((el, idx) => {
            this.totalCount += el["Value"];
            this.trafficByMovement[idx]["count"] = el["Value"];
          });
          res.trafficAvenue[1].forEach((el) => {
            this.trafficByCarModel[el["CarModelType"] - 1]["count"] =
              el["Value"];
          });
        }
        if (res.losAvenue.length > 0) {
          this.los = [];
          res.losAvenue.forEach((el) => {
            const item = {
              time: `${el["Hour"]}시`,
              controlDelay: `${el["Delay"]}`,
              levelOfService: `${el["LevelOfService"]}`,
            };
            this.los.push(item);
          });
        }
      });
    },
    getData() {
      let path = "";
      let url = "";
      let title = "교통량";
      let chartType = "barChart";
      let chartNum = 0;
      let legend = [];
      let label = [];
      let excelHeader = [];
      let excelTarget = "";
      let toolbox = "";

      switch (this.analysis) {
        case 1:
          url = "avenue/trafficByMovement";
          chartType = "lineChart";
          title = "회전 교통량";
          legend = ["직진", "좌회전", "우회전", "유턴"];
          toolbox = 2;
          legend.forEach((el, idx) => {
            excelHeader.push({ seq: idx + 1, name: el });
          });
          break;
        case 2:
          url = "avenue/trafficByLane";
          chartType = "lineChart";
          title = "차로별 교통량";
          legend = ["1차선", "2차선", "3차선", "4차선", "5차선", "6차선"];
          toolbox = 2;
          legend.forEach((el, idx) => {
            excelHeader.push({ seq: idx + 1, name: el });
          });
          break;
        case 3:
          url = "avenue/trafficByCarModel";
          chartType = "lineChart";
          title = "차종분류";
          legend = [
            "승용차",
            "버스",
            "소형트럭",
            "대형트럭",
            "특수차",
            "오토바이",
          ];
          toolbox = 2;
          legend.forEach((el, idx) => {
            excelHeader.push({ seq: idx + 1, name: el });
          });
          break;
        case 4:
          url = "avenue/cycleData";
          chartType = "lineChart";
          title = "초기 대기길이";
          toolbox = 1;
          break;
        case 5:
          url = "avenue/cycleData";
          chartType = "lineChart";
          title = "종기 대기길이";
          toolbox = 1;
          break;
        case 6:
          url = "avenue/cycleData";
          chartType = "lineChart";
          title = "점유율";
          toolbox = 1;
          break;
        case 7:
          url = "avenue/averageSpeed";
          chartType = "lineChart";
          title = "속도";
          toolbox = 1;
          break;
        case 8:
          url = "avenue/trafficByAvenueDelay";
          chartType = "stepLineChart";
          title = "제어지체";
          break;
        case 9:
          url = "avenue/trafficByAvenueDelay";
          chartType = "stepLineChart";
          title = "서비스 수준";
          excelTarget = "los";
          break;
      }

      if (
        parseInt(this.unit) === 3 ||
        parseInt(this.unit) === 2 ||
        parseInt(this.unit) === 1
      ) {
        if (url === "avenue/cycleData") {
          path = `/api/signal/analysis/${url}`;
        } else {
          path = `/api/traffic/analysis/${url}`;
        }
      }

      if (this.analysis === 4 || this.analysis === 5 || this.analysis === 6) {
        if (url === "avenue/cycleData") {
          path = `/api/signal/analysis/${url}`;
        } else {
          path = `/api/traffic/analysis/${url}`;
        }
      } else if (this.analysis === 8 || this.analysis === 9) {
        path = `api/los/analysis/${url}`;
      }

      const apiParams = {
        avenueSeq: this.avenue.avenueSeq,
        unit: parseInt(this.unit),
        startDate: this.startDate,
        endDate:
          parseInt(this.unit) === 1
            ? this.startDate + " 23:59:59"
            : this.startDate + " 23:59:59",
      };
      getAnalysisAvenueAPI(path, apiParams)
        .then((res) => {
          if (res.data.success) {
            let data = res.data.data;
            this.chartData = {
              title: title,
              chartType: chartType,
              unit: parseInt(this.unit),
              chartNum: chartNum,
              data: data,
              legend: legend,
              label: label,
              excelHeader: excelHeader,
              excelTarget: excelTarget,
              toolbox: toolbox,
            };
          }
        })
        .catch(function (error) {
          alert("error :", error);
        });
    },
    excelDownload() {
      const url = "excelDownload";
      const excelDataParams = this.chartData;
      downloadExcelAPI(url, excelDataParams)
        .then((res) => {
          if (res.data.success) {
            let excelUri =
              "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
            let excelUrl = excelUri + res.data.content; // resp.content는 엑셀 데이터입니다.
            let filename = "ExcelDownLaod-File"; // 다운받는 파일의 이름 지정
            setTimeout(function () {
              var a = document.createElement("a");
              a.href = excelUrl;
              a.download = filename;
              a.click();
            }, 100);
          }
        })
        .catch(function (error) {
          alert("error :", error);
        });
    },
    selectAnalysis(e) {
      this.analysis = e;
    },
    updateIntersection() {
      //this.intersection = e
    },
    selectAvenue(e, item) {
      this.avenue.avenueSeq = e.AvenueSeq;
      this.avenue.avenueName = `${item.IntersectionID}-${e.AvenueID}. ${item.IntersectionName} / ${e.AvenueName}`;
      //this.intersection.intersectionIP = item.ControllerIP.trim()
      this.avenue.avenueIP ??= e.CameraIP.trim();

      if (this.intersection.intersectionIP !== item.ControllerIP) {
        this.intersection.intersectionIP = item.ControllerIP?.trim();
        this.init();
      } else {
        this.GET_VIDEO_SOURCE.some((el) => {
          if (el.Address.indexOf(this.avenue.avenueIP) !== -1) {
            const commonOption = {};
            commonOption.SelectViewChannel = el.Channel;
            const json = {};
            json.Serial = 0;
            json.CommendType = `set`;
            json.CommonOption = commonOption;
            JSON_WS_BUS.wsSend(json);
            return true;
          }
        });

        this.getInfo();
        this.getData();
      }
      //this.init()
      this.config.dialog.intersection = false;
    },

    videoSourceUpdate(protocol) {
      for (let i in protocol) {
        const ch = protocol[i].Channel;
        if (protocol[i].Type !== undefined) {
          this.channel.items[ch].type = protocol[i].Type;
        }
        if (protocol[i].Address !== undefined) {
          if (protocol[i].Address === null) {
            this.channel.items[ch].isSet = false;
          } else {
            // this.viewAllModeWsConnect(ch)
            this.channel.items[ch].isSet = true;
          }

          this.channel.items[ch].address = protocol[i].Address;
        }
        if (protocol[i].Response !== undefined) {
          this.channel.addressConnect = false;

          if (protocol[i].Response) {
            this.channel.addressDialog = false;
            if (this.clickedMethodName === `addressCreate`) {
              const disableTabArr = [{ Channel: ch, State: `connect` }];
              this.AC_CHANNEL({ key: `disableTab`, val: disableTabArr });

              this.channel.items[ch].isSet = true;
              this.channel.items[ch].state = `connect`;
              // this.viewAllModeWsConnect(ch)
            }
            if (this.clickedMethodName === `addressReset`) {
              const disableTabArr = [{ Channel: ch, State: null }];
              this.AC_CHANNEL({ key: `disableTab`, val: disableTabArr });

              this.channel.items[ch].isSet = false;
              this.channel.items[ch].address = null;
              this.channel.items[ch].state = null;
              this.channel.items[ch].type = `streaming`;
              // this.viewAllModeWsDisconnect(ch)

              // EVENT_BUS.$emit(`busInfoDetector`, `clear`, {})
              // EVENT_BUS.$emit(`busSetRoi`, `clear`, {})
              // EVENT_BUS.$emit(`busSetParameter`, `clear`, {})
              // EVENT_BUS.$emit(`busStatebar`, `clear`, {})
              // EVENT_BUS.$emit(`getResultTable`, `clear`, {})
            }
          } else {
            if (this.clickedMethodName === `addressCreate`) {
              alert(`${ch}CH Address 연결 실패`);
            }
            if (this.clickedMethodName === `addressReset`) {
              alert(`${ch}CH Address 연결 해제 실패`);
            }
          }

          this.clickedMethodName = null;
        }
      }
    },

    // viewAllModeWsConnect(chIndex) {
    // 	try {
    // 		if (IMG_WS_BUS.viewAllModeWs[chIndex].img === null) {
    // 			if (!this.GET_DEV_MODE)
    // 				IMG_WS_BUS.viewAllModeWsImgClient(chIndex) // 연결
    // 			else IMG_WS_BUS.viewAllModeWs[chIndex].img = `ㅋㅋㅋ`
    // 		}
    // 	} catch (e) {
    // 		console.log(e)
    // 	}
    // },

    // viewAllModeWsDisconnect(chIndex) {
    // 	try {
    // 		if (IMG_WS_BUS.viewAllModeWs[chIndex].img !== null) {
    // 			if (!this.GET_DEV_MODE)
    // 				IMG_WS_BUS.viewAllModeWs[chIndex].img.close() // 해제
    // 			else IMG_WS_BUS.viewAllModeWs[chIndex].img = null
    // 		}
    // 	} catch (e) {
    // 		console.log(e)
    // 	}
    // },

    viewNumApply(opt, num) {
      if (num === 0) {
        alert(`등록된 접근로 정보가 없습니다.`);
        return;
      }

      this.numberOfChannel.val = num;
      this.numberOfChannel.isSet = true;

      this.channel.items = [];
      // IMG_WS_BUS.viewAllModeWs = []
      const disableTabArr = [];

      for (let i = 0; i < num; i++) {
        // IMG_WS_BUS.viewAllModeWs.push({
        // 	img: null,
        // 	ip: IMG_WS_BUS.ws.ip,
        // 	port: String(9200 + i),
        // 	reconnection: true,
        // })
        this.channel.items.push({
          index: i,
          isSet: false,
          address: null,
          state: null,
          action: false,
          type: `streaming`,
        });
        disableTabArr.push({
          Channel: i,
          State: null,
        });
      }

      this.AC_CHANNEL({ key: `disableTab`, val: disableTabArr });
      this.AC_CHANNEL({ key: `ofNumber`, val: num });

      EVENT_BUS.$emit(`busScreen`, `init`, { opt: opt, viewCount: num });
    },

    viewNumClear(opt) {
      // 클리어 안되는 조건..
      let connectFlag = false;
      let connectCh = ``;
      for (let i in this.channel.items) {
        if (this.channel.items[i].state !== null) {
          connectFlag = true;
          connectCh += `[${i}] `;
        }
      }
      if (connectFlag) {
        this.$dialog.error({
          persistent: true,
          width: 400,
          title: " ",
          text: `</br>- CH: ${connectCh} 연결을 끊고 초기화 해주세요.`,
        });
        return;
      }

      this.numberOfChannel.val = 0;
      this.numberOfChannel.isSet = false;

      this.channel.items = [];
      // IMG_WS_BUS.viewAllModeWs = []

      this.AC_CHANNEL({ key: `disableTab`, val: null });
      this.AC_CHANNEL({ key: `ofNumber`, val: 0 });

      EVENT_BUS.$emit(`busScreen`, `init`, { opt: opt, viewCount: 0 });
    },
  },
  watch: {
    GET_COMMON_OPTION(protocol) {
      if (protocol.ViewCount !== undefined) {
        this.viewNumApply(`init`, protocol.ViewCount);
      }
    },

    GET_VIDEO_SOURCE(protocol) {
      // if (this.channel.items.length !== 0) {

      // if(JSON_WS_BUS.ws.json !== null){
      //   JSON_WS_BUS.ws.json.close()
      // }

      // if(IMG_WS_BUS.viewAllModeWs.length !== 0){
      //   IMG_WS_BUS.viewAllModeWs.forEach(el => {
      //     if(el.img !== null)
      //     el.img.close()
      //   });
      // }
      // if(IMG_WS_BUS.ws.img !== null){
      //   IMG_WS_BUS.ws.img.close()
      // }

      this.videoSourceUpdate(protocol);
      // }
    },
    analysis: function () {
      this.getData();
    },
    unit: function () {
      this.getData();
    },
    startDate: function () {
      this.getInfo();
      this.getData();
    },
  },
};
</script>
