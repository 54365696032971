import { mapGetters } from 'vuex'
import { getUserListAPI } from '../api/trafficAPI/index'

export const userInfoMixins = {
	data() {
		return {
			word: '',
			pageList: [
				{
					pageNum: 1,
					active: true,
				},
			],
			currentPage: 1,
			user: {
				userID: '',
				userName: '',
				password: '',
				password2: '',
				passwordNew: '',
				passwordNew2: '',
				userAuth: 1,
				isUsed: '',
				email: '',
			},
			userList: [],
			authItemName: { 1: '사용자', 10: '운영자', 100: '관리자' },
			authItem: [
				{ value: 1, text: '사용자' },
				{ value: 10, text: '운영자' },
				{ value: 100, text: '관리자' },
			],
			ynItem: [
				{ value: 1, text: 'Y' },
				{ value: 0, text: 'N' },
			],
			config: {
				dialog: {
					intersection: false,
					user: false,
				},
				mode: {
					modify: false,
					create: false,
					current: false,
				},
				validation: {},
			},
		}
	},
	methods: {
		userCloseHandler() {
			this.config.dialog.user = false
			this.config.dialog.intersection = false
			this.config.mode = {
				modify: false,
				create: false,
				current: false,
			}
			this.user = {
				userID: '',
				userName: '',
				password: '',
				password2: '',
				passwordNew: '',
				passwordNew2: '',
				userAuth: 1,
				isUsed: '',
				email: '',
			}
			this.config.validation = {}
		},
		userModify(userID, mode) {
			if (!userID) {
				return 0
			}

			if (mode === 'create') {
				this.config.validation = {
					email: false,
					password: false,
					password2: false,
					userAuth: 1,
					limit: false,
					done: false,
					fail: false,
					emailDuple: false,
				}
				this.config.mode.create = true
				this.config.dialog.user = true
				return
			}

			switch (mode) {
				case 'current':
					this.config.validation = {
						email: false,
						passwordNew: false,
						passwordNew2: false,
					}
					this.config.mode.current = true
					break
				case 'modify':
					this.config.validation = {
						email: false,
					}
					this.config.mode.modify = true
					break
			}
			const targetUser = this.userList.filter(v => v.UserID === userID)[0]
			this.user.userID = targetUser.UserID
			this.user.userName = targetUser.UserName
			this.user.userAuth = targetUser.UserAuth
			this.user.isUsed = targetUser.IsUsed
			this.user.email = targetUser.Email
			this.config.dialog.user = true
		},
		getUserList() {
			const wordParams = this.word
			const startPageParams = (this.currentPage - 1) * this.page.scale
			const scaleParams = this.page.scale
			getUserListAPI(wordParams, startPageParams, scaleParams)
				.then(res => {
					if (res.data.success) {
						const data = res.data.data
						this.userList.splice(0)
						this.userList.push(...data[0])
						const gap = this.page.scale - this.userList.length
						for (let i = 0; i < gap; i++) {
							this.userList.push({})
						}
						this.page.totalRow = data[1][0].TotalRow
						const temp = (this.currentPage - 1) % this.page.pageBlock
						this.page.startPage = this.currentPage - temp
						this.page.totalPage = Math.ceil(
							this.page.totalRow / this.page.scale,
						)
						this.pageList.splice(0)
						for (
							let i = this.page.startPage;
							i < this.page.startPage + this.page.pageBlock;
							i++
						) {
							this.pageList.push({
								pageNum: i,
								active: i === this.currentPage ? true : false,
							})
							if (i >= this.page.totalPage) {
								break
							}
						}
					}
				})
				.catch(err => {
					alert('error :', err)
				})
		},
		levelAuth() {
			if (this.getUserAuth >= 10) {
				this.config.dialog.intersection = true
			} else {
				alert('접근권한이 없습니다.')
			}
		},
	},
	computed: {
		...mapGetters('loginStore', ['getUserAuth']),
	},
}
