<template>
  <div class="los">
    <p>LOS 분석</p>
    <div class="mainPanel">
      <div class="tabmenu">
        <!-- name은 type=radio인 그룹 묶을 때 사용 -->
        <input type="radio" name="tab" id="tab1" checked />
        <label for="tab1">입력자료</label>
        <input type="radio" name="tab" id="tab2" />
        <label for="tab2">교통량 보정 및 차로군 분류</label>
        <input type="radio" name="tab" id="tab3" />
        <label for="tab3">포화교통량 및 용량계산</label>
        <input type="radio" name="tab" id="tab4" />
        <label for="tab4">지체 및 서비스 수준 판정</label>

        <a class="btnStyle01 wg formulaBtn" @click="openFomula">계산 수식</a>
        <!-- <div id="cont1">내용1</div> -->

        <!-- 탭 메뉴 내용시작 -->
        <!-- 탭 메뉴 하나 -->
        <div id="cont1" class="tabPanel">
          <h1>입력자료</h1>

          <!-- 테이블1: 입력자료 -->
          <table>
            <colgroup>
              <col width="15%" />
              <col width="25%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="6">입력자료</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>교차로 명</td>
                <td class="inputTd">
                  <p>
                    <v-select
                      v-model="intersectionSeq"
                      :items="intersectionItem"
                      dense
                      :menu-props="{ bottom: true, offsetY: true }"
                      color="#1976d2"
                      dark
                    ></v-select>
                  </p>
                </td>
                <td>분석시간</td>
                <td colspan="3" class="inputTd">
                  <p>
                    <v-menu
                      v-model="config.datePicker.start"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          color="#1976d2"
                          dark
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        @input="config.datePicker.start = false"
                      ></v-date-picker>
                    </v-menu>
                  </p>
                  <p>
                    <v-menu
                      ref="menu"
                      v-model="config.timePicker.start"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startTime"
                          prepend-icon="mdi-calendar-clock"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          color="#1976d2"
                          dark
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="startTime"
                        @click:hour="$refs.menu.save(startTime)"
                        :allowed-minutes="allowedMinutes"
                        ampm-in-title
                        scrollable
                      ></v-time-picker>
                    </v-menu>
                  </p>
                </td>
              </tr>
              <tr>
                <td>교차로유형</td>
                <td>
                  {{
                    this.processingData[0]
                      ? this.processingData[0].data[3]
                        ? this.processingData[0].data[3].value
                        : ""
                      : ""
                  }}
                </td>
                <td>중차량 혼입률</td>
                <td>
                  {{
                    this.processingData[0]
                      ? this.processingData[0].data[1]
                        ? this.processingData[0].data[1].value
                        : ""
                      : ""
                  }}0.05
                </td>
                <td>PHF</td>
                <td>
                  {{
                    this.processingData[0]
                      ? this.processingData[0].data[2]
                        ? this.processingData[0].data[2].value
                        : ""
                      : ""
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <inputData
            :processingData="this.processingData"
            :signalData="this.signalData"
            :cycle="this.cycle"
          />
        </div>
        <trafficCorrection
          id="cont2"
          class="tabPanel"
          :processingData="this.processingData"
        />
        <capacity
          id="cont3"
          class="tabPanel"
          :processingData="this.processingData"
        />
        <levelOfService
          id="cont4"
          class="tabPanel"
          :processingData="this.processingData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import inputData from "@/components/Los/InputData";
import trafficCorrection from "@/components/Los/TrafficCorrection";
import capacity from "@/components/Los/Capacity";
import levelOfService from "@/components/Los/LevelOfService";
import { mapGetters } from "vuex";
import { getLosDataAPI } from "../../api/losAPI";
import { getIntersectionListAPI } from "../../api/trafficAPI";
import { getLosDataBySignalAPI } from "../../api/signalAPI";

const globalStore = "globalStore";
import "@/assets/css/table.css";
export default {
  data() {
    return {
      intersectionSeq: 13,
      startDate: "2023-11-16",
      startTime: `${new Date().getHours()}:00`,
      intersectionItem: [],
      processingData: [
        {
          avenueSeq: 1,
          avemueName: "",
          staticDirection: 1,
          processingDate: "",
          data: {},
        },
      ],
      signalData: {
        greenTime: [],
        yellowTime: [],
        movement: [],
      },
      cycle: null,
      config: {
        dialog: {
          image: false,
        },
        datePicker: {
          start: false,
          end: false,
        },
        timePicker: {
          start: false,
          end: false,
        },
      },
    };
  },
  components: {
    inputData,
    trafficCorrection,
    capacity,
    levelOfService,
  },
  created() {
    const intersectionParams = { intersectionSeq: "" };
    getIntersectionListAPI(intersectionParams)
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          data[0].forEach((el) => {
            this.intersectionItem.push({
              text: `${el.IntersectionID}. ${el.IntersectionName}`,
              value: el.IntersectionSeq,
            });
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  mounted() {
    window.scrollTo(0, 0);
    this.init();
    this.getLOSData();
  },

  methods: {
    init() {},
    getLOSData() {
      const dateParams = `${this.startDate} ${this.startTime}`;
      const typeParams = 60;
      getLosDataAPI(this.intersectionSeq, dateParams, typeParams)
        .then((res) => {
          if (res.data.success) {
            let data = res.data.data;
            this.processingData = [];
            let targetData = new Object();
            let targetAvenueSeq = 0;
            targetData.data = new Object();
            data.forEach((el) => {
              if (targetAvenueSeq !== 0 && targetAvenueSeq !== el.AvenueSeq) {
                this.processingData.push(targetData);
                targetData = new Object();
              }
              if (!targetData.avenueSeq) {
                targetData.avenueSeq = targetAvenueSeq = el.AvenueSeq;
                targetData.avenueName = el.AvenueName;
                targetData.staticDirection = el.StaticDirection;
                targetData.directionName = el.DirectionName;
                targetData.processingDate = el.ProcessingDate;
                targetData.data = new Object();
              }
              targetData.data[el.ProcessType] = {
                processType: el.ProcessType,
                processName: el.ProcessName,
                value: el.Value,
              };
            });
            this.processingData.push(targetData);
            // this.cycle = data[1][0]?data[1][0].CycleTime : null
            // this.signalData.greenTime = data[2]
            // this.signalData.movement = data[3]
          }
        })
        .catch(function (error) {
          alert("error :", error);
        });

      getLosDataBySignalAPI(this.intersectionSeq, dateParams, typeParams)
        .then((res) => {
          if (res.data.success) {
            let data = res.data.data;
            this.cycle = data[0][0] ? data[0][0].CycleTime : null;
            this.signalData.greenTime = data[1];
            this.signalData.movement = data[2];
          }
        })
        .catch(function (error) {
          alert("error :", error);
        });
    },
    openFomula() {
      let fomulaPage = window.open("/storage/LOS.pdf");
      window.setTimeout(() => {
        fomulaPage.document.title = "LOS 계산 수식";
      }, 100);
    },
    allowedMinutes: () => 0,
  },
  computed: {
    ...mapGetters(globalStore, ["getHostName"]),
  },
  watch: {
    intersectionSeq: function () {
      this.getLOSData();
    },
    startDate: function () {
      this.getLOSData();
    },
    startTime: function () {
      this.getLOSData();
    },
  },
};
</script>
