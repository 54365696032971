<template>
	<div
		class="totalViewChartWrap"
		style="
			 {
				width: 100%;
				height: calc(100% - 44px);
			}
		"
	></div>
</template>
<script>
require('echarts/theme/shine')
import * as echarts from 'echarts'
export default {
	components: {},
	props: {
		echartData: {
			type: Object,
			default() {
				return {}
			},
		},
		graphTrigger: {
			type: Boolean,
			default: false,
		},
	},
	setup() {},
	data() {
		return {
			chart: null,
		}
	},
	watch: {
		echartData(value) {
			this.renderChart(value)
		},
	},
	created() {},
	mounted() {
		this.renderChart(this.echartData)
	},
	unmounted() {},
	methods: {
		init() {},
		renderChart(option) {
			if (this.chart !== null) {
				this.chart.clear()
			}

			if (this.graphTrigger) {
				this.chart = echarts.init(this.$el)
				this.chart.setOption(option)
			}
		},
	},
}
</script>
