/* eslint-disable */
<template>
	<div>
		<v-dialog
			v-model="config.dialog.user"
			@keydown.esc="() => init()"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
			scrollable
		>
			<div class="modify">
				<span>
					<v-btn icon dark @click="() => init()">
						<v-icon x-large>mdi-close</v-icon>
					</v-btn>

					<p v-if="!config.mode.modify" class="title">운영자 정보 추가</p>
					<p v-if="config.mode.modify" class="title">운영자 정보 수정</p>
				</span>
				<div class="joinM">
					<div>
						<p class="tit">
							운영자 ID<span class="star" v-show="config.mode.create">*</span>
						</p>
						<p class="txtWrite">
							<input
								type="text"
								@change="idCheck"
								v-model="user.userID"
								:disabled="!config.mode.create"
							/>
						</p>
					</div>
					<div>
						<p class="idCheck limit" v-show="config.validation.limit">
							ID는 최소 다섯자리 이상 입력해주세요.
						</p>
						<p
							class="idCheck blue"
							v-show="config.validation.done && user.userID.length > 0"
						>
							사용가능한 ID 입니다.
						</p>
						<p class="idCheck red" v-show="config.validation.fail">
							사용이 불가능한 ID 입니다.
						</p>
					</div>
					<div>
						<p class="tit">Email<span class="star" v-show="!isSelf">*</span></p>
						<p class="textWrite">
							<input
								type="text"
								:disabled="isSelf"
								v-model="user.email"
								@change="() => (config.mode.create ? emailDupleCheck() : null)"
							/>
						</p>
						<p
							class="idCheck limit"
							v-show="!config.validation.email && user.email.length > 0"
						>
							이메일 형식을 지켜주세요.
						</p>
						<p class="idCheck red" v-show="config.validation.emailDuple">
							사용이 불가능한 email 입니다.
						</p>
					</div>
					<div>
						<p class="tit">
							운영자명<span class="star" v-show="!isSelf">*</span>
						</p>
						<p class="txtWrite">
							<input type="text" :disabled="isSelf" v-model="user.userName" />
						</p>
					</div>
					<div v-if="config.mode.create">
						<p class="tit">비밀번호<span class="star">*</span></p>
						<p class="txtWrite">
							<input type="password" v-model="user.password" />
						</p>
						<p
							class="idCheck limit"
							v-show="
								user.password
									? !config.validation.password && !config.mode.modify
									: false
							"
						>
							비밀번호는 연속되지 않은 대/소문자, 숫자, 특수문자를 포함해 10자
							이상이어야 합니다.
						</p>
					</div>
					<div v-if="config.mode.create">
						<p class="tit">비밀번호 확인<span class="star">*</span></p>
						<p class="txtWrite">
							<input type="password" v-model="user.password2" />
						</p>
						<p
							class="idCheck limit"
							v-show="!config.validation.password2 && user.password2.length > 0"
						>
							비밀번호가 일치하지 않습니다.
						</p>
					</div>
					<div v-if="config.mode.current">
						<p class="tit">기존 비밀번호<span class="star">*</span></p>
						<p class="txtWrite">
							<input type="password" v-model="user.password" />
						</p>
					</div>
					<div v-if="config.mode.current">
						<p class="tit">새 비밀번호<span class="star">*</span></p>
						<p class="txtWrite">
							<input type="password" v-model="user.passwordNew" />
						</p>
						<p
							class="idCheck limit"
							v-show="
								!config.validation.passwordNew && user.passwordNew.length > 0
							"
						>
							비밀번호는 연속되지 않은 대/소문자, 숫자, 특수문자를 포함해 10자
							이상이어야 합니다.
						</p>
					</div>
					<div v-if="config.mode.current">
						<p class="tit">새 비밀번호 확인<span class="star">*</span></p>
						<p class="txtWrite">
							<input type="password" v-model="user.passwordNew2" />
						</p>
						<p
							class="idCheck limit"
							v-show="
								!config.validation.passwordNew2 && user.passwordNew2.length > 0
							"
						>
							비밀번호가 일치하지 않습니다.
						</p>
					</div>
					<div>
						<p class="tit">권한<span class="star" v-show="!isSelf">*</span></p>
						<p class="txtWrite">
							<select v-model="user.userAuth" :disabled="isSelf">
								<option
									v-for="(item, idx) in authItem"
									:key="idx"
									:value="item.value"
								>
									{{ item.text }}
								</option>
							</select>
						</p>
					</div>
					<div v-if="config.mode.modify && !config.mode.current">
						<p class="tit">사용유무<span class="star">*</span></p>
						<p class="txtWrite">
							<select v-model="user.isUsed">
								<option
									v-for="(item, idx) in ynItem"
									:key="idx"
									:value="item.value"
								>
									{{ item.text }}
								</option>
							</select>
						</p>
					</div>
				</div>

				<div class="modifyBtn">
					<div
						role="button"
						v-if="config.mode.create"
						:class="[disabledChecker() ? '' : 'disabled']"
						@click="upSert"
					>
						등록
					</div>
					<div
						role="button"
						v-if="config.mode.modify || config.mode.current"
						:class="[disabledChecker() ? '' : 'disabled']"
						@click="upSert"
					>
						수정
					</div>
				</div>
			</div>
		</v-dialog>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
	checkDupleAPI,
	checkDupleEmailAPI,
	userUpdateAPI,
	signUpAPI,
} from '../../api/trafficAPI/index'
import _ from 'lodash'
import { encryptPassword } from '../../../public/js/encrypt'

const globalStore = 'globalStore'

export default {
	props: {
		user: {
			type: Object,
			default() {
				return {}
			},
		},
		config: {
			type: Object,
			default() {
				return {}
			},
		},
		isSelf: {
			type: Boolean,
			default() {
				return false
			},
		},
	},
	data() {
		return {
			timeInter: null,
			currentTime: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
				.toISOString()
				.replace('T', ' ')
				.substr(0, 19),
			menu: false,
			validateConfig: this.config.validation,
			currentPage: 1,
			page: {
				totalRow: 0,
				pageBlock: 10,
				scale: 10,
				startPage: 1,
				totalPage: 1,
			},
			userLog: [],
			authItem: [
				{ value: 1, text: '사용자' },
				{ value: 10, text: '운영자' },
				{ value: 100, text: '관리자' },
			],
			ynItem: [
				{ value: 1, text: 'Y' },
				{ value: 0, text: 'N' },
			],
		}
	},
	created() {
		let getNow = this
		this.timeInter = window.setInterval(function () {
			;(getNow.currentTime = new Date(
				Date.now() - new Date().getTimezoneOffset() * 60000,
			)
				.toISOString()
				.replace('T', ' ')
				.substr(0, 19)),
				10000
		})
	},
	mounted() {
		this.menu = this.getMenu
		this.userID = sessionStorage.access_id
	},
	destroyed() {},
	components: {},
	computed: {
		...mapGetters(globalStore, [
			`getMenu`,
			`GET_DIS_CARD`,
			`GET_PROGRESS_SHOW`,
			`getHostName`,
		]),
	},
	methods: {
		logOut() {
			sessionStorage.clear()
			this.$router.push('/login')
		},
		init() {
			this.$emit('init-user')
		},
		disabledChecker() {
			if (this.config.mode.modify) {
				return this.config.validation.email ? true : false
			}

			if (
				!this.user.email ||
				!this.user.userID ||
				!this.user.userName ||
				this.config.validation.emailDuple
			) {
				return false
			}

			for (let value in this.config.validation) {
				if (value === 'limit' || value === 'fail' || value === 'emailDuple') {
					continue
				} else if (
					!this.config.validation[value] ||
					this.user.password.length <= 0
				) {
					return false
				}
			}
			return true
		},
		idCheck() {
			if (this.user.userID.length < 5) {
				this.config.validation.limit = true
				this.config.validation.done = false
			} else {
				this.config.validation.limit = false
				checkDupleAPI(this.user.userID)
					.then(res => {
						if (res.data.success) {
							let data = res.data.data
							if (data.length === 1) {
								this.config.validation.done = false
								this.config.validation.fail = true
							} else {
								this.config.validation.done = true
								this.config.validation.fail = false
							}
						}
					})
					.catch(function (error) {
						alert('error :', error)
					})
			}
		},
		passwordCountinutyChecker(password) {
			let firstCnt = 0
			let secondCnt = 0

			for (let i = 0; i < password.length - 2; i++) {
				const temp_1 = password.charAt(i)
				const temp_2 = password.charAt(i + 1)
				const temp_3 = password.charAt(i + 2)
				if (temp_1 === temp_2 && temp_2 === temp_3) {
					return false
				}
				if (
					temp_1.charCodeAt(0) - temp_2.charCodeAt(0) === 1 &&
					temp_2.charCodeAt(0) - temp_3.charCodeAt(0) === 1
				) {
					firstCnt++
				}
				if (
					temp_1.charCodeAt(0) - temp_2.charCodeAt(0) === -1 &&
					temp_2.charCodeAt(0) - temp_3.charCodeAt(0) === -1
				) {
					secondCnt++
				}
			}

			if (firstCnt > 0 || secondCnt > 0) {
				return false
			} else {
				return true
			}
		},
		passwordChecker(password, mode) {
			const regex =
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/
			const wordCheck = this.passwordCountinutyChecker(password)

			const spaceRegex = /\s/g
			const check = regex.test(password) && wordCheck
			const spaceCheck = spaceRegex.test(password)
			if (password === null && mode === 'signUp' && !spaceCheck) {
				this.config.validation.password = false
			} else if (password?.length > 0 && mode === 'signUp') {
				this.config.validation.password = check
			}

			if (password === null && mode === 'change' && !spaceCheck) {
				this.config.validation.passwordNew = false
			} else if (password?.length > 0 && mode === 'change') {
				this.config.validation.passwordNew = check
			}
		},
		recheckPassword(password, mode) {
			const check = this.user.password === password
			const newCheck = this.user.passwordNew === password
			if (password === null && mode === 'signUp') {
				this.config.validation.password2 = false
			} else if (password?.length > 0 && mode === 'signUp') {
				this.config.validation.password2 = check
			}

			if (password === null && mode === 'change') {
				this.config.validation.passwordNew2 = false
			} else if (password?.length > 0 && mode === 'change') {
				this.config.validation.passwordNew2 = newCheck
			}
		},
		emailDupleCheck() {
			checkDupleEmailAPI(this.user.email).then(res => {
				if (res.data.success) {
					let data = res.data.data
					if (data.length === 1) {
						this.config.validation.emailDuple = true
					} else {
						this.config.validation.emailDuple = false
					}
				}
			})
		},
		emailChecker(email) {
			if (this.config.mode.current) {
				this.config.validation.email = true
				return
			}
			// 숫자, 대소문자, 특수문자 포함 정규식
			const regex =
				/^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/
			const check = regex.test(email)
			if (email === null) {
				this.config.validation.email = false
			} else if (email?.length > 0) {
				this.config.validation.email = check
			}
		},
		upSert: function () {
			// 비밀번호 일치 여부 확인
			// let oldPassword = ''
			let newPassword = ''
			// 비밀번호 세가지중 한가지만 입력한 경우

			if (
				this.user.password === null ||
				this.user.passwordNew === null ||
				this.user.passwordNew2 === null
			) {
				if (
					this.user.password === null &&
					this.user.passwordNew === null &&
					this.user.passwordNew2 === null
				) {
					// 비밀번호 요소 전체를 입력하지 않은 경우
				} else if (
					typeof this.user.passwordNew === 'undefined' &&
					typeof this.user.passwordNew2 === 'undefined'
				) {
					// User페이지에서 비번 입력 창이 없는 경우
				} else {
					alert('비밀번호를 변경하시려면 모든 값을 입력해주세요.')
					return false
				}
			}
			// 신규 등록 시
			if (this.config.mode.create) {
				newPassword = this.user.password
				if (this.user.password !== this.user.password2) {
					alert('비밀번호가 일치하지 않습니다.')
					return false
				}
			} // 회원 수정 시
			else if (this.config.mode.modify) {
				// oldPassword = this.user.password
				newPassword = this.user.passwordNew
				if (this.user.passwordNew !== this.user.passwordNew2) {
					alert('새 비밀번호가 일치하지 않습니다.')
					return false
				}
			}

			let apiParams = {}
			if (this.config.mode.current) {
				// 'User 수정'시, 비번변경 포함
				apiParams = {
					userID: this.user.userID,
					userName: this.user.userName,
					oldPassword: encryptPassword(this.user.password),
					newPassword: encryptPassword(this.user.passwordNew),
					userAuth: this.user.userAuth,
					isUsed: this.user.isUsed,
					email: this.user.email,
				}
				// 'User 수정'시, 비번변경 불포함
				userUpdateAPI(apiParams)
					.then(res => {
						if (res.data.success) {
							this.config.dialog.user = false
							this.config.mode.modify = false
							this.init()
							this.$emit('refetch')
							alert('회원정보가 정상적으로 수정되었습니다')
						} else {
							switch (res.status) {
								case 220:
									alert('이전 비밀번호와 동일한 비밀번호 입니다.')
									break
								case 221:
									alert('이미 존재하는 email 입니다.')
									break
								default:
									alert(res.data.message)
									break
							}
						}
					})
					.catch(function (error) {
						console.log('user update err :', error)
					})
			} else if (this.config.mode.modify) {
				apiParams = {
					userID: this.user.userID,
					userName: this.user.userName,
					userAuth: this.user.userAuth,
					isUsed: this.user.isUsed,
					newPassword: '',
					oldPassword: '',
					email: this.user.email,
				}
				userUpdateAPI(apiParams)
					.then(res => {
						if (res.data.success) {
							this.config.dialog.user = false
							this.config.mode.modify = false
							this.init()
							this.$emit('refetch')
							alert('회원정보가 정상적으로 수정되었습니다')
						} else {
							switch (res.status) {
								case 220:
									alert('이전 비밀번호와 동일한 비밀번호 입니다.')
									break
								case 221:
									alert('이미 존재하는 email 입니다.')
									break
								default:
									alert(res.data.message)
									break
							}
						}
					})
					.catch(function (error) {
						console.log('update err :', error)
					})
			}
			// 'User 등록'시 → signUp
			else {
				const apiParams = {
					userID: this.user.userID,
					userName: this.user.userName,
					newPassword: encryptPassword(newPassword),
					userAuth: this.user.userAuth,
					isUsed: this.user.isUsed,
					email: this.user.email,
				}
				signUpAPI(apiParams)
					.then(res => {
						if (res.data.success) {
							this.config.dialog.user = false
							this.config.mode.create = false
							this.init()
							this.$emit('refetch')
						} else {
							switch (res.status) {
								case 220:
									alert('이미 존재하는 email 입니다.')
									break
								default:
									alert('SignUP fail')
									break
							}
						}
					})
					.catch(function (error) {
						alert('SignUp error :', error)
					})
			}
		},
	},
	watch: {
		config: {
			handler() {
				this.disabledChecker()
			},
			deep: true,
		},
		'user.email': _.debounce(function (value) {
			this.emailChecker(value)
		}, 100),
		'user.password': _.debounce(function (value) {
			if (!this.config.mode.password) {
				this.passwordChecker(value, 'signUp')
			}
		}, 100),
		'user.password2': _.debounce(function (value) {
			if (!this.config.mode.password) {
				this.recheckPassword(value, 'signUp')
			}
		}, 100),
		'user.passwordNew': _.debounce(function (value) {
			this.passwordChecker(value, 'change')
		}, 100),
		'user.passwordNew2': _.debounce(function (value) {
			this.recheckPassword(value, 'change')
		}, 100),
	},
}
</script>
