<template>
	<div class="tree">
		<ul>
			<li>
				교차로 목록
				<ul>
					<li
						v-for="item in intersection"
						:key="item.IntersectionSeq"
						:class="{
							on: !isChildrenMode && item.Test,
							childMode: !isChildrenMode,
							avenueMode: isAvenueMode,
						}"
						@click="
							item.checked = !item.checked
							clickIntersection(item)
						"
					>
						<input
							v-if="!isChildrenMode && isCompareMode"
							type="checkbox"
							:checked="item.checked"
						/>
						{{ item.IntersectionID }}. {{ item.IntersectionName }}
						<ul v-if="isChildrenMode">
							<li
								v-for="child in item.Avenue"
								:key="child.AvenueSeq"
								:class="{ on: child.Test, childMode: isChildrenMode }"
								@click="
									child.checked = !child.checked
									clickAvenue(child, item)
								"
							>
								<input
									v-if="isCompareMode"
									type="checkbox"
									:checked="child.checked"
								/>
								{{ child.AvenueID }}. {{ child.AvenueName }}
							</li>
						</ul>
					</li>
				</ul>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getIntersectionListAPI } from '../../api/trafficAPI'

const globalStore = 'globalStore'
export default {
	name: 'intersectionList',
	data() {
		return {
			intersection: [],
		}
	},
	props: {
		intersectionSeq: {
			type: Number,
			default: 0,
		},
		avenueSeq: {
			type: Number,
			default: 0,
		},
		isCompareMode: {
			type: Boolean,
			default: false,
		},
		isChildrenMode: {
			type: Boolean,
			default: false,
		},
		isAvenueMode: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		this.init()
	},
	computed: {
		...mapGetters(globalStore, ['getHostName']),
	},
	methods: {
		init() {
			const intersection = { intersectionSeq: '' }
			getIntersectionListAPI(intersection)
				.then(res => {
					if (res.data.success) {
						let data = res.data.data
						if (this.isChildrenMode) {
							data[1].forEach(el => {
								if (this.avenueSeq === el.AvenueSeq) {
									el.Test = true
									el.checked = true
								} else {
									el.Test = false
									el.checked = false
								}
								data[0].some(el2 => {
									if (el2.IntersectionSeq === el.IntersectionSeq) {
										if (!el2.Avenue) el2.Avenue = []
										el2.Avenue.push(el)
										return true
									}
								})
							})
						} else {
							data[0].forEach(el => {
								if (this.intersectionSeq === el.IntersectionSeq) {
									el.Test = true
									el.checked = true
								} else {
									el.Test = false
									el.checked = false
								}
							})
						}
						this.intersection = data[0]
						this.$emit('update', this.intersection)
					}
				})
				.catch(function (error) {
					alert('error :', error)
				})
		},
		clickIntersection(e) {
			if (!this.isChildrenMode) {
				if (!this.isCompareMode) {
					this.intersection.forEach(el => {
						el.Test = false
					})
					e.Test = true
				} else {
					e.Test = !e.Test
				}

				this.$emit('select', e)
			}
		},
		clickAvenue(e, item) {
			if (!this.isCompareMode) {
				this.intersection.forEach(el => {
					el.Avenue.forEach(el2 => {
						el2.Test = false
					})
				})
				e.Test = true
			} else {
				e.Test = !e.Test
			}

			this.$emit('select', e, item)
		},
		changeIntersection(intersectionSeq) {
			this.intersection.forEach(el => {
				if (el.IntersectionSeq === intersectionSeq) {
					el.Test = true
				} else {
					el.Test = false
				}
			})
		},
	},
}
</script>
