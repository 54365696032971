var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-wrap"},_vm._l((_vm.tableData),function(data){return _c('div',{key:data.id,staticClass:"select-box"},[_c('div',{staticClass:"select-box__header"},[_c('div',{class:[
					data.status === 'Available'
						? 'select-box__circle available'
						: 'select-box__circle inUsed',
				]}),_vm._v(" "+_vm._s(data.volume)+" GB ")]),_c('div',{staticClass:"select-box__body"},[_c('span',{staticClass:"select-box__price"},[_vm._v("₩ "+_vm._s(_vm._f("comma")(data.price)))])]),_c('div',{staticClass:"select-box__btnwrap"},[_c('button',{class:[
					data.status === 'Available'
						? 'manage-status metering-status-change'
						: 'manage-status metering-status-used',
				],attrs:{"disabled":data.status === 'Available' ? false : true},on:{"click":() => _vm.selectBtnHandler(data.volume, data.price)}},[_vm._v(" "+_vm._s(data.status)+" ")])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }