import { _axios } from "../plugins/axios";
import axios from "axios";
const hostname = window.location.hostname.split(".")[0];
const getDatabaseAPI = (collectDate) => {
  try {
    const dbUsage = _axios.post("/api/traffic/meter/db", {
      CollectedDate: collectDate,
      Region: hostname,
    });
    return dbUsage;
  } catch (err) {
    console.log("getDatabase err");
  }
};

const getTrafficAPI = (region, date) => {
  try {
    const trafficData = _axios.post("api/traffic/meter/traffic", {
      CollectedDate: date,
      Region: hostname,
    });
    return trafficData;
  } catch (err) {
    console.log("get Traffic err");
  }
};

const getApiCallAPI = (region, collectDate) => {
  try {
    const trafficUsage = _axios.post("api/traffic/meter/apicall", {
      CollectedDate: collectDate,
      Region: hostname,
    });
    return trafficUsage;
  } catch (err) {
    console.log("getApiCall err");
  }
};

const getCapacityDB = () => {
  try {
    const capacityDB = _axios.post("api/traffic/meter/dbcapacity", {
      Region: hostname,
    });
    return capacityDB;
  } catch (err) {
    console.log("getCapacityDB err");
  }
};

const selectDBAPI = (changeTarget) => {
  try {
    const selectedItem = _axios.post("api/traffic/meter/dbselect", {
      UsedDbCapacity: changeTarget,
      Region: hostname,
    });
    return selectedItem;
  } catch (err) {
    console.log("selectDB err");
  }
};

const getTotalPrice = (region, date) => {
  try {
    const dbPrice = _axios.post("api/traffic/meter/price/db", {
      CollectedDate: date,
      Region: hostname,
    });
    const apiPrice = _axios.post("api/traffic/meter/price/api", {
      CollectedDate: date,
      Region: hostname,
    });
    const trafficPrice = _axios.post("api/traffic/meter/price/traffic", {
      CollectedDate: date,
      Region: hostname,
    });

    const totalPrice = axios.all([dbPrice, apiPrice, trafficPrice]).then(
      axios.spread((dbData, apiData, trafficData) => {
        return {
          dbData: dbData.data.data,
          callData: apiData.data.data,
          trafficData: trafficData.data.data,
        };
      })
    );

    return totalPrice;
  } catch (err) {
    console.log(err);
  }
};

const manageTableDataAPI = (date) => {
  try {
    const getTableData = _axios.post("/api/traffic/meter/info/admin", {
      CollectedDate: date,
      Region: hostname,
    });
    return getTableData;
  } catch (err) {
    console.log("multipleData err");
  }
};

const getIntersectionAnalysisAPI = (dbName, path, body) => {
  try {
    const analysisData = _axios.post(`/api/${dbName}/analysis/${path}`, {
      ...body,
      Region: hostname,
    });
    return analysisData;
  } catch (err) {
    console.log("getIntersectionAnalysisAPI err");
  }
};

const getMapChartDataAPI = (
  intersectionSeq,
  unit,
  startDate,
  endDate,
  path
) => {
  try {
    const mapData = _axios.post(path, {
      intersectionSeq,
      unit,
      startDate,
      endDate,
      Region: hostname,
    });
    return mapData;
  } catch (err) {
    console.log("getMapChartDataAPI err");
  }
};

const getCurrentAvenue = (avenueSeq, targetDate) => {
  try {
    const avenueByTraffic = _axios.post(
      "/api/traffic/avenue/getCurrentAvenue",
      {
        avenueSeq,
        targetDate,
        Region: hostname,
      }
    );
    const avenueByLos = _axios.post("/api/los/avenue/getCurrentAvenue", {
      avenueSeq,
      targetDate,
      Region: hostname,
    });

    const avenueValue = axios.all([avenueByTraffic, avenueByLos]).then(
      axios.spread((trafficAvenue, losAvenue) => {
        return {
          trafficAvenue: trafficAvenue.data.data,
          losAvenue: losAvenue.data.data,
        };
      })
    );

    return avenueValue;
  } catch (err) {
    console.log("getCurrentAvenue err");
  }
};

const getEachDataAPI = (urlPath, body) => {
  try {
    const eachData = _axios.post(urlPath, {
      intersectionSeq: body.intersectionSeq,
      unit: body.unit,
      startDate: body.startDate,
      endDate: body.endDate,
      Region: hostname,
    });
    return eachData;
  } catch (err) {
    console.log("eachDataAPI err");
  }
};

const getAnalysisAvenueAPI = (path, body) => {
  try {
    const avenueData = _axios.post(path, {
      avenueSeq: body.avenueSeq,
      unit: body.unit,
      startDate: body.startDate,
      endDate: body.endDate,
      Region: hostname,
    });
    return avenueData;
  } catch (err) {
    console.log("getAnalysisAvenueAPI err");
  }
};
// 다시 올리기
const getRealIp = async () => {
  try {
    const ipify = await axios.get("https://api64.ipify.org?format=json");
    return ipify.data.ip;
  } catch (err) {
    console.log("err first api");
    try {
      const ipApi = await axios.get("https://ipapi.co/json/");
      return ipApi.data.ip;
    } catch (err) {
      console.log("err second api");
    }
  }
}

export {
  getDatabaseAPI,
  getApiCallAPI,
  getCapacityDB,
  selectDBAPI,
  manageTableDataAPI,
  getTotalPrice,
  getTrafficAPI,
  getMapChartDataAPI,
  getIntersectionAnalysisAPI,
  getCurrentAvenue,
  getEachDataAPI,
  getAnalysisAvenueAPI,
  getRealIp
};
