<template>
	<article class="meteringWrapper manage-page">
		<section>
			<div class="metering-header-top">
				<h1>Management</h1>
				<ManagementDatePicker
					@changeDateHander="changeDateHandler($event)"
					v-model="startDate"
					:config="config"
				/>
			</div>
			<MeteringHeaderVue :header-dummy-data="dummyList" />
			<div class="simple-table-wrapper">
				<v-simple-table dark>
					<template>
						<thead>
							<tr class="manage-header">
								<th></th>
								<th>Name</th>
								<th>Volume</th>
								<th>API call</th>
								<th>Traffic</th>
								<th>Total Price</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(data, idx) in tableList" :key="idx">
								<td><img :src="data.imgSrc" /></td>
								<td>{{ data.name }}</td>
								<td v-if="data.volume">
									{{ fixedFloatFormatter(data.volume) }} GB
								</td>
								<td v-else></td>
								<td v-if="data.apiCall">
									{{ numberFormatter(data.apiCall) }}
								</td>
								<td v-else></td>
								<td v-if="data.traffic">
									{{ fixedFloatFormatter(data.traffic) }} GB
								</td>
								<td v-else></td>
								<td v-if="data.totalPrice">
									₩ {{ numberFormatter(data.totalPrice) }}
								</td>
								<td v-else></td>
								<td v-if="data.status !== ''">
									<span
										:class="[
											data.status === 'Running'
												? 'manage-status manage-status-running'
												: '',
										]"
									>
										{{ data.status }}
									</span>
								</td>
								<td v-else></td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
				<div class="paging">
					<div>
						<a class="Prev pagingBtn" @click="currentPage = 1">Prev</a>
						<a
							v-for="(item, idx) in pageList"
							:key="idx"
							@click="currentPage = item.pageNum"
							:class="{ on: item.active }"
							class="num"
							>{{ item.pageNum }}</a
						>
						<a class="next pagingBtn" @click="currentPage = page.totalPage"
							>Next</a
						>
					</div>
				</div>
			</div>
		</section>
	</article>
</template>
<script>
import MeteringHeaderVue from '@/components/MeteringComponent/MeteringHeader.vue'
import ManagementDatePicker from '@/components/Management/ManagementDatePicker.vue'
import { manageTableDataAPI } from '@/api/index'
import {
	getAdminCurrentTableAPI,
	getAdminPriceDataAPI,
} from '../../api/trafficAPI/index'
import dayjs from 'dayjs'
import _ from 'lodash'
import { mapGetters } from 'vuex'
const globalStore = 'globalStore'

export default {
	name: '',
	components: {
		MeteringHeaderVue,
		ManagementDatePicker,
	},
	data() {
		return {
			startDate: dayjs().format('YYYY-MM'),
			nowDate: dayjs().format('YYYY-MM'),
			currentPage: 1,
			pageList: [{ pageNum: 1, active: true }],
			endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
				.toISOString()
				.substr(0, 10),
			config: {
				datePicker: {
					start: false,
					end: false,
				},
			},
			pageScale: 9,
			testDate: '',
			dummyList: [
				{
					name: 'DB Usage (GB)',
					value: 0,
					format: 'GB',
					icon: 'mdi-database',
				},
				{
					name: 'Traffic',
					value: 0,
					format: 'GB',
					icon: 'mdi-network-strength-3',
				},
				{
					name: 'API Call',
					value: 0,
					format: '',
					icon: 'mdi-swap-vertical-variant',
				},
				{
					name: 'Total Price',
					value: 0,
					format: '',
					icon: 'mdi-currency-krw',
				},
			],
			tableList: [],
		}
	},
	setup() {},
	created() {},
	mounted() {
		this.init()
	},
	unmounted() {},
	methods: {
		init() {
			this.getDatabaseList()
			// this.managePrice();
		},
		getAdminPrice() {},
		managementLogic(response, priceData) {
			this.tableList = []
			let totalDbUsage = 0
			let totalTrafficUsage = 0
			let totalApiUsage = 0
			let totalPriceUsage = 0
			// const listLen = this.pageScale - res.data.data.length;
			const tableData = response.data.data
			for (let i = 0; i < 9; i++) {
				if (tableData[i] && (tableData[i].Region === 'anyang' || tableData[i].Region === 'seongnam')) {
					let region
					let img
					const dataRegion = tableData[i].Region
					const price =
						priceData[dataRegion]?.apiPrice +
						priceData[dataRegion]?.dbPrice +
						priceData[dataRegion]?.trafficPrice
					switch (dataRegion) {
						case 'anyang':
							region = '안양시'
							img = 'anyang'
							break
						case 'seongnam':
							region = '성남시'
							img = 'seongnam'
							break
						default:
							region = '지자체'
							img = 'vue'
							break
					}
					const data = {
						name: region,
						imgSrc: require(`@/assets/logo/${img}-logo.png`),
						volume: tableData[i].UsedVolume,
						apiCall: tableData[i].TotalApiCall.toString(),
						traffic: tableData[i].MonthTraffic.toString(),
						totalPrice: price,
						status: 'Running',
					}
					totalDbUsage += tableData[i].UsedVolume
					totalTrafficUsage += tableData[i].MonthTraffic
					totalApiUsage += tableData[i].TotalApiCall
					totalPriceUsage += isNaN(price) ? 0 : price
					this.tableList.push(data)
				} 
			}
			this.dummyList[0].value = this.fixedFloatFormatter(totalDbUsage)
			this.dummyList[1].value = this.fixedFloatFormatter(totalTrafficUsage)
			this.dummyList[2].value = totalApiUsage
			this.dummyList[3].value = totalPriceUsage
		},
		getDatabaseList() {
			getAdminPriceDataAPI(this.startDate).then(res => {
				const priceData = {}
				const dataSet = res.data.data
				for (let i = 0; i < dataSet.length; i++) {
					priceData[dataSet[i].Region] = {
						dbPrice: dataSet[i].Dbprice,
						apiPrice: dataSet[i].Apiprice,
						trafficPrice: dataSet[i].Trafficprice,
					}
				}

				if (this.startDate === this.nowDate) {
					getAdminCurrentTableAPI(this.startDate).then(res => {
						this.managementLogic(res, priceData)
					})
				} else {
					manageTableDataAPI(this.startDate).then(res => {
						this.managementLogic(res, priceData)
					})
				}
			})
		},
		changeDateHandler(mode) {
			const nextDate = dayjs(this.startDate).add(1, 'month').format('YYYY-MM')
			const diffDate = dayjs().diff(nextDate)
			if (mode === 'next' && diffDate >= 0) {
				this.startDate = nextDate
			} else if (mode === 'prev') {
				this.startDate = dayjs(this.startDate)
					.subtract(1, 'month')
					.format('YYYY-MM')
			}
		},
		numberFormatter(value) {
			const formatNumber = value
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
			return formatNumber
		},
		fixedFloatFormatter(value) {
			const returnValue = Number(value).toFixed(2)
			return returnValue
		},
	},
	computed: {
		...mapGetters(globalStore, ['getHostName']),
	},
	watch: {
		startDate: _.debounce(function () {
			this.init()
		}, 200),
	},
}
</script>
