<template>
	<div class="admin">
		<p>장비 상태정보</p>
		<div class="globalPanel">
			<div class="gridPanel current">
				<table class="tbl_base type1 hand" summary="장비현황 리스트">
					<caption class="hidden">
						장비현황 리스트
					</caption>
					<colgroup>
						<col width="4%" />
						<col width="12%" />
						<col width="8%" />
						<col width="8%" />
						<col width="8%" />
						<col width="8%" />
						<col width="8%" />
						<col width="8%" />
						<col width="6%" />
						<col width="12%" />
						<col width="8%" />
						<col width="10%" />
					</colgroup>
					<thead>
						<tr>
							<th scope="col">번호</th>
							<th scope="col">교차로명</th>
							<th scope="col">교차로ID</th>
							<th scope="col">센터 IP</th>
							<th scope="col">제어기ID</th>
							<th scope="col">제어기IP</th>
							<th scope="col">광정송장비IP</th>
							<th scope="col">상태감시장치IP</th>
							<th scope="col">접근로 번호</th>
							<th scope="col">카메라 검지 방향</th>
							<th scope="col">접근로 진출 방향</th>
							<th scope="col">카메라IP</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(item, idx) in equipmentList"
							:class="item.IntersectionID"
							:key="idx"
							@mouseover="() => mouseOverHandler(item.IntersectionID)"
							@mouseleave="() => mouseLeaveHandler(item.IntersectionID)"
						>
							<td v-if="item.Floor === 1" :rowspan="item.AvenueCount">
								{{ item.IntersectionSeq }}
							</td>
							<td v-if="item.Floor === 1" :rowspan="item.AvenueCount">
								{{ item.IntersectionName }}
							</td>
							<td v-if="item.Floor === 1" :rowspan="item.AvenueCount">
								{{ item.IntersectionID }}
							</td>
							<td v-if="item.Floor === 1" :rowspan="item.AvenueCount">-</td>
							<td v-if="item.Floor === 1" :rowspan="item.AvenueCount">
								{{ item.IntersectionSeq }}
							</td>
							<td
								v-if="item.Floor === 1"
								:rowspan="item.AvenueCount"
								:class="{
									conn: item.IsControllerConn === 1,
									disConn: item.IsControllerConn === 0,
								}"
							>
								{{ item.ControllerIP ?? '-' }}
							</td>
							<td
								v-if="item.Floor === 1"
								:rowspan="item.AvenueCount"
								:class="{
									conn: item.IsOpticalConn === 1,
									disConn: item.IsOpticalConn === 0,
								}"
							>
								{{ item.OpticalIP ?? '-' }}
							</td>
							<td
								v-if="item.Floor === 1"
								:rowspan="item.AvenueCount"
								:class="{
									conn: item.IsObserveConn === 1,
									disConn: item.IsObserveConn === 0,
								}"
							>
								{{ item.ObserveIP ?? '-' }}
							</td>
							<td>{{ item.AvenueSeq }}</td>
							<td>{{ item.AvenueName }}</td>
							<td>{{ category[item.StaticDirection] }}</td>
							<td
								:class="{
									conn: item.IsCameraConn === 1,
									disConn: item.IsCameraConn === 0,
								}"
							>
								{{ item.CameraIP ?? '-' }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { currentSystemEventAPI } from '../../api/trafficAPI/index'

const globalStore = 'globalStore'
export default {
	data() {
		return {
			equipmentList: [],
			category: { 1: '북', 2: '동', 3: '남', 4: '서' },
			weight: 1,
			isOver: false,
		}
	},
	created() {},
	mounted() {
		this.init()
	},
	computed: {
		...mapGetters(globalStore, ['getHostName']),
	},
	methods: {
		init() {
			this.getCurrent()
		},
		getCurrent() {
			currentSystemEventAPI()
				.then(res => {
					if (res.data.success) {
						let data = res.data.data
						this.equipmentList = data
					}
				})
				.catch(function (error) {
					alert('error :', error)
				})
		},
		mouseOverHandler(target) {
			this.isOver = true
			const el = document.getElementsByClassName(target)
			Array.prototype.forEach.call(el, value => {
				value.classList.add('hover-on')
			})
		},
		mouseLeaveHandler(target) {
			this.isOver = false
			const el = document.getElementsByClassName(target)
			Array.prototype.forEach.call(el, value => {
				value.classList.remove('hover-on')
			})
		},
	},
}
</script>
