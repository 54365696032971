<template>
  <div :class="className" :style="{ height: height, width: width }"></div>
</template>

<script>
import * as echarts from "echarts";
import { mapGetters } from "vuex";
import { downloadExcelAPI } from "../../api/trafficAPI/index";
require("echarts/theme/macarons");
const globalStore = "globalStore";
// const animationDuration = 6000

export default {
  name: "globalChart",
  props: {
    chartData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        chartType: "stackChart",
        data: [],
      },
    },
    className: {
      type: String,
      default: "chart",
    },
    id: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      chart: null,
      isFalse: true,
      // 고정값 //
      defaultOption: {
        backgroundColor: "#191a1b",
        title: {
          text: "교통량",
          x: "50%",
          y: "3%",
          textAlign: "center",
          textStyle: {
            color: "#f0ebd8",
            fontSize: "22",
          },
          subtextStyle: {
            color: "#90979c",
            fontSize: "16",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: [],
          textStyle: { color: "#f0ebd8" },
          padding: [0, 0, 0, 0],
          bottom: "5%",
        },
        toolbox: {
          feature: {
            // myExcelDown: {
            //   show: true,
            //   title: "Excel Export",
            //   icon: "image://img/icon/excel.png",
            //   onclick: this.excelExport,
            // },
            magicType: {
              show: false,
              type: ["bar", "line"],
              title: {
                bar: "BarChart",
                line: "LineChart",
              },
            },
            restore: { show: false, title: "ReStore" },
            saveAsImage: { show: true, title: "SAVE" },
          },
        },
        grid: { containLabel: true },
        xAxis: {
          axisLine: {
            lineStyle: {
              color: "#008acd",
            },
          },
          axisLabel: {
            color: "#f0ebd8",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#8b959a50",
            },
          },
          splitArea: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          axisLabel: {
            color: "#f0ebd8",
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: "#8b959a50",
            },
          },
          splitArea: {
            show: false,
          },
        },
        dataset: {
          source: [],
        },

        series: [],
      },
      losColor: {
        "": "#82ff72",
        A: "#74B72E",
        B: "#74B72E",
        C: "#028A0F",
        D: "#FFD400",
        E: "#F78200",
        F: "#E23838",
        FF: "#E23838",
        FFF: "#E23838",
      },
    };
  },

  mounted() {
    this.chart = echarts.init(this.$el, "macarons");
  },

  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },

  methods: {
    rendererChart(option) {
      if (this.chart !== null) {
        this.chart.clear();
      }
      this.chart = echarts.init(this.$el, "macarons");

      this.chart.setOption(option);
      this.isFalse = false;
    },
    excelExport() {
      let series = this.chart.getOption().series;
      let header = [];
      let day = this.chart.getOption().yAxis[0].data;
      let dataSet = [];
      let tempDataSet = {};
      window.kk = this.chart.getOption();
      //시리즈 네임으로 헤더 셋팅
      series.forEach((el) => {
        header.push(el.name);
      });

      //헤더 없으면 타이틀로 셋팅
      if (header.length === 1 && header[0] === undefined) {
        header = [this.chart.getOption().title[0].text];
      }

      //y열 dateType일때 날짜 sorting 및 중복 제거
      if (
        day === undefined ||
        ((series[0].data && typeof series[0].data[0]) === "object" &&
          series[0].data[0][0])
      ) {
        day = [];
        series.forEach((el) => {
          el.data.forEach((item) => {
            day.push(item[0]);
          });
        });
        day = [...new Set(day.sort())];
      }

      //create default Object
      let defaultObj = new Object();
      for (let i = 0; i < header.length; i++) {
        defaultObj[`col${i + 1}`] = "";
      }

      //create default dataSet
      day.forEach((el) => {
        if (!tempDataSet[el]) tempDataSet[el] = { days: el, ...defaultObj };
      });

      //임시 dataSet 셋팅
      series.forEach((el, idx) => {
        el.data.forEach((item, idx2) => {
          if (typeof item === "object") {
            if (item[0]) {
              tempDataSet[item[0]][`col${idx + 1}`] = item[1];
            } else {
              tempDataSet[day[idx2]][`col${idx + 1}`] = item.value;
            }
          } else {
            tempDataSet[day[idx2]][`col${idx + 1}`] =
              item === "null" ? "" : item;
          }
        });
      });

      //dataSet
      for (let item in tempDataSet) {
        dataSet.push(tempDataSet[item]);
      }

      const url = "excelDownload2";
      const excelDataParams = {
        header,
        dataSet,
      };
      downloadExcelAPI(url, excelDataParams)
        .then((res) => {
          if (res.data.success) {
            let excelUri =
              "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
            let excelUrl = excelUri + res.data.content; // resp.content는 엑셀 데이터입니다.
            let filename = this.chartData.title; // 다운받는 파일의 이름 지정
            setTimeout(function () {
              let a = document.createElement("a");
              a.href = excelUrl;
              a.download = filename;
              a.click();
            }, 100);
          }
        })
        .catch(function (error) {
          alert("error :", error);
        });
    },
  },
  computed: {
    ...mapGetters(globalStore, ["getHostName"]),
  },

  watch: {
    chartData: function (e) {
      let option = { ...this.defaultOption };
      if (
        e.title === "제어지체 및 서비스 수준(LOS)" ||
        e.title === "교차로 접근로 교통량" ||
        e.title === "교차로 차종 교통량"
      ) {
        option.animation = this.isFalse;
      }

      //Main 지도 위 그래프 배경 수정
      e.checked
        ? (option.backgroundColor = "rgba(180, 180, 180, 0.2)")
        : (option.backgroundColor = "#191a1b");

      /////////////////////////////Main//////////////////////////////////

      ///////////////////////// barChart /////////////////////////     1. 전체교차로 분석: 1.교통량
      if (e.chartType === "barChart") {
        option.title.text = e.title;
        option.legend.data = e.legend;
        // let legend = e.legend

        // yData → 교차로명
        let yData = [];
        let dataLength = Object.keys(e.data).length;
        for (let i = 0; i < dataLength; i++) {
          yData.push(e.data[i].Name);
        }
        const set = new Set(yData);
        yData = [...set];

        // xData → 차량수
        let xData = new Array(yData.length).fill(null);

        e.data.forEach((el, idx) => {
          if (e.data[idx].Name !== null) {
            let value = el["Value"] === 0 ? "" : el["Value"];
            xData[idx] = value;
          }
        });

        // xyData의 series 생성
        option.series = {
          type: "bar",
          label: {
            show: false,
            position: "insideRight",
          },
          data: xData,
        };

        option.xAxis = {
          ...option.xAxis,
          type: "value",
          axisLabel: {
            color: "#f0ebd8",
            formatter: "{value}대",
          },
        };
        option.yAxis = {
          ...option.yAxis,
          type: "category",
          data: yData,
          axisLabel: {
            color: "#f0ebd8",
          },
        };
      } ///////////////////////// barChart2 /////////////////////////
      else if (e.chartType === "barChart2") {
        if (e.title === "교차로 교통량2") {
          option.title.text = "교차로 교통량";
        } else option.title.text = e.title;

        // let dataLength = Object.keys(e.data).length
        // let legend = e.legend.length === 0 ? [1] : e.legend
        let label = e.label;

        // yData → 차량수
        let dataset = {};
        for (let i of e.data) {
          let value = parseInt(i.Value);
          if (i.IntersectionSeq) {
            if (!dataset[i.IntersectionSeq]) {
              dataset[i.IntersectionSeq] = [];
            }
            dataset[i.IntersectionSeq].push([i.Name, value]);
          } else if (i.AvenueSeq) {
            if (!dataset[i.AvenueSeq]) {
              dataset[i.AvenueSeq] = [];
            }
            dataset[i.AvenueSeq].push([i.Name, value]);
          }
        }

        //시간보정
        let name = "";
        if (e.unit === 1) {
          name = "hh:mm:ss";
        } else if (e.unit === 2) {
          name = "hh:mm";
        } else if (e.unit === 3) {
          name = "hh";
        }

        let seriesData = [];
        let keys = Object.keys(dataset);
        let key = "";
        let sData = "";

        for (let i = 0; i < keys.length; i++) {
          key = keys[i];
          sData = {
            name: label[i],
            type: "bar",
            data: dataset[key],
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          };
          seriesData.push(sData);
        }

        option.series = seriesData;

        // '1시간' 선택 시,
        if (e.unit === 3) {
          option.xAxis = {
            ...option.xAxis,
            type: "time",
            boundaryGap: true,
            // min : '2021-03-31 00:00',
            // max : '2021-03-31 23:59',
            axisLabel: {
              formatter: function (e) {
                let time = echarts.format.formatTime(name, e) + "시";
                return time;
              },
              textStyle: {
                color: "#f0ebd8",
              },
            },
          };
        } else {
          option.xAxis = {
            ...option.xAxis,
            type: "time",
            boundaryGap: true,
            // min : '2021-03-31 00:00',
            // max : '2021-03-31 23:59',
            axisLabel: {
              formatter: function (e) {
                let time = echarts.format.formatTime(name, e);
                return time;
              },
              textStyle: {
                color: "#f0ebd8",
              },
            },
          };
        }

        option.yAxis = {
          ...option.yAxis,
          type: "value",
          axisLabel: {
            formatter: "{value}대",
            textStyle: {
              color: "#f0ebd8",
            },
          },
        };

        option.dataZoom = [
          {
            type: "inside",
            minSpan: 30,
          },
        ];

        option.legend = {
          data: e.label,
          textStyle: { color: "#f0ebd8" },
          bottom: 5,
        };

        // tooltip
        if (e.unit === 3) {
          option.tooltip = {
            show: true,
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                formatter: function (e) {
                  if (e.seriesData[0].value !== undefined) {
                    let time =
                      echarts.format.formatTime(
                        name,
                        e.seriesData[0].value[0]
                      ) + "시";
                    return time;
                  }
                },
              },
            },
          };
        } else {
          option.tooltip = {
            show: true,
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                formatter: function (e) {
                  if (e.seriesData[0].value !== undefined) {
                    let time = echarts.format.formatTime(
                      name,
                      e.seriesData[0].value[0]
                    );
                    return time;
                  }
                },
              },
            },
          };
        }

        // 배경에 세로줄 지우고 가로줄 생성
        option.xAxis.splitLine.show = false;
        option.yAxis.splitLine.show = true;
      } ///////////////////////// barChart3 ///////////////////////// 4. 교차로비교분석: 2.접근로 별 교통량, 3. 차종분류 / 5.접근로비교분석: 1.회전교통량, 2. 차로별교통량, 3. 차종분류
      else if (e.chartType === "barChart3") {
        option.title.text = e.title;
        let legend = e.legend;
        let xData = legend;

        // yData → 차량수
        let yData = {};
        for (let i of e.data) {
          if (i.IntersectionSeq) {
            if (!yData[i.IntersectionSeq]) yData[i.IntersectionSeq] = [];
            yData[i.IntersectionSeq][i.Series - 1] = i.Value;
            //yData[i.IntersectionSeq].push(i.Value)
          } else if (i.AvenueSeq) {
            if (!yData[i.AvenueSeq]) yData[i.AvenueSeq] = [];
            yData[i.AvenueSeq][i.Series - 1] = i.Value;
            //yData[i.AvenueSeq].push(i.Value)
          }
        }

        let seriesData = [];
        let keys = Object.keys(yData);
        let key = "";
        let sData = "";

        for (let i = 0; i < keys.length; i++) {
          key = keys[i];
          sData = {
            name: e.label[i],
            type: "bar",
            data: yData[key],
          };
          seriesData.push(sData);
        }
        option.series = seriesData;

        option.xAxis = {
          ...option.xAxis,
          type: "category",
          data: xData,
          axisLabel: {
            color: "#f0ebd8",
          },
        };

        option.yAxis = {
          ...option.yAxis,
          type: "value",
          axisLabel: {
            formatter: "{value}대",
            textStyle: {
              color: "#f0ebd8",
            },
          },
        };

        option.dataZoom = [
          {
            type: "inside",
            minSpan: 30,
          },
        ];

        option.legend = {
          data: e.label,
          textStyle: { color: "#f0ebd8" },
          bottom: 5,
        };
      } ///////////////////////// StackChart /////////////////////////
      else if (e.chartType === "stackChart") {
        // 1. 전체교차로 분석: 2.교통량(접근로), 3.교통량(회전), 4.차량종류
        option.title.text = e.title;
        option.legend.data = e.legend;
        let legend = e.legend;

        // yData → 교차로명
        let yData = [];
        let dataLength = Object.keys(e.data).length;
        for (let i = 0; i < dataLength; i++) {
          yData.push(e.data[i].IntersectionName);
        }
        const set = new Set(yData);
        yData = [...set];

        // xData → 차량수
        let xData = [];
        for (let i = 0; i < legend.length; i++) {
          xData.push(new Array(yData.length));
          for (let j = 0; j < yData.length; j++) {
            xData[i][j] = "null";
          }
        }

        // Name → 교통량(접근로):DirectionType, 교통량(회전):MovementType, 차량종류:CarModelType
        e.data.forEach((el, idx) => {
          if (e.data[idx].Name !== null) {
            let value = el["Value"] === 0 ? "" : el["Value"];
            xData[el["Name"] - 1][el["IntersectionSeq"] - 1] = value;
          }
        });

        // xyData의 series 생성
        let seriesData = [];
        for (let i = 0; i < legend.length; i++) {
          let sData = {
            name: legend[i],
            type: "bar",
            stack: "총량",
            label: {
              show: false,
              position: "insideRight",
            },
            data: xData[i],
          };
          seriesData.push(sData);
        }
        option.series = seriesData;

        option.xAxis = {
          ...option.xAxis,
          type: "value",
          axisLabel: {
            color: "#f0ebd8",
            formatter: "{value}대",
          },
        };
        option.yAxis = {
          ...option.yAxis,
          type: "category",
          data: yData,
          axisLabel: {
            color: "#f0ebd8",
          },
        };

        // Main페이지의 '교차로 접근로 교통량', '교차로 차종 교통량'의 경우 그래프 안에 차량수 표시X
        if (
          e.title === "교차로 접근로 교통량" ||
          e.title === "교차로 차종 교통량"
        ) {
          for (let i = 0; i < option.series.length; i++) {
            option.series[i].label.show = false;
          }
          // 레전드의 중앙 배열
          option.legend.padding = [0, 80, 0, 80];
          option.legend.bottom = "2%";
        }
      } ///////////////////////// losChart /////////////////////////  1. 전체교차로 분석: 4.제어지체, 5.서비스수준
      else if (e.chartType === "losChart") {
        option.title.text = e.title;
        // let legend = e.legend
        let yData = [];
        let xData = [];
        let losType = [];
        let dataLength = Object.keys(e.data).length;

        // xData → Value, yData → IntersectionName
        for (let i = 0; i < dataLength; i++) {
          yData.push(e.data[i].IntersectionName);
          xData.push(e.data[i].Value);
          losType.push(this.losColor[e.data[i].LevelofService]);
        }
        const set = new Set(yData);
        yData = [...set];

        option.yAxis = {
          ...option.yAxis,
          type: "category",
          data: yData,
          axisLabel: {
            color: "#f0ebd8",
          },
        };
        option.xAxis = {
          ...option.xAxis,
          type: "value",
          axisLabel: {
            color: "#f0ebd8",
            formatter: "{value}초",
          },
        };

        let seriesData1 = [];
        let seriesData2 = [];
        let seriesData3 = [];
        let markPoint1 = [];
        let markPoint2 = [];
        let markPoint3 = [];

        for (let i = 0; i < e.data.length; i++) {
          // 오전 첨두: 8시
          if (e.data[i].Name === 8) {
            let sData1 = {
              value: xData[i] === 0 ? "" : xData[i],
              itemStyle: { color: losType[i] },
            };
            seriesData1.push(sData1);
            let mData1 = {
              value: e.data[i].LevelofService,
              xAxis: e.data[i].Value,
              yAxis: e.data[i].IntersectionName,
              itemStyle: { color: losType[i], borderColor: "#8b959a" },
            };
            markPoint1.push(e.data[i].LevelofService === "0" ? {} : mData1);
            // 오후 첨두: 18시
          } else if (e.data[i].Name === 18) {
            let sData2 = {
              value: xData[i] === 0 ? "" : xData[i],
              itemStyle: { color: losType[i] },
            };
            seriesData2.push(sData2);
            let mData2 = {
              value: e.data[i].LevelofService,
              xAxis: e.data[i].Value,
              yAxis: e.data[i].IntersectionName,
              itemStyle: { color: losType[i], borderColor: "#8b959a" },
            };
            markPoint2.push(e.data[i].LevelofService === "0" ? {} : mData2);
            // 첨두 18시
          } else {
            let sData3 = {
              value: xData[i] === 0 ? "" : xData[i],
              itemStyle: { color: losType[i] },
            };
            seriesData3.push(sData3);
            let mData3 = {
              value: e.data[i].LevelofService,
              xAxis: e.data[i].Value,
              yAxis: e.data[i].IntersectionName,
              itemStyle: { color: losType[i], borderColor: "#8b959a" },
            };
            markPoint3.push(e.data[i].LevelofService === "0" ? {} : mData3);
          }
        }

        option.legend.data = e.label;

        option.series = [
          {
            name: "오전 첨두",
            type: "bar",
            data: seriesData1,
            label: {},
            markPoint: {
              data: markPoint1,
              symbolSize: 36,
            },
          },
          {
            name: "오후 첨두",
            type: "bar",
            data: seriesData2,
            label: {},
            markPoint: {
              data: markPoint2,
              symbolSize: 36,
            },
          },
          {
            name: "현재시간",
            type: "bar",
            data: seriesData3,
            label: {},
            markPoint: {
              data: markPoint3,
              symbolSize: 36,
            },
          },
        ];

        // '제어지체'인 경우에는 markPoint 표시 X
        if (e.title === "제어지체") {
          for (let i = 0; i < option.series.length; i++) {
            option.series[i].markPoint = {};
            option.series[i].label = { show: true, position: "insideRight" };
          }
        }

        // Main의 '제어지체 및 서비스 수준(LOS)'인 경우에는 markPoint의 크기 줄임
        if (e.title === "제어지체 및 서비스 수준(LOS)") {
          for (let i = 0; i < option.series.length; i++) {
            option.series[i].markPoint.symbolSize = 25;
          }
        }
      } ///////////////////////// lineChart /////////////////////////    1. 차로 분석: 2.접근로별 교통량 3. 차종분류
      else if (e.chartType === "lineChart") {
        option.title.text = e.title;
        option.legend.data = e.legend;
        let legend = e.legend.length === 0 ? [1] : e.legend;
        // let dataLength = Object.keys(e.data).length
        // let label = e.label

        let dataset = {};
        for (let i of e.data) {
          if (!dataset[i.Series]) {
            dataset[i.Series] = [];
          }

          // 대기길이, 점유율 보정
          let value = "";
          if (e.title === "초기 대기길이") {
            value = parseInt(i.WaitingStart);
          } else if (e.title === "종기 대기길이") {
            value = parseInt(i.WaitingEnd);
          } else if (e.title === "점유율") {
            value = parseInt(i.OccupancyMax);
          } else value = parseInt(i.Value);
          dataset[i.Series].push([i.Name, value]);
        }

        //시간보정
        let name = "";
        if (e.unit === 1) {
          name = "hh:mm:ss";
        } else if (e.unit === 2) {
          name = "hh:mm";
        } else if (e.unit === 3) {
          name = "hh";
        }

        let seriesData = [];
        let keys = Object.keys(dataset);
        let key = "";
        let sData = "";

        if (e.title === "접근로별 교통량") {
          for (let i = 0; i < keys.length; i++) {
            key = keys[i];
            sData = {
              name: legend[i],
              type: "line",
              data: dataset[key],
            };
            seriesData.push(sData);
          }
          option.series = seriesData;
        } else {
          for (let i = 0; i < keys.length; i++) {
            key = keys[i];
            sData = {
              name: legend[key - 1],
              type: "line",
              data: dataset[key],
            };
            seriesData.push(sData);
          }
          option.series = seriesData;
        }

        // '1시간' 선택 시,
        if (e.unit === 3) {
          option.xAxis = {
            ...option.xAxis,
            type: "time",
            boundaryGap: true,
            // min : '2021-02-18 00:00',
            // max : '2021-02-18 23:59',
            axisLabel: {
              formatter: function (e) {
                let time = echarts.format.formatTime(name, e) + "시";
                return time;
              },
              textStyle: {
                color: "#f0ebd8",
              },
            },
          };
        } else {
          option.xAxis = {
            ...option.xAxis,
            type: "time",
            boundaryGap: true,
            // min : '2021-02-18 00:00',
            // max : '2021-02-18 23:59',
            axisLabel: {
              formatter: function (e) {
                let time = echarts.format.formatTime(name, e);
                return time;
              },
              textStyle: {
                color: "#f0ebd8",
              },
            },
          };
        }

        option.yAxis = {
          ...option.yAxis,
          type: "value",
          axisLabel: {
            formatter: "{value}대",
            textStyle: {
              color: "#f0ebd8",
            },
          },
        };

        // 초기 대기길이, 종기 대기길이, 점유율, 속도 보정
        // toolTip
        if (
          e.title === "초기 대기길이" ||
          e.title === "종기 대기길이" ||
          e.title === "점유율" ||
          e.title === "속도"
        ) {
          if (e.unit === 3) {
            option.tooltip = {
              show: true,
              trigger: "axis",
              formatter: function (e) {
                if (e[0].value !== undefined) {
                  let label = `${echarts.format.formatTime(
                    name,
                    e[0].value[0]
                  )}시 </br>`;
                  e.forEach((el) => {
                    label += `${el.marker} ${el.value[1]} </br>`;
                  });
                  return label;
                }
              },
              axisPointer: {
                axis: "x",
                type: "shadow",
              },
            };
          } else {
            option.tooltip = {
              show: true,
              trigger: "axis",
              formatter: function (e) {
                if (e[0].value !== undefined) {
                  let label = `${echarts.format.formatTime(
                    name,
                    e[0].value[0]
                  )} </br>`;
                  e.forEach((el) => {
                    label += `${el.marker} ${el.value[1]} </br>`;
                  });
                  return label;
                }
              },
              axisPointer: {
                axis: "x",
                type: "shadow",
              },
            };
          }
        } // 나머지 라인차트 1시간
        else if (e.unit === 3) {
          option.tooltip = {
            show: true,
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                formatter: function (e) {
                  if (e.seriesData[0].value !== undefined) {
                    let time =
                      echarts.format.formatTime(
                        name,
                        e.seriesData[0].value[0]
                      ) + "시";
                    return time;
                  }
                },
              },
            },
          };
        } // 나머지 라인 차트 15분
        else {
          option.tooltip = {
            show: true,
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                formatter: function (e) {
                  if (e.seriesData[0].value !== undefined) {
                    let time = echarts.format.formatTime(
                      name,
                      e.seriesData[0].value[0]
                    );
                    return time;
                  }
                },
              },
            },
          };
        }

        option.dataZoom = [
          {
            type: "inside",
            minSpan: 30,
          },
        ];

        if (e.title === "속도") {
          option.yAxis.axisLabel = {
            formatter: "{value}km/h",
            textStyle: { color: "#f0ebd8" },
          };
        }
        if (e.title === "초기 대기길이" || e.title === "종기 대기길이") {
          option.yAxis.axisLabel = {
            formatter: "{value}m",
            textStyle: { color: "#f0ebd8" },
          };
        }
        if (e.title === "점유율") {
          option.yAxis.axisLabel = {
            formatter: "{value}%",
            textStyle: { color: "#f0ebd8" },
          };
        }

        // 배경에 세로줄 지우고 가로줄 생성
        option.xAxis.splitLine.show = false;
        option.yAxis.splitLine.show = true;
      } ///////////////////////// lineChart2 /////////////////////////
      else if (e.chartType === "lineChart2") {
        option.title.text = e.title;
        // let dataLength = Object.keys(e.data).length
        // let legend = e.legend.length === 0 ? [1] : e.legend
        let label = e.label;

        let dataset = {};
        for (let i of e.data) {
          if (!dataset[i.AvenueSeq]) {
            dataset[i.AvenueSeq] = [];
          }

          // 대기길이, 점유율 보정
          let value = "";
          if (e.title === "초기 대기길이") {
            value = parseInt(i.WaitingStart);
          } else if (e.title === "종기 대기길이") {
            value = parseInt(i.WaitingEnd);
          } else if (e.title === "점유율") {
            value = parseInt(i.OccupancyMax);
          } else value = parseInt(i.Value);
          dataset[i.AvenueSeq].push([i.Name, value]);
        }

        // 시간보정
        let name = "";
        if (e.unit === 1) {
          name = "hh:mm:ss";
        } else if (e.unit === 2) {
          name = "hh:mm";
        } else if (e.unit === 3) {
          name = "hh";
        }

        let seriesData = [];
        let keys = Object.keys(dataset);
        let key = "";
        let sData = "";

        for (let i = 0; i < keys.length; i++) {
          key = keys[i];
          sData = {
            name: label[i],
            type: "line",
            data: dataset[key],
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          };
          seriesData.push(sData);
        }

        option.series = seriesData;

        // '1시간' 선택 시,
        if (e.unit === 3) {
          option.xAxis = {
            ...option.xAxis,
            type: "time",
            boundaryGap: true,
            // min : '2021-02-18 00:00',
            // max : '2021-02-18 23:59',
            axisLabel: {
              formatter: function (e) {
                let time = echarts.format.formatTime(name, e) + "시";
                return time;
              },
              textStyle: {
                color: "#f0ebd8",
              },
            },
          };
        } else {
          option.xAxis = {
            ...option.xAxis,
            type: "time",
            boundaryGap: true,
            // min : '2021-02-18 00:00',
            // max : '2021-02-18 23:59',
            axisLabel: {
              formatter: function (e) {
                let time = echarts.format.formatTime(name, e);
                return time;
              },
              textStyle: {
                color: "#f0ebd8",
              },
            },
          };
        }

        option.yAxis = {
          ...option.yAxis,
          type: "value",
          axisLabel: {
            formatter: "{value}대",
            textStyle: {
              color: "#f0ebd8",
            },
          },
        };

        // toolTip
        if (e.unit === 3) {
          option.tooltip = {
            show: true,
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                formatter: function (e) {
                  if (e.seriesData[0].value !== undefined) {
                    let time =
                      echarts.format.formatTime(
                        name,
                        e.seriesData[0].value[0]
                      ) + "시";
                    return time;
                  }
                },
              },
            },
          };
        } else {
          option.tooltip = {
            show: true,
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                formatter: function (e) {
                  if (e.seriesData[0].value !== undefined) {
                    let time = echarts.format.formatTime(
                      name,
                      e.seriesData[0].value[0]
                    );
                    return time;
                  }
                },
              },
            },
          };
        }

        option.dataZoom = [
          {
            type: "inside",
            minSpan: 30,
          },
        ];

        option.legend = {
          data: e.label,
          textStyle: { color: "#f0ebd8" },
          bottom: 5,
        };

        if (e.title === "속도") {
          option.yAxis.axisLabel = {
            formatter: "{value}km/h",
            textStyle: { color: "#f0ebd8" },
          };
        }
        if (e.title === "초기 대기길이" || e.title === "종기 대기길이") {
          option.yAxis.axisLabel = {
            formatter: "{value}m",
            textStyle: { color: "#f0ebd8" },
          };
        }
        if (e.title === "점유율") {
          option.yAxis.axisLabel = {
            formatter: "{value}%",
            textStyle: { color: "#f0ebd8" },
          };
        }

        // 배경에 세로줄 지우고 가로줄 생성
        option.xAxis.splitLine.show = false;
        option.yAxis.splitLine.show = true;
      } ///////////////////////// stepLineChart /////////////////////////
      else if (e.chartType === "stepLineChart") {
        option.title.text = e.title;
        option.legend.data = e.legend;
        let label = e.label;
        // let legend = e.legend.length === 0 ? [1] : e.legend
        // let dataLength = Object.keys(e.data).length
        // let seriesData = []
        // let losType = []
        let dataset = {};
        for (let i of e.data) {
          if (!dataset[i.Series]) {
            dataset[i.Series] = [];
          }

          // 대기길이, 점유율 보정
          let value = "";
          if (
            e.title === "교차로 제어지체" ||
            e.title === "접근로 제어지체" ||
            e.title === "제어지체"
          ) {
            value = parseInt(i.Value);
          } else if (
            e.title === "교차로 서비스 수준" ||
            e.title === "접근로 서비스 수준" ||
            e.title === "서비스 수준"
          ) {
            value = i.LevelofService;
          }
          dataset[i.Series].push([i.Name, value]);
        }

        //시간보정
        let name = "";
        if (e.unit === 1) {
          name = "hh:mm:ss";
        } else if (e.unit === 2) {
          name = "hh:mm";
        } else if (e.unit === 3) {
          name = "hh";
        }

        let seriesData2 = [];
        let keys = Object.keys(dataset);
        let key = "";
        let sData = "";
        let labelName = "";

        for (let i = 0; i < keys.length; i++) {
          key = keys[i];
          if (e.label === undefined) {
            labelName = "";
          } else {
            labelName = e.label[i];
          }
          sData = {
            name: labelName,
            type: "line",
            step: "auto",
            smooth: false,
            data: dataset[key],
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          };
          seriesData2.push(sData);
        }

        option.series = seriesData2;

        // '1시간' 선택 시,
        if (e.unit === 3) {
          option.xAxis = {
            ...option.xAxis,
            type: "time",
            boundaryGap: true,
            // min : '2021-02-18 00:00',
            // max : '2021-02-18 23:59',
            axisLabel: {
              formatter: function (e) {
                let time = echarts.format.formatTime(name, e) + "시";
                return time;
              },
              textStyle: {
                color: "#f0ebd8",
              },
            },
          };
        } else {
          option.xAxis = {
            ...option.xAxis,
            type: "time",
            boundaryGap: true,
            // min : '2021-02-18 00:00',
            // max : '2021-02-18 23:59',
            axisLabel: {
              formatter: function (e) {
                let time = echarts.format.formatTime(name, e);
                return time;
              },
              textStyle: {
                color: "#f0ebd8",
              },
            },
          };
        }

        option.yAxis = {
          ...option.yAxis,
          type: "value",
          axisLabel: {
            formatter: "{value} 초",
            textStyle: {
              color: "#f0ebd8",
            },
          },
        };

        //서비스 수준 y축 A,B,C,D 보정
        if (
          e.title === "교차로 서비스 수준" ||
          e.title == "서비스 수준" ||
          e.title === "접근로 서비스 수준"
        ) {
          option.yAxis = {
            ...option.yAxis,
            data: ["A", "B", "C", "D", "E", "F", "FF", "FFF"],
            type: "category",
            axisLabel: {
              textStyle: {
                color: "#f0ebd8",
              },
            },
          };
        }

        option.dataZoom = [
          {
            type: "inside",
            minSpan: 30,
          },
        ];

        option.legend = {
          data: label,
          textStyle: { color: "#f0ebd8" },
          bottom: 5,
        };

        //tooltip
        if (e.unit === 3) {
          option.tooltip = {
            show: true,
            trigger: "axis",
            axisPointer: {
              axis: "x",
              type: "shadow",
              label: {
                formatter: function (e) {
                  if (e.seriesData[0].value !== undefined) {
                    let time =
                      echarts.format.formatTime(
                        name,
                        e.seriesData[0].value[0]
                      ) + "시";
                    return time;
                  }
                },
              },
            },
          };
        } else {
          option.tooltip = {
            show: true,
            trigger: "axis",
            axisPointer: {
              axis: "x",
              type: "shadow",
              label: {
                formatter: function (e) {
                  if (e.seriesData[0].value !== undefined) {
                    let time = echarts.format.formatTime(
                      name,
                      e.seriesData[0].value[0]
                    );
                    return time;
                  }
                },
              },
            },
          };
        }
      } ///////////////////////// stepLineChart2 /////////////////////////
      else if (e.chartType === "stepLineChart2") {
        option.title.text = e.title;
        option.legend.data = e.legend;
        let label = e.label;
        // let legend = e.legend.length === 0 ? [1] : e.legend
        // let dataLength = Object.keys(e.data).length
        let seriesData = [];
        // let losType = []

        let dataset = {};
        for (let i of e.data) {
          if (i.AvenueSeq) {
            if (!dataset[i.AvenueSeq]) {
              dataset[i.AvenueSeq] = [];
            }
          } else if (i.IntersectionSeq) {
            if (!dataset[i.IntersectionSeq]) {
              dataset[i.IntersectionSeq] = [];
            }
          }

          // 대기길이, 점유율 보정
          let value = "";
          if (
            e.title === "교차로 제어지체" ||
            e.title === "접근로 제어지체" ||
            e.title === "제어지체"
          ) {
            value = parseInt(i.Value);
          } else if (
            e.title === "교차로 서비스 수준" ||
            e.title === "접근로 서비스 수준" ||
            e.title === "서비스 수준"
          ) {
            value = i.LevelofService;
          }
          dataset[i.Series].push([i.Name, value]);
        }

        //시간보정
        let name = "";
        if (e.unit === 1) {
          name = "hh:mm:ss";
        } else if (e.unit === 2) {
          name = "hh:mm";
        } else if (e.unit === 3) {
          name = "hh";
        }

        seriesData = [];
        let keys = Object.keys(dataset);
        let key = "";
        let sData = "";
        let labelName = "";

        for (let i = 0; i < keys.length; i++) {
          key = keys[i];
          if (e.label === undefined) {
            labelName = "";
          } else {
            labelName = e.label[i];
          }
          sData = {
            name: labelName,
            type: "line",
            step: "auto",
            smooth: false,
            data: dataset[key],
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          };
          seriesData.push(sData);
        }

        option.series = seriesData;

        option.xAxis = {
          ...option.xAxis,
          type: "time",
          boundaryGap: true,
          // min : '2021-02-18 00:00',
          // max : '2021-02-18 23:59',
          axisLabel: {
            formatter: function (e) {
              let time = echarts.format.formatTime(name, e) + "시";
              return time;
            },
            textStyle: {
              color: "#f0ebd8",
            },
          },
        };

        option.yAxis = {
          ...option.yAxis,
          type: "value",
          axisLabel: {
            formatter: "{value} 초",
            textStyle: {
              color: "#f0ebd8",
            },
          },
        };

        // 서비스 수준 y축 A,B,C,D 보정
        if (
          e.title === "교차로 서비스 수준" ||
          e.title == "서비스 수준" ||
          e.title === "접근로 서비스 수준"
        ) {
          option.yAxis = {
            ...option.yAxis,
            data: ["A", "B", "C", "D", "E", "F", "FF", "FFF"],
            type: "category",
            axisLabel: { color: "#f0ebd8" },
          };
        }

        option.dataZoom = [
          {
            type: "inside",
            minSpan: 30,
          },
        ];

        option.legend = {
          data: label,
          textStyle: { color: "#f0ebd8" },
          bottom: 5,
        };

        // toolTip
        option.tooltip = {
          show: true,
          trigger: "axis",
          axisPointer: {
            axis: "x",
            type: "shadow",
            label: {
              formatter: function (e) {
                if (e.seriesData[0].value !== undefined) {
                  let time =
                    echarts.format.formatTime(name, e.seriesData[0].value[0]) +
                    "시";
                  return time;
                }
              },
            },
          },
        };
      }

      /////////////////////////stepLine2 End/////////////////////////////

      if (e.toolbox === 1) {
        option.toolbox.feature.magicType = {
          show: true,
          type: ["bar", "line"],
          title: {
            bar: "BarChart",
            line: "LineChart",
          },
        };
        option.toolbox.feature.restore.show = true;
      } else if (e.toolbox === 2) {
        option.toolbox.feature.magicType = {
          show: true,
          type: ["bar", "line", "stack", "tiled"],
          title: {
            bar: "BarChart",
            line: "LineChart",
            stack: "StackedChart",
            tiled: "TiledChart",
          },
        };
        option.toolbox.feature.restore.show = true;
      } else {
        option.toolbox.feature.magicType.show = false;
        option.toolbox.feature.restore.show = false;
      }

      if (!e.isKeep) this.rendererChart(option);
    },
  },
};
</script>
