<template>
	<!-- 탭 메뉴 셋 -->
	<div id="cont3" class="tabPanel">
		<h1>포화교통량 및 용량계산</h1>

		<!-- 테이블1 : 포화교통량 계산 -->
		<table>
			<colgroup>
				<col width="13%" />
				<col width="10%" />
				<col
					v-for="(item, idx) in processingData.length * 3"
					:key="idx"
					:width="`${77 / (processingData.length * 3)}%`"
				/>
			</colgroup>
			<thead>
				<tr>
					<th colspan="14">포화 교통량 계산</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td colspan="2" rowspan="2">접근로</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.directionName }}
					</td>
				</tr>
				<tr>
					<td v-for="(item, idx) in processingData.length * 3" :key="idx">
						{{ (idx + 1) % 3 === 0 ? 'RT' : (idx + 1) % 3 === 1 ? 'LT' : 'TH' }}
					</td>
				</tr>
				<tr>
					<td colspan="2">이동류 교통량</td>
					<td v-for="(item, idx) in processingData.length * 3" :key="idx">
						{{
							processingData[Math.floor(idx / 3)]
								? processingData[Math.floor(idx / 3)].data[4 + (idx % 3)]
									? processingData[Math.floor(idx / 3)].data[4 + (idx % 3)]
											.value
									: ''
								: ''
						}}
					</td>
				</tr>
				<tr>
					<td colspan="2">회전직진환산계수</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[32] ? item.data[32].value : ''
						}}{{ item.data[35] ? ` / ${item.data[35].value}` : '' }}
					</td>
				</tr>
				<tr>
					<td rowspan="6">차로군별 교통량(vph)</td>
					<td>공용 LT</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[41] ? item.data[41].value : '' }}
					</td>
				</tr>
				<tr>
					<td>공용 RT</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[42] ? item.data[42].value : '' }}
					</td>
				</tr>
				<tr>
					<td>실질 LT</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[43] ? item.data[43].value : '' }}
					</td>
				</tr>
				<tr>
					<td>실질 RT</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[44] ? item.data[44].value : '' }}
					</td>
				</tr>
				<tr>
					<td>실질 TH</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[45] ? item.data[45].value : '' }}
					</td>
				</tr>
				<tr>
					<td>통합차로군</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[46] ? item.data[46].value : '' }}
					</td>
				</tr>
				<tr>
					<td colspan="2">회전교통량비</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[47] ? item.data[47].value : ''
						}}{{ item.data[48] ? ` / ${item.data[48].value}` : '' }}
					</td>
				</tr>
				<tr>
					<td colspan="2">회전보정계수</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[49] ? item.data[49].value : ''
						}}{{ item.data[50] ? ` / ${item.data[50].value}` : '' }}
					</td>
				</tr>
				<tr>
					<td colspan="2">차로폭보정계수</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[51] ? item.data[51].value : '' }}1
					</td>
				</tr>
				<tr>
					<td colspan="2">경사보정계수</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[52] ? item.data[52].value : '' }}1
					</td>
				</tr>
				<tr>
					<td colspan="2">중차량보정계수</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[53] ? item.data[53].value : '' }}0.9615
					</td>
				</tr>
				<tr>
					<td colspan="2">포화교통량 계산</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[54] ? item.data[54].value : ''
						}}{{ item.data[55] ? ` / ${item.data[55].value}` : '' }}
					</td>
				</tr>
			</tbody>
		</table>
		<!-- 테이블2 : 포화교통량 계산 -->
		<table>
			<colgroup>
				<col width="23%" />
				<col
					v-for="(item, idx) in processingData.length * 3"
					:key="idx"
					:width="`${77 / (processingData.length * 3)}%`"
				/>
			</colgroup>
			<thead>
				<tr>
					<th colspan="13">용량 계산</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td rowspan="2">접근로</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.directionName }}
					</td>
				</tr>
				<tr>
					<td v-for="(item, idx) in processingData.length * 3" :key="idx">
						{{ (idx + 1) % 3 === 0 ? 'RT' : (idx + 1) % 3 === 1 ? 'LT' : 'TH' }}
					</td>
				</tr>
				<tr>
					<td>차로군 교통량</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[56] ? item.data[56].value : ''
						}}{{ item.data[57] ? ` / ${item.data[57].value}` : '' }}
					</td>
				</tr>
				<!-- <tr>
          <td>차로군교통량비</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx">{{item.data[58]?item.data[58].value:''}}</td>
        </tr>
        <tr>
          <td>현시의 임계자료군</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx">{{item.data[59]?item.data[59].value:''}}</td>
        </tr>
        <tr>
          <td>임계자료군의 V/S합</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx">{{item.data[60]?item.data[60].value:''}}</td>
        </tr> -->
				<tr>
					<td>차로군 녹색시간비</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[61] ? item.data[61].value : ''
						}}{{ item.data[62] ? ` / ${item.data[62].value}` : '' }}
					</td>
				</tr>
				<tr>
					<td>차로군용량</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[63] ? item.data[63].value : ''
						}}{{ item.data[64] ? ` / ${item.data[64].value}` : '' }}
					</td>
				</tr>
				<tr>
					<td>차로군포화도</td>
					<td colspan="3" v-for="(item, idx) in processingData" :key="idx">
						{{ item.data[65] ? item.data[65].value : ''
						}}{{ item.data[66] ? ` / ${item.data[66].value}` : '' }}
					</td>
				</tr>
				<!-- <tr>
          <td>손실시간 임계 V/c비</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx">{{item.data[67]?item.data[67].value:''}}</td>
        </tr>
        <tr>
          <td>석정주기(설계분석)</td>
          <td colspan="3" v-for="(item,idx) in processingData" :key="idx"></td>
        </tr> -->
			</tbody>
		</table>
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	props: {
		processingData: {
			type: Array,
			default() {
				return []
			},
		},
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {},
	},
}
</script>
