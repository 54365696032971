import Vue from 'vue'
import store from '../store/index'

export const JSON_WS_BUS = new Vue({
	data() {
		return {
			ws: {
				json: null,
				ip: window.location.hostname,
				//ip: `10.30.2.24`,
				port: `8001`,
			},
		}
	},

	methods: {
		wsJsonClient(callBack) {
			this.ws.json = new WebSocket(`ws://${this.ws.ip}:${this.ws.port}`)
			this.ws.json.onopen = () => {
				this.wsProtocolGetAll()
				if (callBack) callBack()
			}

			this.ws.json.onclose = () => {
				//this.ws.json.close()
				this.ws.json = null
				store.dispatch(`globalStore/AC_MAIN`, { key: `disCard`, val: true })
				store.dispatch(`globalStore/AC_MAIN`, {
					key: `progressShow`,
					val: false,
				})
				// setTimeout(() => {
				//   console.log(`json ws[${this.ws.port}] reconnect`)
				//   this.wsJsonClient()
				// }, 500)
			}

			this.ws.json.onerror = e =>
				console.log(`json ws[${this.ws.port}] error: ${e}`)

			this.ws.json.onmessage = msg => {
				try {
					//console.log(`json ws[${this.ws.port}] message: ${msg.data}`)
					const obj = JSON.parse(msg.data)
					this.protocolParser(obj)
				} catch (e) {
					console.log(`json ws[${this.ws.port}] message error: ${e}`)
				}
			}
		},

		protocolParser(obj) {
			if (obj.CommendType !== undefined)
				store.dispatch(`protocolStore/AC_COMMEND_TYPE`, obj.CommendType)
			if (obj.AppInfo !== undefined) {
				store.dispatch(`protocolStore/AC_APP_INFO`, obj.AppInfo)

				let color = `white`
				switch (obj.AppInfo.Customer) {
					case `anyang`:
						color = `blue`
						break
					case `sejong`:
						color = `blue`
						break
					case `gwangju`:
						color = `red`
						break
				}
				store.dispatch(`globalStore/AC_MAIN`, { key: `mainColor`, val: color })
			}
			if (obj.CommonOption !== undefined)
				store.dispatch(`protocolStore/AC_COMMON_OPTION`, obj.CommonOption)
			if (obj.TrainFile !== undefined)
				store.dispatch(`protocolStore/AC_TRAIN_FILE`, obj.TrainFile)
			if (obj.IPConfig !== undefined)
				store.dispatch(`protocolStore/AC_IP_CONFIG`, obj.IPConfig)

			if (obj.CenterInfo !== undefined)
				store.dispatch(`protocolStore/AC_CENTER_INFO`, obj.CenterInfo)
			if (obj.SignalInfo !== undefined)
				store.dispatch(`protocolStore/AC_SIGNAL_INFO`, obj.SignalInfo)
			if (obj.VoiceGuideInfo !== undefined)
				store.dispatch(`protocolStore/AC_VOICE_GUIDE_INFO`, obj.VoiceGuideInfo)
			if (obj.VMSInfo !== undefined)
				store.dispatch(`protocolStore/AC_VMS_INFO`, obj.VMSInfo)
			if (obj.SpotLightInfo !== undefined)
				store.dispatch(`protocolStore/AC_SPOT_LIGHT_INFO`, obj.SpotLightInfo)
			if (obj.FloorIndicatorInfo !== undefined)
				store.dispatch(
					`protocolStore/AC_FLOOR_INDICATOR_INFO`,
					obj.FloorIndicatorInfo,
				)

			if (obj.Configration !== undefined)
				store.dispatch(`protocolStore/AC_CONFIGRATION`, obj.Configration)
			if (obj.ScreenShot !== undefined)
				store.dispatch(`protocolStore/AC_SCREENSHOT`, obj.ScreenShot)
			if (obj.PTZ !== undefined) store.dispatch(`protocolStore/AC_PTZ`, obj.PTZ)

			if (obj.VideoSource !== undefined)
				store.dispatch(`protocolStore/AC_VIDEO_SOURCE`, obj.VideoSource)
			if (obj.ChannelState !== undefined)
				store.dispatch(`protocolStore/AC_CHANNEL_STATE`, obj.ChannelState)
			if (obj.Action !== undefined)
				store.dispatch(`protocolStore/AC_ACTION`, obj.Action)
			if (obj.Approach !== undefined)
				store.dispatch(`protocolStore/AC_APPROACH`, obj.Approach)
			if (obj.CarDetectROI !== undefined)
				store.dispatch(`protocolStore/AC_CAR_DETECT_ROI`, obj.CarDetectROI)
			if (obj.CarCountROI !== undefined)
				store.dispatch(`protocolStore/AC_CAR_COUNT_ROI`, obj.CarCountROI)
			if (obj.PersonDetectROI !== undefined)
				store.dispatch(
					`protocolStore/AC_PERSON_DETECT_ROI`,
					obj.PersonDetectROI,
				)
			if (obj.ChannelOption !== undefined)
				store.dispatch(`protocolStore/AC_CHANNEL_OPTION`, obj.ChannelOption)
			if (obj.Signal !== undefined)
				store.dispatch(`protocolStore/AC_SIGNAL`, obj.Signal)

			if (obj.CarState !== undefined)
				store.dispatch(`protocolStore/AC_CAR_STATE`, obj.CarState)
			if (obj.CarCount !== undefined)
				store.dispatch(`protocolStore/AC_CAR_COUNT`, obj.CarCount)
			if (obj.PersonState !== undefined)
				store.dispatch(`protocolStore/AC_PERSON_STATE`, obj.PersonState)
			if (obj.PersonCount !== undefined)
				store.dispatch(`protocolStore/AC_PERSON_COUNT`, obj.PersonCount)
		},

		wsProtocolGetAll() {
			this.wsSend({ Serial: 0, CommendType: `get_all` })
		},

		wsSend(obj) {
			const json = JSON.stringify(obj)

			if (!store.getters[`globalStore/GET_DEV_MODE`]) this.ws.json.send(json)
		},
	},

	created() {
		// console.log(`created:wsBus`)
	},
})
