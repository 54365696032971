<template>
  <div class="login">
    <div class="dummy">
      <div class="container">
        <v-dialog
          max-width="520"
          v-model="isDialog"
          persistent
          no-click-animation
        >
          <DialogComponentVue
            @closehandler="dialogCloseHandler"
            :dialogOption="dialogOption"
            :submitMode="dialogSubmit"
            @eventHandler="dialogActionHandler"
          />
        </v-dialog>
        <div class="loginArea">
          <h1>LaonRoad TMAAS</h1>
          <h4>Smart City Traffic Monitoring</h4>
          <div>
            <div>
              <ul>
                <li class="id">
                  <div
                    :class="[
                      errorOption.pwError || errorOption.idError
                        ? 'loginArea-list input-error'
                        : 'loginArea-list',
                    ]"
                  >
                    <span></span>
                    <input
                      id="id"
                      :disabled="authCheck"
                      type="text"
                      placeholder="User ID"
                      v-model="user.userid"
                    />
                  </div>
                </li>
                <li class="pw">
                  <div
                    :class="[
                      errorOption.pwError || errorOption.idError
                        ? 'loginArea-list input-error'
                        : 'loginArea-list',
                    ]"
                  >
                    <span></span>
                    <input
                      id="pw"
                      :disabled="authCheck"
                      type="password"
                      placeholder="Password"
                      v-model="user.password"
                      v-on:keyup.enter="loginButtonHandler"
                    />
                  </div>
                  <div
                    v-show="errorOption.pwError || errorOption.idError"
                    class="loginArea-error"
                  >
                    계정정보를 다시 확인해주세요
                  </div>
                </li>
                <li class="email" v-show="authCheck">
                  <div>
                    <span></span>
                    <div
                      :class="[
                        errorOption.mailError
                          ? 'loginArea-input__email input-error'
                          : 'loginArea-input__email',
                      ]"
                    >
                      <input
                        type="text"
                        placeholder="email code"
                        v-model="user.emailAuth"
                        v-on:keyup.enter="loginButtonHandler"
                      />
                      <p>{{ authEndTime }}</p>
                    </div>
                    <button @click="reSendMailHandler">재전송</button>
                  </div>
                  <div v-show="errorOption.mailError" class="loginArea-error">
                    email code를 다시 확인해주세요.
                  </div>
                </li>
                <div class="btnLogin" v-on:click="loginButtonHandler">
                  Login
                </div>
                <div class="loginArea-find__wrap">
                  <button @click="findIdHandler">ID 찾기</button>
                  <button @click="findPasswordHandler">Password 찾기</button>
                </div>
                <div class="docs-find__wrap">
                  <button @click="() => openFomula('privacy')">
                    개인정보처리방침
                  </button>
                  <button @click="() => openFomula('term')">이용약관</button>
                </div>
              </ul>
              <!-- <router-link to="/signUp">가입하기</router-link> -->
              <div class="etc">
                <!--<a href="javascript:;">아이디찾기 | </a>
                  <a href="javascript:;">비밀번호찾기</a>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import {
  mailAuthAPI,
  passwordUpdateAPI,
  findUserPasswordAPI,
  findUserIdAPI,
} from "../../api/trafficAPI/index";
import DialogComponentVue from "../../components/DialogComponent.vue";
import { dialogMixins } from "../../mixins/dialogMixins";
import { encryptPassword } from "../../../public/js/encrypt";
import { getRealIp } from "../../api";

const loginStore = "loginStore";
const globalStore = "globalStore";

export default {
  mixins: [dialogMixins],
  created() {
    // this.AC_TOKEN_CHECK({Region: this.getHostName})
    // .then((path) => {
    //   switch (path) {
    //     case `Main`: this.$router.push({name: `Main`, replace: true}); break
    //     case `login`:
    //       if(window.location.pathname !== `/login`) {
    //         this.$router.push({name: `login`})
    //       }
    //     break
    //   }
    // })
    // .catch((e) => {
    //     console.error(e.message)
    // })
  },
  components: {
    DialogComponentVue,
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  async mounted() {
    if(this.getHostName === 'tmaas') {
      const ip = await getRealIp();
      const whiteList = process.env.VUE_APP_IP_WHITE_LIST.replace(
        /\s/gi,
        ""
      ).split(",");
  
      if (!whiteList.includes(ip)) {
        alert('접근권한이 없습니다.');
        this.$router.push("/error");
      }
    }
    this.initInput();
  },
  data() {
    return {
      user: {
        userid: "",
        password: "",
        emailAuth: "",
        email: "",
      },
      authCheck: false,
      usingAuth: false,
      emailAuthTime: 0,
      timer: null,
      errorOption: {
        idError: false,
        pwError: false,
        mailError: false,
      },
    };
  },
  methods: {
    ...mapActions(loginStore, [`AC_TOKEN_CHECK`, `AC_LOGIN`]),
    ...mapMutations(globalStore, [
      "setIsAlert",
      "setMailFailedIncrement",
      "setMailFailedReset",
    ]),
    reSendMailHandler() {
      this.mailAuth();
    },
    openFomula(mode) {
      let fomulaPage;
      let title;
      switch (mode) {
        case "privacy":
          fomulaPage = window.open("/storage/privacy_policy.pdf");
          title = "개인정보 처리방침";
          break;
        case "term":
          fomulaPage = window.open("/storage/terms_of_use.pdf");
          title = "이용약관";
          break;
        default:
          fomulaPage = window.open("/storage/privacy_policy.pdf");
          title = "개인정보 처리방침";
          break;
      }

      window.setTimeout(() => {
        fomulaPage.document.title = title;
      }, 100);
    },
    findPasswordHandler() {
      this.setIsAlert(false);
      this.isDialog = true;
      this.dialogOption.mode = "findPw";
      this.dialogSubmit = true;
      this.dialogOption.title = "비밀번호 찾기";
      this.dialogOption.body = "임시 비밀번호는 Email로 전송됩니다.";
    },
    findIdHandler() {
      this.setIsAlert(false);
      this.isDialog = true;
      this.dialogOption.mode = "findId";
      this.dialogSubmit = true;
      this.dialogOption.title = "ID 찾기";
    },
    findPasswordEvent(modalParams) {
      findUserPasswordAPI(modalParams).then((res) => {
        if (res.data.success) {
          this.changeDialogSuccess(true);
        } else {
          this.dialogOption.dupleCheck = true;
        }
      });
    },
    findIdEvent(modalParams) {
      findUserIdAPI(modalParams).then((res) => {
        if (res.data.success) {
          this.dialogOption.body = res.data.data.UserID;
          this.changeDialogSuccess(true);
        } else {
          this.dialogOption.dupleCheck = true;
        }
      });
    },
    mailAuth() {
      mailAuthAPI({
        user: {
          userid: this.user.userid,
          password: encryptPassword(this.user.password),
        },
        Region: this.getHostName,
      }).then((res) => {
        if(!res) {
          this.errorOption.idError = true;
          return;
        }
        switch (res.status) {
          case 200:
            if (this.timer) {
              clearTimeout(this.timer);
            }
            this.authCheck = true;
            this.emailAuthTime = res.data.seconds;
            break;
          case 520:
            this.isDialog = true;
            break;
          case 225:
            this.errorOption.idError = true;
            break;
          case 521:
            this.errorOption.idError = true;
            break;
          case 221:
            this.errorOption.pwError = true;
            // this.$router.push('/error')
            break;
          case 222:
            this.isDialog = true;
            this.dialogOption.title = "로그인 시도 횟수초과";
            this.dialogOption.body =
              "5회 이상 로그인에 실패하여 제한되었습니다.";
            break;
          case 223:
            this.isDialog = true;
            this.dialogOption.title = "사용 정지 계정";
            this.dialogOption.body = "해당 계정은 사용 정지 되었습니다.";
            break;
          case 224:
            this.user.email = res.data.data.email;
            this.isDialog = true;
            this.dialogSubmit = true;
            this.dialogOption.title = "비밀번호 변경";
            this.dialogOption.body =
              "신규가입 및 임시 비밀번호 발급 시 비밀번호 변경이 필요합니다.";
            this.dialogOption.mode = "update";
            break;
        }
      });
    },
    initInput() {
      this.user = {
        userid: "",
        password: "",
        emailAuth: "",
        email: "",
      };
      this.dialogSubmit = false;
    },
    login() {
      if (this.user.userid === ``) return alert(`아이디를 입력해 주세요.`);
      if (this.user.password === ``) return alert(`암호를 입력해 주세요.`);

      const apiParams = {
        emailAuth: this.user.emailAuth,
        userid: this.user.userid,
        password: encryptPassword(this.user.password),
      };

      this.AC_LOGIN({ user: apiParams, Region: this.getHostName })
        .then((r) => {
          if (r === true) {
            if (this.getHostName === "tmaas") {
              this.$router.push({ name: `Management` });
              return;
            } else {
              this.$router.push({ name: `Main` });
              return;
            }
          }
          const actionParams = {
            isQuater: true,
            nextDate: r.data.passwordChange,
          };
          switch (r.status) {
            case 221:
              this.errorOption.mailError = true;
              this.setMailFailedIncrement();
              break;
            case 222:
              this.changeQuaterCheck(actionParams);
              if (this.getHostName === "tmaas") {
                this.$router.push({ name: "Management" });
              } else {
                this.$router.push({ name: `Main` });
              }
              break;
            default:
              alert("Login Error!");
              break;
          }
        })
        .catch((e) => {
          console.error(e.message);
        });
    },
    loginButtonHandler() {
      if (this.authCheck) {
        this.login();
      } else {
        this.setIsAlert(false);
        this.mailAuth();
      }
    },
    transMinutes(second) {
      const minutes =
        parseInt((second % 3600) / 60) < 10
          ? "0" + parseInt((second % 3600) / 60)
          : parseInt((second % 3600) / 60);
      const seconds = second % 60 < 10 ? "0" + (second % 60) : second % 60;
      const authTime = `${minutes} : ${seconds}`;
      return authTime;
    },
    dialogActionHandler(modalParams) {
      this.initCheck();
      switch (this.dialogOption.mode) {
        case "update":
          this.updateUser(modalParams);
          break;
        case "findPw":
          this.findPasswordEvent(modalParams);
          break;
        case "findId":
          this.findIdEvent(modalParams);
          break;
      }
    },
    updateUser(modalParams) {
      const apiParams = {
        userID: this.user.userid,
        oldPassword: encryptPassword(modalParams.oldPassword),
        newPassword: encryptPassword(modalParams.newPassword),
        checkPassword: encryptPassword(modalParams.checkPassword),
      };
      passwordUpdateAPI(apiParams).then((res) => {
        if (res.data.success) {
          this.changeDialogSuccess(true);
          this.initInput();
          return;
        }

        switch (res.status) {
          case 220:
            this.dialogOption.validate = true;
            break;
          case 222:
            this.dialogOption.dupleCheck = true;
            break;
        }
      });
    },
    resetUserInput() {
      this.user = { userid: "", password: "", emailAuth: "", email: "" };
    },
    emailFailedModal() {
      this.setIsAlert(false);
      this.isDialog = true;
      this.dialogOption.title = "Email 인증 실패";
      this.dialogOption.body = "email 인증을 5회 이상 실패했습니다.";
    },
  },
  computed: {
    ...mapGetters(globalStore, ["getHostName", "getMailFailedCount"]),
    authEndTime() {
      return this.transMinutes(this.emailAuthTime);
    },
    mailFailedChecker() {
      return this.getMailFailedCount;
    },
  },
  watch: {
    emailAuthTime: {
      handler(value) {
        if (value > 0) {
          this.timer = setTimeout(() => {
            this.emailAuthTime--;
          }, 1000);
        }
      },
    },
    mailFailedChecker: function (count) {
      if (count >= 5) {
        this.emailFailedModal();
        this.setMailFailedReset();
        this.authCheck = false;
        this.resetUserInput();
        this.errorOption.mailError = false;
      }
    },
    "user.userid": function () {
      this.errorOption.idError = false;
    },
    "user.password": function () {
      this.errorOption.pwError = false;
    },
    "user.emailAuth": function () {
      this.errorOption.mailError = false;
    },
  },
};
</script>
