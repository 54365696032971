<template>
	<div class="">설정파일관리</div>
</template>
<script>
export default {
	data() {
		return {
			//menu: false
		}
	},
	created() {},
}
</script>
