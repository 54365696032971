<template>
	<v-app>
		<router-view />
	</v-app>
</template>

<script>
import { mapGetters } from 'vuex'
const globalStore = 'globalStore'
export default {
	data() {
		return {}
	},
	created() {},
	async mounted() {
		const favicon = document.getElementById('favicon')
		const hostName = this.getHostName

		switch (hostName) {
			case 'localhost':
				favicon.href = require('@/assets/favicon/favicon_demo3.png')
				break
			case 'anyang':
				favicon.href = require('@/assets/favicon/anyang-logo-color.png')
				break
			case 'seongnam':
				favicon.href = require('@/assets/favicon/seongnam-logo-color.png')
				break
			case 'tmaas':
				favicon.href = require('@/assets/favicon/favicon_demo3.png')
				break
		}
	},
	computed: {
		...mapGetters(globalStore, ['getHostName']),
	},
}
</script>
<style scoped>
.theme--light.v-application {
	background: none;
	color: inherit;
}
.v-application {
	font-family: NanumGothic, '돋움', Dotum, '굴림', Gulim, Tahoma, Verdana,
		AppleGothic, sans-serif;
	line-height: 1;
	/* display: block; */
}
</style>
