import Vue from 'vue'
import Vuex from 'vuex'

import loginStore from '@/store/modules/loginStore.js'
import globalStore from '@/store/modules/globalStore.js'
import protocolStore from '@/store/modules/protocolStore.js'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		loginStore: loginStore,
		globalStore: globalStore,
		protocolStore: protocolStore,
	},
})
