import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
// import { mapGetters } from 'vuex'

import SignUp from "@/views/Login/SignUp.vue";
import Login from "@/views/Login/Login.vue";
// import NotFound from '../views/NotFound.vue'

import Master from "@/views/Master.vue";
import Main from "@/views/Main/Main.vue";

//교통정보 분석 메뉴
import AllIntersection from "@/views/TrafficAnalysis/AllIntersection.vue";
import Intersection from "@/views/TrafficAnalysis/Intersection.vue";
import Avenue from "@/views/TrafficAnalysis/Avenue.vue";
import TimeSeries from "@/views/TrafficAnalysis/TimeSeries.vue";
import CompareAvenue from "@/views/TrafficAnalysis/CompareAvenue.vue";
import CompareIntersection from "@/views/TrafficAnalysis/CompareIntersection.vue";
import CurrentState from "@/views/TrafficAnalysis/CurrentState.vue";

//딥러닝 영상 분석 메뉴
import LiveView from "@/views/VideoAnalysis/LiveView.vue";
import DetectionZone from "@/views/VideoAnalysis/DetectionZone.vue";
import CountingZone from "@/views/VideoAnalysis/CountingZone.vue";
import PedestrianZone from "@/views/VideoAnalysis/PedestrianZone.vue";
import TrainingFile from "@/views/VideoAnalysis/TrainingFile.vue";
import OptionFile from "@/views/VideoAnalysis/OptionFile.vue";

//교차로 설정 메뉴
import Camera from "@/views/IntersectionMgt/Camera.vue";
import Controller from "@/views/IntersectionMgt/Controller.vue";
import IntersectionInfo from "@/views/IntersectionMgt/IntersectionInfo.vue";
import EtcInfo from "@/views/IntersectionMgt/EtcInfo.vue";

//관리자 메뉴
import Users from "@/views/AdminMgt/Users.vue";
// import Setting from '@/views/AdminMgt/Setting.vue'
import SystemInfo from "@/views/AdminMgt/SystemInfo.vue";
import SystemEvent from "@/views/AdminMgt/SystemEvent.vue";
import EventInfo from "@/views/AdminMgt/EventInfo.vue";
// from '@/views/AdminMgt/AddUsers.vue'

// 미터링 페이지
import MeteringUserView from "@/views/Metering/MeteringUserView.vue";
import ManagementView from "@/views/Management/ManagementView.vue";
import Policy from "../views/Policy/Policy.vue";
import ReportPage from "../views/Report/ReportPage.vue";
import SignReport from "../views/TrafficAnalysis/SignReport.vue";



import ErrorComponent from "@/components/ErrorComponent.vue";
import { decodeToken } from "../../public/js/encrypt";
import { encryptPassword } from "../../public/js/encrypt";

import { decryptValue } from "../../public/js/encrypt";
import { getRealIp } from "../api";

Vue.use(VueRouter);

const hostName = store.getters["globalStore/getHostName"];
const userAuth = store.getters["loginStore/getUserAuth"];

const ipCheckHandler = async () => {
  const ip = await getRealIp();
  const whiteList = process.env.VUE_APP_IP_WHITE_LIST.replace(/\s/gi, "").split(
    ","
  );
  return whiteList.includes(ip);
}

const requireAuth = (menuLevel) => async (from, to, next) => {
  if(hostName === 'tmaas') {
    const checkResponse = await ipCheckHandler();
    if(!checkResponse) {
      alert('접근권한이 없습니다.');
      next('/error');
      return false;
    }
  }
  store
    .dispatch(`loginStore/AC_TOKEN_CHECK`, {
      menuLevel: menuLevel,
      Region: hostName,
    })
    .then((path) => {
      if (!userAuth) {
        const decoded = decodeToken(sessionStorage.getItem("accesstoken"));
        if (decoded) {
          store.dispatch("loginStore/changeUserAuth", decoded.userAuth);
        }
      }
      switch (
        typeof path === "object" ? decryptValue(path.path) : decryptValue(path)
      ) {
        case `Main`:
          store.dispatch("globalStore/changeMenu", true);
          next();
          break;
        case `denied`:
          alert(`접근권한이 없습니다.`);
          break;
        case `login`:
          next("/login");
          sessionStorage.clear();
          break;
        default:
          sessionStorage.clear();
          this.$router.push("/login");
          break;
      }
    });
};

const titleChangeHandler = (hostName) => {
  let title = "";
  switch (hostName) {
    case "anyang":
      title = `안양시 스마트 교차로`;
      break;
    case "seongnam":
      title = `성남시 스마트 교차로`;
      break;
    case "localhost":
      title = `로컬 스마트 교차로`;
      break;
    default:
      title = "스마트 교차로";
      break;
  }

  return title;
};

let routes = [];
if (hostName === "tmaas") {
  routes = [
    {
      path: "/",
      component: Master,
      children: [
        {
          path: "/",
          name: "Management",
          component: ManagementView,
          beforeEnter: requireAuth(100),
        },
        // {
        // 	path: '/',
        // 	name: 'Main',
        // 	component: Main,
        // 	beforeEnter: requireAuth(1),
        // },
        {
          path: "/managemantView",
          name: "Management",
          component: ManagementView,
          beforeEnter: requireAuth(100),
        },
        {
          path: "/Users",
          name: "Users",
          component: Users,
          beforeEnter: requireAuth(100),
        },
        {
          path: "/meteringUser",
          name: "MeteringUser",
          component: MeteringUserView,
          beforeEnter: requireAuth(100),
        },
        
      ],
    },
    {
      path: "/policy",
      name: "Policy",
      component: Policy
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/signUp",
      name: "SignUp",
      component: SignUp,
    },
    {
      path: "*",
      component: ErrorComponent,
    },
  ];
} else {
  routes = [
    {
      path: "/",
      component: Master,
      children: [
        {
          path: "/",
          name: "Main",
          component: Main,
          beforeEnter: requireAuth(1),
        },
        {
          path: "/allIntersection",
          name: "AllIntersection",
          component: AllIntersection,
          beforeEnter: requireAuth(1),
        },
        {
          path: "/intersection",
          name: "Intersection",
          component: Intersection,
          beforeEnter: requireAuth(1),
        },
        {
          path: "/avenue",
          name: "Avenue",
          component: Avenue,
          beforeEnter: requireAuth(1),
        },
        {
          path: "/timeSeries",
          name: "TimeSeries",
          component: TimeSeries,
          beforeEnter: requireAuth(1),
        },
        {
          path: "/compareIntersection",
          name: "CompareIntersection",
          component: CompareIntersection,
          beforeEnter: requireAuth(1),
        },
        {
          path: "/compareAvenue",
          name: "CompareAvenue",
          component: CompareAvenue,
          beforeEnter: requireAuth(1),
        },
        {
          path: "/currentState",
          name: "CurrentState",
          component: CurrentState,
          beforeEnter: requireAuth(1),
        },

        {
          path: "/liveView",
          name: "LiveView",
          component: LiveView,
          beforeEnter: requireAuth(100),
        },
        {
          path: "/detectionZone",
          name: "DetectionZone",
          component: DetectionZone,
          beforeEnter: requireAuth(100),
        },
        {
          path: "/countingZone",
          name: "CountingZone",
          component: CountingZone,
          beforeEnter: requireAuth(100),
        },
        {
          path: "/pedestrianZone",
          name: "PedestrianZone",
          component: PedestrianZone,
          beforeEnter: requireAuth(100),
        },
        {
          path: "/trainingFile",
          name: "TrainingFile",
          component: TrainingFile,
          beforeEnter: requireAuth(100),
        },
        {
          path: "/optionFile",
          name: "OptionFile",
          component: OptionFile,
          beforeEnter: requireAuth(100),
        },
        {
          path: "/camera",
          name: "Camera",
          component: Camera,
          beforeEnter: requireAuth(100),
        },
        {
          path: "/controller",
          name: "Controller",
          component: Controller,
          beforeEnter: requireAuth(100),
        },
        {
          path: "/intersectionInfo",
          name: "IntersectionInfo",
          component: IntersectionInfo,
          beforeEnter: requireAuth(100),
        },
        {
          path: "/etcInfo",
          name: "EtcInfo",
          component: EtcInfo,
          beforeEnter: requireAuth(10),
        },
        {
          path: "/users",
          name: "Users",
          component: Users,
          beforeEnter: requireAuth(100),
        },
        // {
        //   path: '/addUsers',
        //   name: 'AddUsers',
        //   component: AddUsers
        // },
        // {
        // 	path: '/setting',
        // 	name: 'Setting',
        // 	component: Setting,
        // 	beforeEnter: requireAuth(10),
        // },
        {
          path: "/systemInfo",
          name: "SystemInfo",
          component: SystemInfo,
          beforeEnter: requireAuth(10),
        },
        {
          path: "/systemEvent",
          name: "SystemEvent",
          component: SystemEvent,
          beforeEnter: requireAuth(10),
        },
        {
          path: "/eventInfo",
          name: "EventInfo",
          component: EventInfo,
          beforeEnter: requireAuth(1),
        },
        {
          path: "/meteringUser",
          name: "MeteringUser",
          component: MeteringUserView,
          beforeEnter: requireAuth(100),
        },
        {
          path: "/signReport",
          name: "SignReport",
          component: SignReport,
          beforeEnter: requireAuth(100),
        },
        {
          path: "/dateReport",
          name: "DateReport",
          component: ReportPage,
          beforeEnter: requireAuth(100),
        },
      ],
    },
    {
      path: "/policy",
      name: "Policy",
      component: Policy
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/signUp",
      name: "SignUp",
      component: SignUp,
    },
    {
      path: "*",
      component: ErrorComponent,
    },
    {
      path: "/error",
      name: "Error",
      component: ErrorComponent,
    },

    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // },
  ];
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(() => {
  const getHost = store.getters["globalStore/getHostName"];
  const title = titleChangeHandler(getHost);
  Vue.nextTick(() => {
    document.title = title;
  });
});

export default router;
