import { _axios } from "../../plugins/axios";
import axios from "axios";

const hostname = window.location.hostname.split(".")[0];

const getTrafficGisAPI = () => {
  try {
    const trafficGIS = _axios.post("/api/traffic/intersection/trafficByGis", {
      Region: hostname,
    });
    return trafficGIS;
  } catch (err) {
    console.log("getTrafficGis err");
  }
};

const getIntersectionListAPI = (intersection) => {
  try {
    const intersectionList = _axios.post("/api/traffic/intersection/list", {
      intersection,
      Region: hostname,
    });

    return intersectionList;
  } catch (err) {
    console.log("getIntersectionList err");
  }
};

const getDashBoardInfoAPI = (startDate, endDate, tokenRefresh) => {
  try {
    const getDashInfo = _axios.post("/api/traffic/dashboard/mainInfo", {
      startDate,
      endDate,
      tokenRefresh,
      Region: hostname,
    });
    return getDashInfo;
  } catch (err) {
    console.log("getDashBoardInfoAPI err");
  }
};

const getMainChartAPI = (startDate, endDate) => {
  try {
    const trafficAvenue = _axios.post("/api/traffic/analysis/trafficByAvenue", {
      startDate,
      endDate,
      tokenRefresh: false,
      Region: hostname,
    });
    const trafficCarmodel = _axios.post(
      "/api/traffic/analysis/trafficByCarModel",
      {
        startDate,
        endDate,
        tokenRefresh: false,
        Region: hostname,
      }
    );
    const trafficDelay = _axios.post("/api/los/analysis/trafficByDelay", {
      startDate,
      endDate,
      tokenRefresh: false,
      Region: hostname,
    });

    const chartData = axios
      .all([trafficAvenue, trafficCarmodel, trafficDelay])
      .then(
        axios.spread((avenueData, carModelData, delayData) => {
          return {
            avenueData: avenueData.data.data,
            carModelData: carModelData.data.data,
            delayData: delayData.data.data,
          };
        })
      );
    return chartData;
  } catch (err) {
    console.log("getMainChartData err");
  }
};

const getUserListAPI = (word, startPage, scale) => {
  try {
    const userList = _axios.post("/api/traffic/user/list", {
      word,
      startPage,
      scale,
      Region: hostname,
    });
    return userList;
  } catch (err) {
    console.log("getUserListAPI err");
  }
};

const getUserLogAPI = (body) => {
  try {
    const userLog = _axios.post("/api/traffic/user/userLog", {
      ...body,
      Region: hostname,
    });
    return userLog;
  } catch (err) {
    console.log("getUserLogAPI err");
  }
};

const downloadExcelAPI = (url, excelData) => {
  try {
    const download = _axios.post(`/api/traffic/excelDownload/${url}`, {
      excelData,
      Region: hostname,
    });
    return download;
  } catch (err) {
    console.log("downloadExcelAPI err");
  }
};

const getAvenueInfoAPI = () => {
  try {
    const avanueInfo = _axios.post("/api/traffic/avenue/getAvenueInfo", {
      Region: hostname,
    });
    return avanueInfo;
  } catch (err) {
    console.log("getAvenueInfoAPI err");
  }
};

const checkDupleAPI = (userID) => {
  try {
    const check = _axios.post("/api/traffic/user/dupleCheck", {
      userID,
      Region: hostname,
    });
    return check;
  } catch (err) {
    console.log("checkDupleAPI err");
  }
};

const checkDupleEmailAPI = (email) => {
  try {
    const check = _axios.post("/api/traffic/user/emailDupleCheck", {
      email,
      Region: hostname,
    });
    return check;
  } catch (err) {
    console.log("checkEmailDupleAPI err");
  }
};

const userUpdateAPI = (body) => {
  try {
    const updateUser = _axios.post("/api/traffic/user/update", {
      userID: body.userID,
      userName: body.userName,
      newPassword: body.newPassword,
      oldPassword: body.oldPassword,
      userAuth: body.userAuth,
      isUsed: body.isUsed,
      email: body.email,
      Region: hostname,
    });
    return updateUser;
  } catch (err) {
    console.log("userUpdateAPI err");
  }
};

const signUpAPI = (body) => {
  try {
    const signup = _axios.post("/api/traffic/user/signUp", {
      userID: body.userID,
      userName: body.userName,
      newPassword: body.newPassword,
      userAuth: body.userAuth,
      isUsed: body.isUsed,
      email: body.email,
      Region: hostname,
    });
    return signup;
  } catch (err) {
    console.log("signUpAPI err");
  }
};

const insertAvenueAPI = (body, detail, isBasic) => {
  try {
    if (isBasic) {
      const insertAvenue = _axios.post("/api/traffic/list/insertAvenue", {
        avenue: {
          ID: body.ID,
          name: body.name,
          direction: body.direction,
          nodeID: body.nodeID,
          intersectionSeq: body.intersectionSeq,
        },
        detailAvenue: detail,
        Region: hostname,
      });
      return insertAvenue;
    } else {
      const insertAvenueDetail = _axios.post("/api/traffic/list/insertAvenue", {
        detailAvenue: detail,
        AvenueSeq: body,
        Region: hostname,
      });
      return insertAvenueDetail;
    }
  } catch (err) {
    console.log("insertAvenueAPI err");
  }
};

const updateAvenueAPI = (body, isBasic) => {
  try {
    if (isBasic) {
      const updateAvenue = _axios.post("/api/traffic/list/updateAvenue", {
        avenue: {
          ID: body.ID,
          label: body.label,
          name: body.name,
          direction: body.direction,
          nodeID: body.nodeID,
          Region: hostname,
        },
      });
      return updateAvenue;
    } else {
      const updateAvenueDetail = _axios.post("/api/traffic/list/updateAvenue", {
        detailAvenue: body.detailAvenue,
        AvenueSeq: body.AvenueSeq,
        Region: hostname,
      });
      return updateAvenueDetail;
    }
  } catch (err) {
    console.log("updateAvenueAPI err");
  }
};

const getIntersectionInfoAPI = () => {
  try {
    const getIntersection = _axios.post(
      "/api/traffic/intersection/getIntersectionInfo",
      { Region: hostname }
    );
    return getIntersection;
  } catch (err) {
    console.log("getIntersectionInfoAPI err");
  }
};

const getCurrentIntersectionAPI = (body) => {
  try {
    const getCurrent = _axios.post(
      "/api/traffic/intersection/getCurrentIntersection",
      {
        intersectionSeq: body.intersectionSeq,
        targetDate: body.targetDate,
        Region: hostname,
      }
    );
    return getCurrent;
  } catch (err) {
    console.log("getCurrentIntersectionAPI err");
  }
};

const insertIntersectionAPI = (body) => {
  try {
    const insertIntersection = _axios.post(
      "/api/traffic/list/insertIntersection",
      {
        intersection: body.intersection,
        Region: hostname,
      }
    );
    return insertIntersection;
  } catch (err) {
    console.log("insertIntersectionAPI err");
  }
};

const updateIntersectionAPI = (body) => {
  try {
    const updateIntersection = _axios.post(
      "/api/traffic/list/updateIntersection",
      {
        intersection: body.intersection,
        Region: hostname,
      }
    );
    return updateIntersection;
  } catch (err) {
    console.log("updateIntersectionAPI err");
  }
};

const deleteIntersectionAPI = (body) => {
  try {
    const deleteIntersection = _axios.post(
      "/api/traffic/list/deleteIntersection",
      {
        IntersectionSeq: body.IntersectionSeq,
        Region: hostname,
      }
    );
    return deleteIntersection;
  } catch (err) {
    console.log("deleteIntersectionAPI err");
  }
};

const deleteAvenueAPI = (body) => {
  try {
    const deleteAvenue = _axios.post("/api/traffic/list/deleteAvenue", {
      AvenueSeq: body.AvenueSeq,
      Region: hostname,
    });
    return deleteAvenue;
  } catch (err) {
    console.log("deleteAvenueAPI err");
  }
};

const getEventLogAPI = (body) => {
  try {
    const eventLog = _axios.post("/api/traffic/event/getEventLog", {
      intersectionSeq: body.intersectionSeq,
      category: body.category,
      startDate: body.startDate,
      endDate: body.endDate,
      startPage: body.startPage,
      scale: body.scale,
      Region: hostname,
    });
    return eventLog;
  } catch (err) {
    console.log("getEventLogAPI err");
  }
};

const getInterInfoAPI = () => {
  try {
    const interInfo = _axios.post("/api/traffic/list/getInterInfo", {
      Region: hostname,
    });
    return interInfo;
  } catch (err) {
    console.log("getInterInfoAPI err");
  }
};

const getAveInfoAPI = () => {
  try {
    const aveInfo = _axios.post("/api/traffic/list/getAveInfo", {
      Region: hostname,
    });
    return aveInfo;
  } catch (err) {
    console.log("getAveInfoAPI err");
  }
};

const mailAuthAPI = async (body) => {
  try {
    const mailAuth = _axios.post("/api/traffic/user/loginMailAuth", {
      user: { ...body.user },
      Region: hostname,
    });
    
    return mailAuth;
  } catch (err) {
    console.log("mailAuthAPI err");
  }
};

const getCurrentDbUsage = () => {
  try {
    const getUsage = _axios.post("/api/traffic/meter/db/now", {
      Region: hostname,
    });

    return getUsage;
  } catch (err) {
    console.log("getCurrentDbUsage err");
  }
};

const getCurrentDbPercentAPI = () => {
  try {
    const getPercent = _axios.post("/api/traffic/meter/dbpercentage/now", {
      Region: hostname,
    });

    return getPercent;
  } catch (err) {
    console.log("getCurrentDbPercentAPI err");
  }
};

const getAdminCurrentTableAPI = (date) => {
  try {
    const getAdminData = _axios.post("/api/traffic/meter/db/now/admin", {
      CollectedDate: date,
      Region: hostname,
    });

    return getAdminData;
  } catch (err) {
    console.log("getAdminCurrentDbAPI err");
  }
};

const getAdminPriceDataAPI = (date) => {
  try {
    const priceData = _axios.post("/api/traffic/meter/price/admin", {
      CollectedDate: date,
      Region: hostname,
    });

    return priceData;
  } catch (err) {
    console.log("getAdminPriceDataAPI err");
  }
};

const currentSystemEventAPI = () => {
  try {
    const systemEvent = _axios.post(`/api/traffic/event/getCurrent`, {
      Region: hostname,
    });
    return systemEvent;
  } catch (err) {
    console.log("currentSystemEventAPI err");
  }
};

const getSystemEventLogAPI = (body) => {
  try {
    const eventLog = _axios.post(`/api/traffic/event/getSystemEventLog`, {
      ...body,
      Region: hostname,
    });

    return eventLog;
  } catch (err) {
    console.log("getSystemEventLogAPI err");
  }
};

const passwordUpdateAPI = (body) => {
  try {
    const updatePassword = _axios.post("/api/traffic/user/updatePassword", {
      ...body,
      Region: hostname,
    });

    return updatePassword;
  } catch (err) {
    console.log("passwordUpdateAPI err");
  }
};

const nextUpdateQuaterAPI = (body) => {
  try {
    const nextUpdate = _axios.post("/api/traffic/user/nextUpdate", {
      ...body,
      Region: hostname,
    });
    return nextUpdate;
  } catch (err) {
    console.log("nextUpdateQuaterAPI err");
  }
};

const findUserPasswordAPI = (body) => {
  try {
    const findPassword = _axios.post("/api/traffic/user/findPW", {
      ...body,
      Region: hostname,
    });
    return findPassword;
  } catch (err) {
    console.log("findUserPasswordAPI err");
  }
};

const findUserIdAPI = (body) => {
  try {
    const findId = _axios.post("/api/traffic/user/findID", {
      ...body,
      Region: hostname,
    });
    return findId;
  } catch (err) {
    console.log("findUserIdAPI err");
  }
};

const getCameraInfoAPI = (body) => {
  try {
    const cameraInfo = _axios.post("/api/traffic/camera/info", {
      ...body,
      Region: hostname,
    });
    return cameraInfo;
  } catch (err) {
    console.log("getCameraInfoAPI err :", err);
  }
};

const saveLogAPI = (body) => {
  try {
    const saveLog = _axios.post("/api/traffic/user/saveErrorLog", {
      ...body,
      Region: hostname,
    });
    return saveLog;
  } catch (err) {
    console.log("saveLogAPI err :", err);
  }
};

export {
  getTrafficGisAPI,
  getIntersectionListAPI,
  getMainChartAPI,
  getDashBoardInfoAPI,
  getUserListAPI,
  downloadExcelAPI,
  getAvenueInfoAPI,
  checkDupleAPI,
  userUpdateAPI,
  signUpAPI,
  insertAvenueAPI,
  updateAvenueAPI,
  getIntersectionInfoAPI,
  getCurrentIntersectionAPI,
  insertIntersectionAPI,
  updateIntersectionAPI,
  deleteIntersectionAPI,
  deleteAvenueAPI,
  getEventLogAPI,
  getInterInfoAPI,
  getAveInfoAPI,
  mailAuthAPI,
  getUserLogAPI,
  getCurrentDbUsage,
  getCurrentDbPercentAPI,
  getAdminCurrentTableAPI,
  getAdminPriceDataAPI,
  currentSystemEventAPI,
  getSystemEventLogAPI,
  passwordUpdateAPI,
  nextUpdateQuaterAPI,
  findUserPasswordAPI,
  findUserIdAPI,
  getCameraInfoAPI,
  checkDupleEmailAPI,
  saveLogAPI,
};
