import Vue from "vue";
import router from "./router";
import App from "./App.vue";
import store from "./store";
import axios from "axios";
import vuetify from "./plugins/vuetify";

import "@/assets/css/style.css";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.prototype.$http = axios;
Vue.config.productionTip = false;

Vue.filter("comma", (val) => {
  return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
