import { _axios } from "../../plugins/axios";
const hostname = window.location.hostname.split(".")[0];
const getAvenueListAPI = (avenueSeq) => {
  try {
    const avenueList = _axios.post("/api/los/avenue/list", {
      avenueSeq,
      Region: hostname,
    });

    return avenueList;
  } catch (err) {
    console.log("getAvenueListAPI err");
  }
};

const getLosDataAPI = (intersectionSeq, targetDate, type) => {
  try {
    const getData = _axios.post("/api/los/levelOfService/getLOSData", {
      intersectionSeq,
      targetDate,
      type,
      Region: hostname,
    });

    return getData;
  } catch (err) {
    console.log("getLosDataAPI err");
  }
};

const getTrafficDelayByLos = (startDate, endDate) => {
  try {
    const getLosData = _axios.post("/api/los/analysis/trafficByDelay", {
      startDate,
      endDate,
      Region: hostname,
    });
    return getLosData;
  } catch (err) {
    console.log("getTrafficDelayByLos err");
  }
};

const getCurrentIntersectionByLosAPI = (body) => {
  try {
    const getCurrent = _axios.post(
      "/api/los/intersection/getCurrentIntersection",
      {
        intersectionSeq: body.intersectionSeq,
        targetDate: body.targetDate,
        Region: hostname,
      }
    );
    return getCurrent;
  } catch (err) {
    console.log("getCurrentIntersectionByLosAPI err");
  }
};

export {
  getAvenueListAPI,
  getLosDataAPI,
  getTrafficDelayByLos,
  getCurrentIntersectionByLosAPI,
};
