var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admin"},[_c('p',[_vm._v("장비 상태정보")]),_c('div',{staticClass:"globalPanel"},[_c('div',{staticClass:"gridPanel current"},[_c('table',{staticClass:"tbl_base type1 hand",attrs:{"summary":"장비현황 리스트"}},[_c('caption',{staticClass:"hidden"},[_vm._v(" 장비현황 리스트 ")]),_vm._m(0),_vm._m(1),_c('tbody',_vm._l((_vm.equipmentList),function(item,idx){return _c('tr',{key:idx,class:item.IntersectionID,on:{"mouseover":() => _vm.mouseOverHandler(item.IntersectionID),"mouseleave":() => _vm.mouseLeaveHandler(item.IntersectionID)}},[(item.Floor === 1)?_c('td',{attrs:{"rowspan":item.AvenueCount}},[_vm._v(" "+_vm._s(item.IntersectionSeq)+" ")]):_vm._e(),(item.Floor === 1)?_c('td',{attrs:{"rowspan":item.AvenueCount}},[_vm._v(" "+_vm._s(item.IntersectionName)+" ")]):_vm._e(),(item.Floor === 1)?_c('td',{attrs:{"rowspan":item.AvenueCount}},[_vm._v(" "+_vm._s(item.IntersectionID)+" ")]):_vm._e(),(item.Floor === 1)?_c('td',{attrs:{"rowspan":item.AvenueCount}},[_vm._v("-")]):_vm._e(),(item.Floor === 1)?_c('td',{attrs:{"rowspan":item.AvenueCount}},[_vm._v(" "+_vm._s(item.IntersectionSeq)+" ")]):_vm._e(),(item.Floor === 1)?_c('td',{class:{
								conn: item.IsControllerConn === 1,
								disConn: item.IsControllerConn === 0,
							},attrs:{"rowspan":item.AvenueCount}},[_vm._v(" "+_vm._s(item.ControllerIP ?? '-')+" ")]):_vm._e(),(item.Floor === 1)?_c('td',{class:{
								conn: item.IsOpticalConn === 1,
								disConn: item.IsOpticalConn === 0,
							},attrs:{"rowspan":item.AvenueCount}},[_vm._v(" "+_vm._s(item.OpticalIP ?? '-')+" ")]):_vm._e(),(item.Floor === 1)?_c('td',{class:{
								conn: item.IsObserveConn === 1,
								disConn: item.IsObserveConn === 0,
							},attrs:{"rowspan":item.AvenueCount}},[_vm._v(" "+_vm._s(item.ObserveIP ?? '-')+" ")]):_vm._e(),_c('td',[_vm._v(_vm._s(item.AvenueSeq))]),_c('td',[_vm._v(_vm._s(item.AvenueName))]),_c('td',[_vm._v(_vm._s(_vm.category[item.StaticDirection]))]),_c('td',{class:{
								conn: item.IsCameraConn === 1,
								disConn: item.IsCameraConn === 0,
							}},[_vm._v(" "+_vm._s(item.CameraIP ?? '-')+" ")])])}),0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{attrs:{"width":"4%"}}),_c('col',{attrs:{"width":"12%"}}),_c('col',{attrs:{"width":"8%"}}),_c('col',{attrs:{"width":"8%"}}),_c('col',{attrs:{"width":"8%"}}),_c('col',{attrs:{"width":"8%"}}),_c('col',{attrs:{"width":"8%"}}),_c('col',{attrs:{"width":"8%"}}),_c('col',{attrs:{"width":"6%"}}),_c('col',{attrs:{"width":"12%"}}),_c('col',{attrs:{"width":"8%"}}),_c('col',{attrs:{"width":"10%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("번호")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("교차로명")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("교차로ID")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("센터 IP")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("제어기ID")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("제어기IP")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("광정송장비IP")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("상태감시장치IP")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("접근로 번호")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("카메라 검지 방향")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("접근로 진출 방향")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("카메라IP")])])])
}]

export { render, staticRenderFns }