<template>
	<div class="admin">
		<p>운영자 관리</p>
		<div class="globalPanel">
			<div class="searchPanel">
				<div class="fLeft">
					<span>운영자</span>
					<p>
						<input
							class="webfont inp_txt2"
							type="text"
							value=""
							autofocus=""
							v-model="word"
							@keyup.enter="getUserList()"
						/>
					</p>
					<a class="btnStyle01 wg searchBtn" @click="getUserList()">조회</a>
				</div>
				<div class="rLeft">
					<a class="btnStyle02 wg newBtn" @click="userModify('test', 'create')"
						>신규</a
					>
				</div>
			</div>
			<div class="gridPanel user">
				<table
					v-if="getHostName !== 'localhost' && getHostName !== 'tmaas'"
					class="tbl_base type1 hand"
					summary="유저 리스트"
				>
					<caption class="hidden">
						유저 리스트
					</caption>
					<colgroup>
						<col width="25%" />
						<col width="25%" />
						<col width="25%" />
						<col width="25%" />
					</colgroup>
					<thead>
						<tr>
							<th scope="col">운영자 ID</th>
							<th scope="col">운영자명</th>
							<th scope="col">권한</th>
							<th scope="col">사용유무</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(item, idx) in userList"
							:key="idx"
							@dblclick="() => userModify(item.UserID, 'modify')"
						>
							<td>{{ item.UserID }}</td>
							<td>{{ item.UserName }}</td>
							<td>{{ authItemName[item.UserAuth] }}</td>
							<td class="center">
								{{ item.IsUsed !== undefined ? (item.IsUsed ? 'Y' : 'N') : '' }}
							</td>
						</tr>
					</tbody>
				</table>
				<table v-else class="tbl_base type1 hand" summary="유저 리스트">
					<caption class="hidden">
						유저 리스트
					</caption>
					<colgroup>
						<col width="10%" />
						<col width="20%" />
						<col width="20%" />
						<col width="20%" />
						<col width="20%" />
					</colgroup>
					<thead>
						<tr>
							<th scope="col">지역</th>
							<th scope="col">운영자 ID</th>
							<th scope="col">운영자명</th>
							<th scope="col">권한</th>
							<th scope="col">사용유무</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(item, idx) in userList"
							:key="idx"
							@dblclick="
								() =>
									item.Region === getHostName
										? userModify(item.UserID, 'modify')
										: null
							"
						>
							<td>{{ changeRegionTitle(item.Region) }}</td>
							<td>{{ item.UserID }}</td>
							<td>{{ item.UserName }}</td>
							<td>{{ authItemName[item.UserAuth] }}</td>
							<td class="center">
								{{ item.IsUsed !== undefined ? (item.IsUsed ? 'Y' : 'N') : '' }}
							</td>
						</tr>
					</tbody>
				</table>
				<div class="paging">
					<div>
						<a class="Prev pagingBtn" @click="currentPage = 1">Prev</a>
						<a
							v-for="(item, idx) in pageList"
							:key="idx"
							@click="currentPage = item.pageNum"
							:class="{ on: item.active }"
							class="num"
							>{{ item.pageNum }}</a
						>
						<a class="next pagingBtn" @click="currentPage = page.totalPage"
							>Next</a
						>
					</div>
				</div>

				<div class="fLeft step2">
					<span>접속 로그</span>
				</div>
				<div class="logPanel">
					<table class="tbl_base type1 hand" summary="">
						<caption class="hidden">
							로그
						</caption>
						<colgroup>
							<col width="25%" />
							<col width="25%" />
							<col width="50%" />
						</colgroup>
						<thead>
							<tr>
								<th scope="col">운영자 ID</th>
								<th scope="col">등록시간</th>
								<th scope="col">내용</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, idx) in userLog" :key="idx">
								<td>{{ item.UserID }}</td>
								<td>{{ item.RegisteredDate }}</td>
								<td>{{ item.Description }}</td>
							</tr>
						</tbody>
					</table>
					<div class="paging">
						<div>
							<a class="Prev pagingBtn" @click="logCurrentPage = 1">Prev</a>
							<a
								v-for="(item, idx) in logPageList"
								:key="idx"
								@click="logCurrentPage = item.pageNum"
								:class="{ on: item.active }"
								class="num"
								>{{ item.pageNum }}</a
							>
							<a class="next pagingBtn" @click="logCurrentPage = page.totalPage"
								>Next</a
							>
						</div>
					</div>
				</div>
			</div>
			<userInfo
				:user="user"
				:config="config"
				@init-user="userCloseHandler"
				@refetch="this.refetchUserList"
			></userInfo>
		</div>
	</div>
</template>

<script>
import userInfo from '@/components/Dialog/UserInfo.vue'
import { mapGetters } from 'vuex'
import { getUserLogAPI } from '../../api/trafficAPI/index'
import { userInfoMixins } from '../../mixins/userInfoMixins'

const globalStore = 'globalStore'
export default {
	mixins: [userInfoMixins],
	data() {
		return {
			refetch: false,
			logCurrentPage: 1,
			page: {
				totalRow: 0,
				pageBlock: 8,
				scale: 8,
				startPage: 1,
				totalPage: 1,
			},
			logPageList: [],
			userLog: [],
		}
	},
	created() {},
	mounted() {
		this.getUserList()
		this.getUserLog()
	},
	components: {
		userInfo,
	},
	methods: {
		printFunc(value) {
			console.log(value)
		},
		resetUser() {
			this.user = {
				userID: null,
				userName: null,
				password: null,
				password2: null,
				userAuth: 1,
				isUsed: null,
				email: null,
			}
		},
		refetchUserList() {
			this.getUserList()
		},
		changeRegionTitle(name) {
			let regionTitle = ''
			switch (name) {
				case 'seongnam':
					regionTitle = '성남시'
					break
				case 'anyang':
					regionTitle = '안양시'
					break
				case 'tmaas':
					regionTitle = 'Laonroad'
					break
				default:
					regionTitle = ''
					break
			}
			return regionTitle
		},
		getUserLog(userID) {
			const apiParams = {
				userID: userID || '',
				page: this.logCurrentPage,
			}
			getUserLogAPI(apiParams)
				.then(res => {
					if (res.data.success) {
						let data = res.data.data
						this.userLog.splice(0)
						this.userLog.push(...data)
						this.logPageList.splice(0)
						for (let i = 1; i <= 5; i++) {
							this.logPageList.push({
								pageNum: i,
								active: i === this.logCurrentPage ? true : false,
							})
						}
					}
				})
				.catch(function (error) {
					alert('error :', error)
				})
		},

		createUser() {
			this.config.dialog.user = true
			this.config.mode.modify = false
		},
	},
	computed: {
		...mapGetters(globalStore, ['getHostName']),
	},
	watch: {
		currentPage: function () {
			this.getUserList()
		},
		logCurrentPage: function () {
			this.getUserLog()
		},
	},
}
</script>
