var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tree"},[_c('p',{staticClass:"treeTitle"},[_vm._v(_vm._s(_vm.title))]),_c('ul',_vm._l((_vm.analysisList),function(item,idx){return _c('li',{key:idx,class:{on : item.classIf, 
    type01 : item.type===1, 
    type02 : item.type===2, 
    type03 : item.type===3, 
    type04 : item.type===4, 
    type05 : item.type===5, 
    type06 : item.type===6, 
    type07 : item.type===7},on:{"click":function($event){return _vm.clickItem(item, idx)}}},[_vm._v(_vm._s(item.analysisName))])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }