<template>
  <div class="admin">
    <p>시스템 이벤트</p>
    <div class="globalPanel">
      <div class="searchPanel">
        <div class="fLeft">
          <span>교차로 명칭</span>
          <p>
            <v-select
              v-model="intersectionSeq"
              :items="intersectionItem"
              dense
              :menu-props="{ bottom: true, offsetY: true }"
              color="#1976d2"
              dark
            ></v-select>
          </p>
          <span>이벤트 종류</span>
          <p>
            <v-select
              v-model="category"
              :items="categoryItem"
              dense
              :menu-props="{ bottom: true, offsetY: true }"
              color="#1976d2"
              dark
            ></v-select>
          </p>
          <span>조회 기간</span>
          <p>
            <v-menu
              v-model="config.datePicker.start"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="#1976d2"
                  dark
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                @input="config.datePicker.start = false"
              ></v-date-picker>
            </v-menu>
          </p>
          <p>
            <v-menu
              ref="menu"
              v-model="config.timePicker.start"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startTime"
                  prepend-icon="mdi-calendar-clock"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="#1976d2"
                  dark
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="startTime"
                @click:minute="$refs.menu.save(startTime)"
                ampm-in-title
                scrollable
              ></v-time-picker>
            </v-menu>
          </p>
          <p>~</p>
          <p>
            <v-menu
              v-model="config.datePicker.end"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="#1976d2"
                  dark
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                @input="config.datePicker.end = false"
              ></v-date-picker>
            </v-menu>
          </p>
          <p>
            <v-menu
              ref="menu2"
              v-model="config.timePicker.end"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endTime"
                  prepend-icon="mdi-calendar-clock"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="#1976d2"
                  dark
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="endTime"
                @click:minute="$refs.menu2.save(endTime)"
                ampm-in-title
                scrollable
              ></v-time-picker>
            </v-menu>
          </p>
          <a
            class="btnStyle01 wg searchBtn"
            @click="currentPage === 1 ? getEventLog() : (currentPage = 1)"
            >조회</a
          >
          <!-- <a class="btnStyle02 wg downBtn" >다운로드</a> -->
        </div>
        <div class="rLeft"></div>
      </div>

      <div class="gridPanel">
        <table class="tbl_base type1 hand" summary="유저 리스트">
          <caption class="hidden">
            이벤트 리스트
          </caption>
          <colgroup>
            <col width="6%" />
            <col width="12%" />
            <col width="6%" />
            <col width="12%" />
            <col width="12%" />
            <col width="12%" />
            <col width="40%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">교차로 번호</th>
              <th scope="col">교차로명</th>
              <th scope="col">접근로 번호</th>
              <th scope="col">접근로명</th>
              <th scope="col">이벤트종류</th>
              <th scope="col">발생시간</th>
              <th scope="col">내용</th>
            </tr>
          </thead>
          <tbody>
            <tr v-show="eventList.length === 0">
              <td colspan="7">조회 기간내 데이터가 없습니다.</td>
            </tr>
            <tr v-for="(item, idx) in eventList" :key="idx">
              <td>{{ item.IntersectionSeq }}</td>
              <td>{{ item.IntersectionName }}</td>
              <td>{{ item.AvenueSeq }}</td>
              <td>{{ item.AvenueName }}</td>
              <td>{{ categoryType[item.Category] }}</td>
              <td>{{ item.RegisteredDate }}</td>
              <td
                :class="{
                  conn: item.Description.indexOf('확인') !== -1,
                  disConn: item.Description.indexOf('없음') !== -1,
                }"
              >
                {{ item.Description }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="paging">
        <div>
          <a class="next pagingBtn" @click="currentPage = 1">&lt;&lt;</a>
          <a
            class="Prev pagingBtn"
            @click="changePageList(pageList[0].pageNum - page.pageBlock)"
            >&lt;</a
          >
          <a
            v-for="(item, idx) in pageList"
            :key="idx"
            @click="currentPage = item.pageNum"
            :class="{ on: item.active }"
            class="num"
            >{{ item.pageNum }}</a
          >
          <!-- <a class="num on">1</a> 
          <a class="num" >2</a> 
          <a class="num" >3</a> 
          <a class="num" >4</a> 
          <a class="num" >5</a>  -->
          <a
            class="next pagingBtn"
            @click="changePageList(pageList[0].pageNum + page.pageBlock)"
            >&gt;</a
          >
          <a class="next pagingBtn" @click="currentPage = page.totalPage"
            >&gt;&gt;</a
          >
        </div>
      </div>
    </div>
    <v-dialog
      v-model="config.dialog.image"
      width="800"
      height="600"
      class="eventDialog"
      :opacity="1"
    >
      <img
        :src="this.fileName"
        style="width: 800px; height: 600px; opacity: 1"
      />
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  getIntersectionListAPI,
  getSystemEventLogAPI,
} from "../../api/trafficAPI/index";

const globalStore = "globalStore";
export default {
  data() {
    return {
      startDate: "2023-11-16",
      endDate: "2023-11-16",
      startTime: "00:00",
      endTime: "23:59",
      config: {
        dialog: {
          image: false,
        },
        datePicker: {
          start: false,
          end: false,
        },
        timePicker: {
          start: false,
          end: false,
        },
      },
      eventList: [],

      currentPage: 1,
      page: {
        totalRow: 0,
        pageBlock: 10,
        scale: 100,
        startPage: 1,
        totalPage: 1,
      },
      pageList: [{ pageNum: 1, active: true }],

      category: "",
      categoryItem: [
        { text: "전체", value: "" },
        { text: "제어기", value: 1 },
        { text: "광전송 장비", value: 2 },
        { text: "상태감시장치", value: 3 },
        { text: "카메라", value: 4 },
        { text: "LOS", value: 5 },
      ],
      intersectionItem: [{ text: "전체", value: "" }],
      intersectionSeq: "",
      categoryType: {
        1: "제어기",
        2: "광전송 장비",
        3: "상태감시장치",
        4: "카메라",
        5: "LOS",
      },
      fileName: "/img/laonpeople.png",
    };
  },
  created() {
    const apiParams = {
      intersection: "",
    };
    getIntersectionListAPI(apiParams)
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          data[0].forEach((el) => {
            this.intersectionItem.push({
              text: `${el.IntersectionID}. ${el.IntersectionName}`,
              value: el.IntersectionSeq,
            });
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getEventLog();
    },
    getEventLog() {
      const apiParams = {
        intersectionSeq: this.intersectionSeq,
        category: this.category,
        startDate: `${this.startDate} ${this.startTime}`,
        endDate: `${this.endDate} ${this.endTime}:59`,
        startPage: (this.currentPage - 1) * this.page.scale,
        scale: this.page.scale,
      };
      getSystemEventLogAPI(apiParams)
        .then((res) => {
          if (res.data.success) {
            let data = res.data.data;
            this.eventList = data[0];

            this.page.totalRow = data[1][0].TotalRow;
            let temp = (this.currentPage - 1) % this.page.pageBlock;
            this.page.startPage = this.currentPage - temp;
            this.page.totalPage = Math.ceil(
              this.page.totalRow / this.page.scale
            );

            this.pageList.splice(0);
            for (
              let i = this.page.startPage;
              i < this.page.startPage + this.page.pageBlock;
              i++
            ) {
              this.pageList.push({
                pageNum: i,
                active: i === this.currentPage ? true : false,
              });
              if (i >= this.page.totalPage) break;
            }
          }
        })
        .catch(function (error) {
          alert("error :", error);
        });
    },
    changePageList(startPage) {
      if (startPage < 1 || startPage > this.page.totalPage) return;

      this.pageList.splice(0);
      for (
        let i = this.page.startPage;
        i < this.page.startPage + this.page.pageBlock;
        i++
      ) {
        this.pageList.push({
          pageNum: i,
          active: i === this.currentPage ? true : false,
        });
        if (i >= this.page.totalPage) break;
      }
      this.currentPage = startPage;
    },
    showPhoto(fileName, url) {
      this.makeFileName(fileName, url);
      this.config.dialog.image = true;
    },
    downPhoto(fileName, url) {
      let downEl = document.createElement("a");
      downEl.href = this.makeFileName(fileName, url);
      downEl.download = fileName;
      downEl.click();
    },
    makeFileName(fileName, url) {
      let temp = url.split(" ")[0].split("-");
      this.fileName = `/storage/event/${temp
        .join()
        .replaceAll(",", "/")}/${fileName}`;

      return this.fileName;
    },
  },
  computed: {
    ...mapGetters(globalStore, ["getHostName"]),
  },
  watch: {
    currentPage: function () {
      this.getEventLog();
    },
  },
};
</script>
