import { _axios } from "../../plugins/axios";
const hostname = window.location.hostname.split(".")[0];
const getLosDataBySignalAPI = (intersectionSeq, targetDate, type) => {
  try {
    const getLosData = _axios.post("/api/signal/levelOfService/getLOSData", {
      intersectionSeq,
      targetDate,
      type,
      Region: hostname,
    });
    return getLosData;
  } catch (err) {
    console.log("getLosDataBySignalAPI err");
  }
};

export { getLosDataBySignalAPI };
