<template>
  <div class="chart" style="height: 100%; width: 100%"></div>
</template>
<script>
import * as echarts from "echarts";

require("echarts/theme/macarons");

export default {
  name: "signReportChart",
  components: {},
  props: {
    chartData: {
      type: Object,
      default: {
        chartType: "barChart",
        data: [],
      },
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  setup() {},
  created() {},
  mounted() {
    if (this.chart) {
      this.renderChart(this.chartData);
    }
  },
  unmounted() {},
  methods: {
    init(e) {},
    renderChart(option) {
      if (this.chart !== null) {
        this.chart.clear();
      }
      this.chart = echarts.init(this.$el, "macarons");
      let addOption;
      switch (option.name) {
        case "speedChart":
        case "losChart":
          addOption = {
            title: {
              text: option.title,
              left: "center",
            },
            legend: {
              orient: "vertical",
            },
            xAxis: {
              type: "category",
              data: [""],
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                type: "bar",
                data: [option.chartData[0]],
              },
              {
                type: "bar",
                data: [option.chartData[1]],
              },
            ],
          };
          break;
        case "trafficChart":
        case "costChart":
          addOption = {
            title: {
              text: option.title,
              left: "center",
            },
            legend: {
              orient: "vertical",
            },
            xAxis: {
              type: "category",
              data: option.axisData,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                type: "line",
                data: option.chartData[0],
              },
              {
                type: "line",
                data: option.chartData[1],
              },
            ],
          };
          break;
        case "pie":
          addOption = {
            title: {
              text: option.title,
            },
            legend: {
              orient: "vertical",
              left: "10%",
              bottom: "40%",
              textStyle: {
                color: "#fff",
              },
            },
            tooltip: {
              trigger: "item",
            },
            series: [
              {
                type: option.chartType,
                radius: "60%",
                center: ["60%", "50%"],
                data: option.data,
                label: {
                  color: "#fff",
                },
              },
            ],
          };
          break;
        case "pie2":
          addOption = {
            title: {
              text: option.title,
            },
            legend: {
              orient: "vertical",
              left: "10%",
              // bottom: "40%",
              padding: [60, 10],
              textStyle: {
                color: "#fff",
              },
            },
            tooltip: {
              trigger: "item",
            },
            series: [
              {
                type: option.chartType,
                radius: "60%",
                center: ["60%", "50%"],
                data: option.data,
                label: {
                  color: "#fff",
                },
              },
            ],
          };
          break;
      }
      this.chart.setOption(addOption);
    },
  },
  watch: {
    chartData: {
      deep: true,
      handler(value) {
        this.renderChart(value);
      },
    },
  },
};
</script>
