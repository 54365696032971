const protocolStore = {
	namespaced: true,
	state: {
		CommendType: null,

		AppInfo: null,
		CommonOption: null,
		TrainFile: null, // X
		IPConfig: null, // X

		CenterInfo: null,
		SignalInfo: null,
		VoiceGuideInfo: null,
		VMSInfo: null,
		SpotLightInfo: null,
		FloorIndicatorInfo: null,

		Configration: null, // X
		ScreenShot: null, // X
		PTZ: null, // X

		VideoSource: [],
		ChannelState: [],
		Action: [],
		Approach: [],
		CarDetectROI: [],
		CarCountROI: [],
		PersonDetectROI: [],
		ChannelOption: [],
		Signal: [],

		CarState: [],
		CarCount: [],
		PersonState: [],
		PersonCount: [],
	},
	getters: {
		GET_COMMEND_TYPE: state => state.CommendType,

		GET_APP_INFO: state => state.AppInfo,
		GET_COMMON_OPTION: state => state.CommonOption,
		GET_TRAIN_FILE: state => state.TrainFile,

		GET_IP_CONFIG: state => state.IPConfig,
		GET_CENTER_INFO: state => state.CenterInfo,
		GET_SIGNAL_INFO: state => state.SignalInfo,
		GET_VOICE_GUIDE_INFO: state => state.VoiceGuideInfo,
		GET_VMS_INFO: state => state.VMSInfo,
		GET_SPOT_LIGHT_INFO: state => state.SpotLightInfo,
		GET_FLOOR_INDICATOR_INFO: state => state.FloorIndicatorInfo,
		GET_CONFIGRATION: state => state.Configration,
		GET_SCREENSHOT: state => state.ScreenShot,
		GET_PTZ: state => state.PTZ,

		GET_VIDEO_SOURCE: state => state.VideoSource,
		GET_CHANNEL_STATE: state => state.ChannelState,
		GET_ACTION: state => state.Action,
		GET_APPROACH: state => state.Approach,
		GET_CAR_DETECT_ROI: state => state.CarDetectROI,
		GET_CAR_COUNT_ROI: state => state.CarCountROI,
		GET_PERSON_DETECT_ROI: state => state.PersonDetectROI,
		GET_CHANNEL_OPTION: state => state.ChannelOption,
		GET_SIGNAL: state => state.Signal,

		GET_CAR_STATE: state => state.CarState,
		GET_CAR_COUNT: state => state.CarCount,
		GET_PERSON_STATE: state => state.PersonState,
		GET_PERSON_COUNT: state => state.PersonCount,
	},
	mutations: {
		SET_PROTOCOL_CLEAR: state => {
			state.CommendType = null

			state.AppInfo = null
			state.CommonOption = null
			state.TrainFile = null
			state.IPConfig = null
			state.CenterInfo = null
			state.SignalInfo = null
			state.VoiceGuideInfo = null
			state.VMSInfo = null
			state.SpotLightInfo = null
			state.FloorIndicatorInfo = null
			state.Configration = null
			state.ScreenShot = null
			state.PTZ = null

			state.VideoSource = []
			state.ChannelState = []
			state.Action = []
			state.Approach = []
			state.CarDetectROI = []
			state.CarCountROI = []
			state.PersonDetectROI = []
			state.ChannelOption = []
			state.Signal = []

			state.CarState = []
			state.CarCount = []
			state.PersonState = []
			state.PersonCount = []
		},

		SET_COMMEND_TYPE: (state, payload) => (state.CommendType = payload),

		SET_APP_INFO: (state, payload) => (state.AppInfo = payload),
		SET_COMMON_OPTION: (state, payload) => (state.CommonOption = payload),
		SET_TRAIN_FILE: (state, payload) => (state.TrainFile = payload),
		SET_IP_CONFIG: (state, payload) => (state.IPConfig = payload),
		SET_CENTER_INFO: (state, payload) => (state.CenterInfo = payload),
		SET_SIGNAL_INFO: (state, payload) => (state.SignalInfo = payload),
		SET_VOICE_GUIDE_INFO: (state, payload) => (state.VoiceGuideInfo = payload),
		SET_VMS_INFO: (state, payload) => (state.VMSInfo = payload),
		SET_SPOT_LIGHT_INFO: (state, payload) => (state.SpotLightInfo = payload),
		SET_FLOOR_INDICATOR_INFO: (state, payload) =>
			(state.FloorIndicatorInfo = payload),
		SET_CONFIGRATION: (state, payload) => (state.Configration = payload),
		SET_SCREENSHOT: (state, payload) => (state.ScreenShot = payload),
		SET_PTZ: (state, payload) => (state.PTZ = payload),

		SET_VIDEO_SOURCE: (state, payload) => (state.VideoSource = payload),
		SET_CHANNEL_STATE: (state, payload) => (state.ChannelState = payload),
		SET_ACTION: (state, payload) => (state.Action = payload),
		SET_APPROACH: (state, payload) => (state.Approach = payload),
		SET_CAR_DETECT_ROI: (state, payload) => (state.CarDetectROI = payload),
		SET_CAR_COUNT_ROI: (state, payload) => (state.CarCountROI = payload),
		SET_PERSON_DETECT_ROI: (state, payload) =>
			(state.PersonDetectROI = payload),
		SET_CHANNEL_OPTION: (state, payload) => (state.ChannelOption = payload),
		SET_SIGNAL: (state, payload) => (state.Signal = payload),

		SET_CAR_STATE: (state, payload) => (state.CarState = payload),
		SET_CAR_COUNT: (state, payload) => (state.CarCount = payload),
		SET_PERSON_STATE: (state, payload) => (state.PersonState = payload),
		SET_PERSON_COUNT: (state, payload) => (state.PersonCount = payload),
	},
	actions: {
		AC_PROTOCOL_CLEAR({ commit }) {
			commit('SET_PROTOCOL_CLEAR')
		}, // 초기화

		AC_COMMEND_TYPE({ commit }, payload) {
			commit('SET_COMMEND_TYPE', payload)
		},

		AC_APP_INFO({ commit }, payload) {
			commit('SET_APP_INFO', payload)
		},
		AC_COMMON_OPTION({ commit }, payload) {
			commit('SET_COMMON_OPTION', payload)
		},
		AC_TRAIN_FILE({ commit }, payload) {
			commit('SET_TRAIN_FILE', payload)
		},
		AC_IP_CONFIG({ commit }, payload) {
			commit('SET_IP_CONFIG', payload)
		},
		AC_CENTER_INFO({ commit }, payload) {
			commit('SET_CENTER_INFO', payload)
		},
		AC_SIGNAL_INFO({ commit }, payload) {
			commit('SET_SIGNAL_INFO', payload)
		},
		AC_VOICE_GUIDE_INFO({ commit }, payload) {
			commit('SET_VOICE_GUIDE_INFO', payload)
		},
		AC_VMS_INFO({ commit }, payload) {
			commit('SET_VMS_INFO', payload)
		},
		AC_SPOT_LIGHT_INFO({ commit }, payload) {
			commit('SET_SPOT_LIGHT_INFO', payload)
		},
		AC_FLOOR_INDICATOR_INFO({ commit }, payload) {
			commit('SET_FLOOR_INDICATOR_INFO', payload)
		},
		AC_CONFIGRATION({ commit }, payload) {
			commit('SET_CONFIGRATION', payload)
		},
		AC_SCREENSHOT({ commit }, payload) {
			commit('SET_SCREENSHOT', payload)
		},
		AC_PTZ({ commit }, payload) {
			commit('SET_PTZ', payload)
		},

		AC_VIDEO_SOURCE({ commit }, payload) {
			commit('SET_VIDEO_SOURCE', payload)
		},
		AC_CHANNEL_STATE({ commit }, payload) {
			commit('SET_CHANNEL_STATE', payload)
		},
		AC_ACTION({ commit }, payload) {
			commit('SET_ACTION', payload)
		},
		AC_APPROACH({ commit }, payload) {
			commit('SET_APPROACH', payload)
		},
		AC_CAR_DETECT_ROI({ commit }, payload) {
			commit('SET_CAR_DETECT_ROI', payload)
		},
		AC_CAR_COUNT_ROI({ commit }, payload) {
			commit('SET_CAR_COUNT_ROI', payload)
		},
		AC_PERSON_DETECT_ROI({ commit }, payload) {
			commit('SET_PERSON_DETECT_ROI', payload)
		},
		AC_CHANNEL_OPTION({ commit }, payload) {
			commit('SET_CHANNEL_OPTION', payload)
		},
		AC_SIGNAL({ commit }, payload) {
			commit('SET_SIGNAL', payload)
		},

		AC_CAR_STATE({ commit }, payload) {
			commit('SET_CAR_STATE', payload)
		},
		AC_CAR_COUNT({ commit }, payload) {
			commit('SET_CAR_COUNT', payload)
		},
		AC_PERSON_STATE({ commit }, payload) {
			commit('SET_PERSON_STATE', payload)
		},
		AC_PERSON_COUNT({ commit }, payload) {
			commit('SET_PERSON_COUNT', payload)
		},
	},
}

export default protocolStore
