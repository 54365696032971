<template>
	<div class="">
		카메라IP설정
		<button @click="test">Click</button>
	</div>
</template>
<script>
export default {
	data() {
		return {
			//menu: false
		}
	},
	created() {},
	methods: {
		test() {
			let kk = window.open('http://10.30.2.3:9300', 'laonTas')
			kk.postMessage('createToken')
			// let kk = window.open("http://10.30.2.3:9300", "laonTas")
			// let el = document.createElement("script")
			// el.innerText = `sessionStorage.setItem('access_token', "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXNzIjoiMjAyY2I5NjJhYzU5MDc1Yjk2NGIwNzE1MmQyMzRiNzAiLCJpYXQiOjE2MDkyMzQ0NDh9.wxpA92ZXSyGHhq2XzWLmZLR3qdI0Na6pzloso8m1x6I");`

			// kk.document.head.appendChild(el)
			// kk.document.write(`<iframe src="http://10.30.2.3:9300" style=width:100%; height:100%;>`)

			// let kk = window.open("http://10.30.2.3:9300", "laonTas")
			// let el = document.createElement("script")
			// el.innerText = `sessionStorage.setItem('access_token', "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXNzIjoiMjAyY2I5NjJhYzU5MDc1Yjk2NGIwNzE1MmQyMzRiNzAiLCJpYXQiOjE2MDkyMzQ0NDh9.wxpA92ZXSyGHhq2XzWLmZLR3qdI0Na6pzloso8m1x6I");`

			// kk.document.head.appendChild(el)

			// let kk = window.open("", "laonTas")
			// let el = document.createElement("script")
			// el.innerText = `sessionStorage.setItem('access_token', "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXNzIjoiMjAyY2I5NjJhYzU5MDc1Yjk2NGIwNzE1MmQyMzRiNzAiLCJpYXQiOjE2MDkyMzQ0NDh9.wxpA92ZXSyGHhq2XzWLmZLR3qdI0Na6pzloso8m1x6I");`

			// kk.document.write(el)
			// kk = window.open("http://10.30.2.3:9300", "laonTas")
		},
	},
}
</script>
