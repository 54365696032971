<template>
  <section class="error-wrapper" style="height: 100vh">
    <article class="error-wrapper__sub" style="height: 100%">
      <div
        class="error-box"
        style="
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1rem;
        "
      >
        <div
          style="
            width: 300px;
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="240"
            height="240"
            view-box="0 0 240 240"
          >
            <circle
              cx="120"
              cy="120"
              r="70"
              fill="none"
              stroke="#e04006"
              stroke-width="8"
            />
            <path
              fill="none"
              stroke="#e04006"
              stroke-width="6"
              d="M120 100v30m0 7v6"
            />
          </svg>
        </div>
        <span style="font-size: 1.5rem"> Error Page </span>
      </div>
    </article>
  </section>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      sampleData: "",
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {},
};
</script>
