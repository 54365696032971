<template>
	<div class="metering-price-dialog">
		<span class="metering-price-title">요금정책</span>
		<div class="price-tab__wrapper">
			<ul>
				<li :class="{ active: tabTarget === 'DB' }" @click="tabTarget = 'DB'">
					DB 정책
				</li>
				<li :class="{ active: tabTarget === 'API' }" @click="tabTarget = 'API'">
					API call 정책
				</li>
				<li
					:class="{ active: tabTarget === 'Traffic' }"
					@click="tabTarget = 'Traffic'"
				>
					Traffic 정책
				</li>
			</ul>
		</div>
		<v-simple-table dark>
			<template v-if="tabTarget === 'DB'">
				<thead>
					<tr class="manage-header">
						<th>기준용량 (GB)</th>
						<th>월 요금</th>
						<th>기준요금 (일)</th>
						<th>정책</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(data, idx) in priceData" :key="data.volume">
						<td>{{ data.volume }}</td>
						<td>{{ data.price }} 원</td>
						<td>{{ data.criteria }} 원</td>
						<td class="metering-price-desc" rowspan="4" v-if="idx === 0">
							<div class="price-desc__wrap">
								<p class="desc-title">환불 정책</p>
								<p>서비스 종료 시, 남은 기간 기준으로 환불해드립니다.</p>
								<p class="desc-title">기준 용량 변경 정책</p>
								<p>
									기준 용량 변경 시, 남은 기간 기준으로 변경된 금액으로 요금이
									청구됩니다.
								</p>
								<p class="desc-important">
									※ 본 금액은 예상 금액으로 실제 청구금액과 다를 수 있습니다.
								</p>
							</div>
						</td>
					</tr>
					<tr>
						<td>100 GB 초과</td>
						<td>-</td>
						<td>-</td>
						<td>
							<a href="http://laonroad.com/" target="_blank"> 문의하기 </a>
						</td>
					</tr>
				</tbody>
			</template>
			<template v-else-if="tabTarget === 'API'">
				<thead>
					<tr class="manage-header">
						<th>기준요금</th>
						<th>정책</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="price-desc__api">
							<p>100회 / 월 무료 이후 Apicall 1회 당 100원</p>
						</td>
						<td class="metering-price-desc">
							<div class="price-desc__wrap">
								<p class="desc-title">환불 정책</p>
								<p>서비스 종료 시, 남은 기간 기준으로 환불해드립니다.</p>
								<p class="desc-title">기준 용량 변경 정책</p>
								<p>
									기준 용량 변경 시, 남은 기간 기준으로 변경된 금액으로 요금이
									청구됩니다.
								</p>
								<p class="desc-important">
									※ 본 금액은 예상 금액으로 실제 청구금액과 다를 수 있습니다.
								</p>
							</div>
						</td>
					</tr>
				</tbody>
			</template>
			<template v-else-if="tabTarget === 'Traffic'">
				<thead>
					<tr class="manage-header">
						<th>기준요금</th>
						<th>정책</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="price-desc__api">
							<p>10GB / 월 무료 이후 1GB 당 100원</p>
						</td>
						<td class="metering-price-desc">
							<div class="price-desc__wrap">
								<p class="desc-title">환불 정책</p>
								<p>서비스 종료 시, 남은 기간 기준으로 환불해드립니다.</p>
								<p class="desc-title">기준 용량 변경 정책</p>
								<p>
									기준 용량 변경 시, 남은 기간 기준으로 변경된 금액으로 요금이
									청구됩니다.
								</p>
								<p class="desc-important">
									※ 본 금액은 예상 금액으로 실제 청구금액과 다를 수 있습니다.
								</p>
							</div>
						</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
	</div>
</template>

<script>
export default {
	name: '',
	components: {},
	data() {
		return {
			tabTarget: 'DB',
			priceData: [
				{
					volume: '10 GB',
					price: 30000,
					criteria: 1000,
				},
				{
					volume: '20 GB',
					price: 50000,
					criteria: 1700,
				},
				{
					volume: '50 GB',
					price: 80000,
					criteria: 2700,
				},
				{
					volume: '100 GB',
					price: 100000,
					criteria: 3400,
				},
			],
		}
	},
	setup() {},
	created() {},
	mounted() {},
	unmounted() {},
	methods: {
		selectTabHandler() {},
	},
}
</script>
