<template>
  <div class="dContainer" :class="{ active: this.classIf.overlay.roadview }">
    <div class="droadview">
      <div id="roadview" style="width: 100%; height: 100%"></div>
      <!-- 로드뷰를 표시할 div 입니다 -->
      <div id="close" title="로드뷰닫기" onclick="closeRoadview()">
        <span class="img"></span>
      </div>
    </div>
    <div class="dmap" style="position: relative">
      <div
        id="map"
        style="
          width: 100%;
          height: 780px;
          position: relative;
          overflow: hidden;
          border-radius: 10px;
        "
      ></div>
      <div class="custom_typecontrol radius_border">
        <span
          @click="setMapType('roadmap')"
          :class="{ active: this.classIf.mapType.roadview }"
          >지도</span
        >
        <span
          @click="setMapType('skyview')"
          :class="{ active: this.classIf.mapType.skyview }"
          >스카이뷰</span
        >
      </div>
      <div class="category">
        <ul>
          <li
            @click="changeMarker('intersection')"
            :class="{ active: this.classIf.map.intersection }"
          >
            <span class="ico_comm ico_coffee"></span>
            교차로
          </li>
          <li
            @click="changeMarker('camera')"
            :class="{ active: this.classIf.map.camera }"
          >
            <span class="ico_comm ico_store"></span>
            카메라
          </li>
          <li
            @click="changeMarker('avenue')"
            :class="{ active: this.classIf.map.avenue }"
          >
            <span class="ico_comm ico_carpark"></span>
            접근로
          </li>
          <!-- <li @click="changeMarker('light')" :class="{active:this.classIf.map.light}">
                <span class="ico_comm ico_light"></span>
                신호등
            </li>
            <li @click="changeMarker('its')" :class="{active:this.classIf.map.its}">
                <span class="ico_comm ico_its"></span>
                I T S
            </li> -->
        </ul>
      </div>
      <div class="category category2">
        <ul>
          <li
            @click="setTrafficOverlay('traffic')"
            :class="{ active: this.classIf.overlay.traffic }"
          >
            <span class="ico_comm ico_traffic"></span>
            교통
          </li>
          <li
            @click="setTrafficOverlay('bicycle')"
            :class="{ active: this.classIf.overlay.bicycle }"
          >
            <span class="ico_comm ico_bicycle"></span>
            자전거
          </li>
          <li
            @click="setTrafficOverlay('roadview')"
            :class="{ active: this.classIf.overlay.roadview }"
          >
            <span class="ico_comm ico_roadview"></span>
            로드뷰
          </li>
          <li
            @click="setTrafficOverlay('label')"
            :class="{ active: this.classIf.overlay.label }"
          >
            <span class="ico_comm ico_label"></span>
            레이블
          </li>
        </ul>
      </div>
      <div class="legend">
        <table>
          <colgroup>
            <col width="12.5%" />
            <col width="12.5%" />
            <col width="12.5%" />
            <col width="12.5%" />
            <col width="12.5%" />
            <col width="12.5%" />
            <col width="12.5%" />
            <col width="12.5%" />
          </colgroup>
          <thead>
            <tr>
              <th><span class="box color1"></span></th>
              <th><span class="box color2"></span></th>
              <th><span class="box color3"></span></th>
              <th><span class="box color4"></span></th>
              <th><span class="box color5"></span></th>
              <th><span class="box color6"></span></th>
              <th><span class="box color7"></span></th>
              <th><span class="box color8"></span></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>A</td>
              <td>B</td>
              <td>C</td>
              <td>D</td>
              <td>E</td>
              <td>F</td>
              <td>FF</td>
              <td>FFF</td>
            </tr>
            <tr>
              <td>15 ↓</td>
              <td>30 ↓</td>
              <td>50 ↓</td>
              <td>70 ↓</td>
              <td>100 ↓</td>
              <td>220 ↓</td>
              <td>340 ↓</td>
              <td>340 ↑</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
let map, roadView, roadViewClient;
var startX, startY, startOverlayPoint, customoverlay;
var overlayOn = false;
let roadViewMarker;
let interMarkers = [];
let cameraMarkers = [];
let avenueLine = [];
let phaseMarkers = {};
let nodeLink = {};
let signalLink = {};
let signalActiveLink = {};
let signalPlayLink = {};
let trafficLights = {};
let signalInterval = {};
let twinObject = [];

let interOverlays = [];
let cameraOverlays = [];

import { mapGetters, mapActions } from "vuex";
import { getTrafficGisAPI } from "../../api/trafficAPI";
import { getAvenueListAPI } from "../../api/losAPI";
import axios from "axios";
const protocolStore = "protocolStore";
const globalStore = "globalStore";

// import { CANVAS_BUS } from '@/bus/canvasBus.js'
// import { JSON_WS_BUS } from '@/bus/jsonWsBus.js'
export default {
  name: "mapComponent",
  data() {
    return {
      cameraIP: "localhost",
      classIf: {
        map: {
          intersection: true,
          camera: false,
          avenue: true,
          light: false,
          its: false,
        },
        overlay: {
          traffic: false,
          bicycle: false,
          roadview: false,
          label: false,
        },
        mapType: {
          roadview: false,
          skyview: true,
        },
      },
      currentOverlay: null,
      avenue: [],
      //losColor : {'': '#82ff72','A' :'#00FF00', 'B' :'#00FF00','C' :'#fdeb11','D' :'#ff961d','E' :'#ff961d','F' :'#dd292a','FF' :'#dd292a','FFF' :'#dd292a'}
      losColor: {
        "": "#c2c2c2",
        A: "#E2EFDA",
        B: "#A9D08E",
        C: "#00B050",
        D: "#FFFF00",
        E: "#FFC000",
        F: "#FF0000",
        FF: "#FF0000",
        FFF: "#FF0000",
      },

      channel: {
        addressDialog: false,
        addressConnect: false,
        headers: [
          { name: `CH`, cols: 1 },
          { name: `Address`, cols: 6 },
          { name: `상태`, cols: 3 },
          { name: `동작`, cols: 2 },
        ],
        items: [{}, {}, {}, {}],
      },
      webSocketHandler: {
        socket: null,
        ip: "localhost",
        port: "8001",
      },
    };
  },
  props: {
    intersectionSeq: {
      type: Number,
      default: 0,
    },
    intersection: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters(protocolStore, [`GET_VIDEO_SOURCE`]),
    ...mapGetters(globalStore, [`GET_CH_INDEX`, "getHostName"]),
  },
  created() {},
  mounted() {
    window.kk = this;
    window.nodeLink = nodeLink;
    window.signalLink = signalLink;
    window.signalActiveLink = signalActiveLink;
    window.signalPlayLink = signalPlayLink;
    window.trafficLight = trafficLights;
    window.twinObject = twinObject;
    // if(typeof kakao !== Object){
    //   console.log('없음')
    //   let js = document.createElement('script')
    //   js.setAttribute("src", `/js/kakaoapi.js`)
    //   js.setAttribute("type", `text/javascript`)
    //   document.head.appendChild(js)
    //   console.log('등록')
    // }
    interMarkers = [];
    cameraMarkers = [];
    avenueLine = [];

    interOverlays = [];
    cameraOverlays = [];

    var mapContainer = document.getElementById("map");
    var rvContainer = document.getElementById("roadview");

    //var mapCenter = new kakao.maps.LatLng(37.38908161130102, 126.94810068307262)
    var mapCenter = new kakao.maps.LatLng(
      37.393396724606866,
      126.95741154909003
    );
    var mapOptions = {
      center: mapCenter, //new daum.maps.LatLng(37.38908161130102, 126.94810068307262),
      mapTypeId: kakao.maps.MapTypeId.SKYVIEW,
      level: 7, //지도의 레벨(확대, 축소 정도)
    };
    document.map = map = new daum.maps.Map(mapContainer, mapOptions);
    roadView = new kakao.maps.Roadview(rvContainer);
    roadViewClient = new kakao.maps.RoadviewClient();

    kakao.maps.event.addListener(roadView, "position_changed", function () {
      var roadViewPosition = roadView.getPosition();
      map.setCenter(roadViewPosition);
      if (overlayOn) {
        roadViewMarker.setPosition(roadViewPosition);
      }
    });

    var markImage = new kakao.maps.MarkerImage(
      "https://t1.daumcdn.net/localimg/localimages/07/2018/pc/roadview_minimap_wk_2018.png",
      new kakao.maps.Size(26, 46),
      {
        spriteSize: new kakao.maps.Size(1666, 168),
        spriteOrigin: new kakao.maps.Point(705, 114),
        offset: new kakao.maps.Point(13, 46),
      }
    );

    document.roadViewMarker = roadViewMarker = new kakao.maps.Marker({
      image: markImage,
      position: mapCenter,
      draggable: true,
    });

    kakao.maps.event.addListener(roadViewMarker, "dragend", () => {
      var position = roadViewMarker.getPosition();
      this.toggleRoadview(position);
    });

    kakao.maps.event.addListener(map, "click", (mouseEvent) => {
      if (!overlayOn) {
        return;
      }
      var position = mouseEvent.latLng;
      roadViewMarker.setPosition(position);
      this.toggleRoadview(position);
    });

    // var mapTypeControl = new kakao.maps.MapTypeControl();
    // map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);

    var zoomControl = new kakao.maps.ZoomControl();
    map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);

    kakao.maps.event.addListener(map, "zoom_changed", function () {
      // 지도의 현재 레벨을 얻어옵니다
      var level = map.getLevel();

      let size = 0;
      let width = 0;
      let height = 0;

      switch (level) {
        case 0:
          size = 160;
          break;
        case 1:
          size = 80;
          break;
        case 2:
          size = 60;
          break;
        case 3:
          size = 48;
          break;
        case 4:
          size = 36;
          break;
        case 5:
          size = 24;
          break;
        case 6:
          size = 24;
          break;
        case 7:
          size = 12;
          break;
        default:
          size = 0;
      }

      for (let i in phaseMarkers) {
        for (let j in phaseMarkers[i]) {
          let src = phaseMarkers[i][j].getImage().Xj;
          let imgSize = new kakao.maps.Size(size, size);
          let imageOption = new kakao.maps.Point(size / 2, size / 2);
          let img = new kakao.maps.MarkerImage(src, imgSize, imageOption);
          phaseMarkers[i][j].setImage(img);
        }
      }

      switch (level) {
        case 0:
          width = 76;
          height = 21;
          break;
        case 1:
          width = 76;
          height = 21;
          break;
        case 2:
          width = 51;
          height = 14;
          break;
        case 3:
          width = 51;
          height = 14;
          break;
        case 4:
          width = 25;
          height = 7;
          break;
        default:
          width = 0;
          height = 0;
      }

      for (let i in trafficLights) {
        for (let j in trafficLights[i]) {
          let src = trafficLights[i][j].getImage().Yj;
          let imgSize = new kakao.maps.Size(width, height);
          let imageOption = new kakao.maps.Point(width / 2, height / 2);
          let img = new kakao.maps.MarkerImage(src, imgSize, imageOption);
          trafficLights[i][j].setImage(img);
        }
      }
    });

    // this.getNodeLink()
  },
  methods: {
    ...mapActions(globalStore, [`AC_MAIN`, `AC_CHANNEL`]),
    addIntersection(e) {
      e.forEach((item, idx) => {
        var imageSrc = "/img/icon/default.png",
          imageSize = new kakao.maps.Size(34, 50);
        //imageOption = {offset: new kakao.maps.Point(34, 50)};
        //임시 테스트용

        //item.LOS = color
        switch (item.LOS) {
          case "A":
            imageSrc = "/img/icon/a.png";
            break;
          case "B":
            imageSrc = "/img/icon/b.png";
            break;
          case "C":
            imageSrc = "/img/icon/c.png";
            break;
          case "D":
            imageSrc = "/img/icon/d.png";
            break;
          case "E":
            imageSrc = "/img/icon/e.png";
            break;
          case "F":
            imageSrc = "/img/icon/f.png";
            break;
          case "FF":
            imageSrc = "/img/icon/ff.png";
            break;
          case "FFF":
            imageSrc = "/img/icon/fff.png";
            break;
        }
        let markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize); //, imageOption)
        let marker = new kakao.maps.Marker({
          position: new kakao.maps.LatLng(item["Latitude"], item["Longitude"]),
          image: markerImage,
          clickable: true,
        });
        marker.setMap(map);

        var infoContent = `<div style="background:#343a4090; padding:5px; border:1px solid #39a5d9; font-size:14px">${item["IntersectionID"]}. ${item["IntersectionName"]}</div>`;
        var infowindow = new kakao.maps.CustomOverlay({
          content: infoContent,
          position: marker.getPosition(),
          xAnchor: 0.5,
          yAnchor: 2.5,
        });

        kakao.maps.event.addListener(marker, "mouseover", function () {
          infowindow.setMap(map);
        });

        kakao.maps.event.addListener(marker, "mouseout", function () {
          infowindow.setMap(null);
        });

        let content = document.createElement("div");
        content.className = "Owrap";
        content.id = "playPanel";
        content.style =
          "background:#343a4090; padding:5px; border:1px solid #39a5d9; font-size:14px";
        content.innerHTML = `<div class="">
                                  <div class="" style="font-size:16px">
                                      ${item["IntersectionID"]}. ${
          item["IntersectionName"]
        } <span style="font-size: 12px;">${new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 19)
          .replace("T", " ")}</span>
                                      <div class="close" title="닫기" style="float: right;">닫기</div>
                                  </div>
                                  <div class="body" style="margin-top: 3px;">
                                    <div id="subMap${idx}" class="img" style="width:340px; height:340px;">
                                      
                                    </div>
                                  </div>
                              </div>`;

        content.onclick = (e) => {
          if (e.target.className === "close") {
            this.closeOverlay(idx);
          }
        };

        let overlay = new kakao.maps.CustomOverlay({
          content: content,
          position: marker.getPosition(),
          xAnchor: -0.03,
          yAnchor: 1.13,
          clickable: true,
        });

        // 커스텀 오버레이에 mousedown이벤트를 등록합니다
        this.addEventHandle(content, "mousedown", function () {
          customoverlay = overlay;
        });
        this.addEventHandle(content, "mousedown", this.onMouseDown);

        // mouseup 이벤트가 일어났을때 mousemove 이벤트를 제거하기 위해
        // document에 mouseup 이벤트를 등록합니다
        this.addEventHandle(document, "mouseup", this.onMouseUp);

        let tempObject = this;
        kakao.maps.event.addListener(marker, "click", function () {
          tempObject.$emit("select", item["IntersectionSeq"]);
          overlay.setPosition(marker.getPosition());
          overlay.setMap(map);

          let subMapPanel = document.getElementById(`subMap${idx}`);
          if (subMapPanel.children.length === 0) {
            let option = {
              center: marker.getPosition(),
              draggable: false,
              level: 3,
            };
            let subMap = new kakao.maps.Map(subMapPanel, option);
            item.Avenue.forEach((el) => {
              let subContent = `<div class ="label">
                    <table style="width:84px; height:70px; color:#121212; transform: rotate( ${el.Degree}deg );">
                      <tr>
                        <td style="">
                          <img src="/img/icon/pbot.png"/ style="width:84px; height:30px; display: inherit;">
                          <span style="background: #ffffff90; color:crimson; font-weight: bold; width:26px; height:21px; display: inline-block;padding: 3px 0px; transform: rotate( -${el.Degree}deg ); ">${el.traffic[1]["value"]}</span>
                          <span style="background: #ffffff90; color:crimson; font-weight: bold; width:26px; height:21px; display: inline-block;padding: 3px 0px; transform: rotate( -${el.Degree}deg ); ">${el.traffic[0]["value"]}</span>
                          <span style="background: #ffffff90; color:crimson; font-weight: bold; width:26px; height:21px; display: inline-block;padding: 3px 0px; transform: rotate( -${el.Degree}deg ); ">${el.traffic[2]["value"]}</span>
                        </td>
                      </tr>
                    </table>
                  </div>`;
              let subLocation, subCustomOverlay;
              if (el.location) {
                subLocation = el?.Location[0][2];
              }
              let subPosition = new kakao.maps.LatLng(
                subLocation?.y,
                subLocation?.x
              );
              subCustomOverlay = new kakao.maps.CustomOverlay({
                position: subPosition,
                content: subContent,
              });
              subCustomOverlay.setMap(subMap);
            });
          }
        });
        interOverlays.push(overlay);
        interMarkers.push(marker);

        //신호 관련
        try {
          if (item.Phase) {
            for (let i in item.Phase) {
              this.addSignalPhase(
                idx,
                i,
                item.Phase[i].Icon,
                item["Latitude"],
                item["Longitude"]
              );
            }

            // this.webSocketHandler = {
            // 	socket: null,
            // 	ip: item.ControllerIP,
            // 	port: '8001',
            // }

            // this.webSocketHandler.socket = new WebSocket(
            // 	`ws://${this.webSocketHandler.ip}:${this.webSocketHandler.port}`,
            // )
            // this.webSocketHandler.socket.onopen = () => {
            // 	const json = JSON.stringify({ Serial: 0, CommendType: `get_all` })
            // 	this.webSocketHandler.socket.send(json)
            // }

            // this.webSocketHandler.socket.onclose = () => {
            // 	this.webSocketHandler.socket = null
            // }

            // this.webSocketHandler.socket.onerror = e =>
            // 	console.log(`json ws[${this.webSocketHandler.port}] error: ${e}`)

            // this.webSocketHandler.socket.onmessage = msg => {
            // 	try {
            // 		//console.log(`json ws[${this.ws.port}] message: ${msg.data}`)
            // 		const obj = JSON.parse(msg.data)
            // 		if (obj.Signal !== undefined) {
            // 			this.changeSignalPhase(
            // 				idx,
            // 				obj.Signal[0]['Vehicle']['ARingIndex'],
            // 			)
            // 		}
            // 	} catch (e) {
            // 		console.log(
            // 			`json ws[${this.webSocketHandler.port}] message error: ${e}`,
            // 		)
            // 	}
            // }
          }
        } catch (err) {
          return false;
        }

        //ITS 노드링크 관련
        if (item.IntersectionSeq === 15) {
          if (!signalLink[idx])
            signalLink[idx] = { 1: [], 2: [], 3: [], 4: [] };
          if (!nodeLink[idx]) nodeLink[idx] = { 1: [], 2: [], 3: [], 4: [] };
          if (!signalActiveLink[idx]) signalActiveLink[idx] = [];
          if (!signalPlayLink[idx]) signalPlayLink[idx] = [];
          if (!signalInterval[idx]) signalInterval[idx] = null;

          // this.webSocketHandler = {
          // 	socket: null,
          // 	ip: item.ControllerIP,
          // 	port: '8001',
          // }

          // this.webSocketHandler.socket = new WebSocket(
          // 	`ws://${this.webSocketHandler.ip}:${this.webSocketHandler.port}`,
          // )
          // this.webSocketHandler.socket.onopen = () => {
          // 	const json = JSON.stringify({ Serial: 0, CommendType: `get_all` })
          // 	this.webSocketHandler.socket.send(json)
          // }

          // this.webSocketHandler.socket.onclose = () => {
          // 	//this.ws.json.close()
          // 	console.log(
          // 		`json ws[${this.webSocketHandler.ip}:${this.webSocketHandler.port}] close`,
          // 	)
          // 	this.webSocketHandler.socket = null
          // }

          // this.webSocketHandler.socket.onerror = e =>
          // 	console.log(`json ws[${this.webSocketHandler.port}] error: ${e}`)

          // this.webSocketHandler.socket.onmessage = msg => {
          // 	try {
          // 		//console.log(`json ws[${this.ws.port}] message: ${msg.data}`)
          // 		const obj = JSON.parse(msg.data)
          // 		if (obj.Signal !== undefined) {
          // 			this.changeTrafficLight(
          // 				idx,
          // 				obj.Signal[0]['Vehicle']['ARingIndex'],
          // 			)
          // 		}
          // 		if (obj.CarState !== undefined) {
          // 			this.changeNodeLink(idx, obj.CarState)
          // 		}
          // 		if (obj.Result !== undefined) {
          // 			this.digitalTwinTest(obj.Result)
          // 		}
          // 	} catch (e) {
          // 		console.log(
          // 			`json ws[${this.webSocketHandler.port}] message error: ${e}`,
          // 		)
          // 	}
          // }
        }
      });
    },

    addAvenue(e) {
      e.forEach((item, idx) => {
        this.intersection.some((el2) => {
          if (el2.IntersectionSeq === item.IntersectionSeq) {
            if (!el2.Avenue) el2.Avenue = [];
            el2.Avenue.push(item);
            return true;
          }
        });
        const cameraPosition = item["CameraLocation"]["coordinates"]
          ? item["CameraLocation"]["coordinates"]
          : [];
        if (!item["CameraLocation"]) {
          return;
        }
        //if(idx !== 0) return;
        let imageSrc = "/img/icon/camera.png";
        let imageSize = new kakao.maps.Size(25, 25);
        //imageOption = {offset: new kakao.maps.Point(25, 25)};
        let markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize);

        let marker;

        marker = new kakao.maps.Marker({
          //DB에 x,y 좌표 반대로 저장 되어있음.....
          position: new kakao.maps.LatLng(cameraPosition[1], cameraPosition[0]),

          image: markerImage,
          clickable: true,
        });

        // marker.setMap(map);

        var infoContent = `<div style="background:#343a4090; padding:5px; border:1px solid #39a5d9; font-size:14px">${item["IntersectionID"]}-${item["AvenueID"]}. ${item["IntersectionName"]}/${item["AvenueName"]}</div>`;
        var infowindow = new kakao.maps.CustomOverlay({
          content: infoContent,
          position: marker.getPosition(),
          xAnchor: 0.5,
          yAnchor: 2.5,
        });
        kakao.maps.event.addListener(marker, "mouseover", function () {
          infowindow.setMap(map);
        });
        kakao.maps.event.addListener(marker, "mouseout", function () {
          infowindow.setMap(null);
        });

        let content = document.createElement("div");
        content.className = "Owrap";
        content.id = "playPanel";
        content.style =
          "background:#343a4090; padding:5px; border:1px solid #39a5d9; font-size:14px";

        content.onclick = (e) => {
          if (e.target.className === "close") {
            const cameraContainer = document.getElementById("camera-container");

            if (cameraContainer) {
              cameraContainer.remove();
            }
            this.closeOverlayCamera(idx);
          }
        };

        let overlay = new kakao.maps.InfoWindow({
          content: content,
          position: marker.getPosition(),
          xAnchor: -0.03,
          yAnchor: 1.13,
          clickable: true,
        });

        // 커스텀 오버레이에 mousedown이벤트를 등록합니다
        this.addEventHandle(content, "mousedown", function () {
          customoverlay = overlay;
        });
        this.addEventHandle(content, "mousedown", this.onMouseDown);

        // mouseup 이벤트가 일어났을때 mousemove 이벤트를 제거하기 위해
        // document에 mouseup 이벤트를 등록합니다
        this.addEventHandle(document, "mouseup", this.onMouseUp);

        let tempObject = this;
        kakao.maps.event.addListener(marker, "click", function () {
          tempObject.$emit("select", item["IntersectionSeq"]);
          tempObject.cameraIP = item.CameraIP;

          overlay.setPosition(marker.getPosition());
          overlay.setMap(map);

          content.innerHTML = `<div id="camera-container">
                                      <div class="" style="font-size:16px">
                                          ${item["IntersectionID"]}-${item["AvenueID"]}. ${item["IntersectionName"]}/${item["AvenueName"]} 카메라
                                          <div class="close" title="닫기" style="float: right;">닫기</div>
                                      </div>
                                      <div class="body" style="width:400px; height:300px;" >
                                        <div class="playerBox" >
                                          <video  src='/videos/fast1.1.mp4' autoplay muted type="video/mp4" id="can${item["AvenueSeq"]}" style="width:400px; height:300px;">
                                          </video>
                                        </div>
                                      </div>
                                  </div>`;
          // if (
          // 	IMG_WS_BUS.viewAllModeWs[idx] &&
          // 	IMG_WS_BUS.viewAllModeWs[idx].img !== null
          // ) {
          // 	return false
          // }

          // if (!CANVAS_BUS.can[idx]) {
          // 	CANVAS_BUS.can[idx] = { fabric: null, img: null }
          // 	CANVAS_BUS.can[idx].fabric = new fabric.Canvas(
          // 		`can${item['AvenueSeq']}`,
          // 	)
          // 	CANVAS_BUS.can[idx].fabric.setWidth(380)
          // 	CANVAS_BUS.can[idx].fabric.setHeight(280)
          // 	CANVAS_BUS.can[idx].fabric.selection = false
          // 	CANVAS_BUS.viewerInit(idx, 380, 280)
          // }

          // if (JSON_WS_BUS.ws.ip !== item.ControllerIP) {
          // 	if (JSON_WS_BUS.ws.json !== null) {
          // 		JSON_WS_BUS.ws.json.close()
          // 	}

          // 	JSON_WS_BUS.ws.ip = item.ControllerIP
          // 	IMG_WS_BUS.ws.ip = item.ControllerIP
          // 	IMG_WS_BUS.ws.on = false

          // 	let temp = window.setInterval(function () {
          // 		if (JSON_WS_BUS.ws.json === null) {
          // 			JSON_WS_BUS.wsJsonClient()
          // 			//IMG_WS_BUS.wsImgClient(); // canvas 가 생성 되고 붙어야 함..
          // 			clearInterval(temp)
          // 		}
          // 	}, 200)
          // } else {
          // 	tempObject.GET_VIDEO_SOURCE.some((el, ch) => {
          // 		if (el.Address.indexOf(tempObject.cameraIP) !== -1) {
          // 			let temp = window.setInterval(function () {
          // 				IMG_WS_BUS.viewAllModeWs[idx] = {
          // 					...tempObject.channel.items[ch],
          // 				}
          // 				tempObject.viewAllModeWsConnect(idx)
          // 				clearInterval(temp)
          // 			}, 200)
          // 			return true
          // 		}
          // 	})
          // }
        });

        let linePath = [];
        if (!item["Location"]) {
          return;
        }
        item["Location"][0]?.forEach((el, idx) => {
          if (idx < 2) linePath.push(new kakao.maps.LatLng(el["y"], el["x"]));
        });

        var polyline = new kakao.maps.Polyline({
          path: linePath,
          strokeWeight: 10,
          strokeColor: this.losColor[item.los],
          //strokeColor: this.losColor[color],
          strokeOpacity: 0.7,
          strokeStyle: "solid",
        });
        polyline.setMap(map);
        cameraMarkers.push(marker);
        cameraOverlays.push(overlay);
        avenueLine.push(polyline);
      });
    },
    addSignalPhase(idx, phaseIdx, icon, latitude, longitude) {
      let imageSrc = `/img/icon/phase/${icon}.png`;
      let imageSize = new kakao.maps.Size(12, 12);
      let imageOption = new kakao.maps.Point(6, 6);
      let markerImage = new kakao.maps.MarkerImage(
        imageSrc,
        imageSize,
        imageOption
      );
      let phaseMarker = new kakao.maps.Marker({
        position: new kakao.maps.LatLng(latitude, longitude),
        image: markerImage,
        clickable: true,
      });

      if (!phaseMarkers[idx]) phaseMarkers[idx] = {};
      phaseMarkers[idx][phaseIdx] = phaseMarker;
    },
    onMouseDown(e) {
      // 커스텀 오버레이를 드래그 할 때, 내부 텍스트가 영역 선택되는 현상을 막아줍니다.
      if (e.preventDefault) {
        e.preventDefault();
      } else {
        e.returnValue = false;
      }

      var proj = map.getProjection(), // 지도 객체로 부터 화면픽셀좌표, 지도좌표간 변환을 위한 MapProjection 객체를 얻어옵니다
        overlayPos = customoverlay.getPosition(); // 커스텀 오버레이의 현재 위치를 가져옵니다

      // 커스텀오버레이에서 마우스 관련 이벤트가 발생해도 지도가 움직이지 않도록 합니다
      kakao.maps.event.preventMap();

      // mousedown된 좌표를 설정합니다
      startX = e.clientX;
      startY = e.clientY;

      // mousedown됐을 때의 커스텀 오버레이의 좌표를
      // 지도 컨테이너내 픽셀 좌표로 변환합니다
      startOverlayPoint = proj.containerPointFromCoords(overlayPos);

      // document에 mousemove 이벤트를 등록합니다
      this.addEventHandle(document, "mousemove", this.onMouseMove);
    },

    onMouseMove(e) {
      // 커스텀 오버레이를 드래그 할 때, 내부 텍스트가 영역 선택되는 현상을 막아줍니다.
      if (e.preventDefault) {
        e.preventDefault();
      } else {
        e.returnValue = false;
      }

      var proj = map.getProjection(), // 지도 객체로 부터 화면픽셀좌표, 지도좌표간 변환을 위한 MapProjection 객체를 얻어옵니다
        deltaX = startX - e.clientX, // mousedown한 픽셀좌표에서 mousemove한 좌표를 빼서 실제로 마우스가 이동된 픽셀좌표를 구합니다
        deltaY = startY - e.clientY,
        // mousedown됐을 때의 커스텀 오버레이의 좌표에 실제로 마우스가 이동된 픽셀좌표를 반영합니다
        newPoint = new kakao.maps.Point(
          startOverlayPoint.x - deltaX,
          startOverlayPoint.y - deltaY
        ),
        // 계산된 픽셀 좌표를 지도 컨테이너에 해당하는 지도 좌표로 변경합니다
        newPos = proj.coordsFromContainerPoint(newPoint);

      // 커스텀 오버레이의 좌표를 설정합니다
      customoverlay.setPosition(newPos);
    },

    onMouseUp(e) {
      // 등록된 mousemove 이벤트 핸들러를 제거합니다
      this.removeEventHandle(document, "mousemove", this.onMouseMove);
    },

    addEventHandle(target, type, callback) {
      if (target.addEventListener) {
        target.addEventListener(type, callback);
      } else {
        target.attachEvent("on" + type, callback);
      }
    },

    removeEventHandle(target, type, callback) {
      if (target.removeEventListener) {
        target.removeEventListener(type, callback);
      } else {
        target.detachEvent("on" + type, callback);
      }
    },

    setMapType(maptype) {
      if (maptype === "roadmap") {
        this.classIf.mapType.roadview = true;
        this.classIf.mapType.skyview = false;
        map.setMapTypeId(kakao.maps.MapTypeId.ROADMAP);
      } else {
        this.classIf.mapType.roadview = false;
        this.classIf.mapType.skyview = true;
        map.setMapTypeId(kakao.maps.MapTypeId.SKYVIEW);
      }
    },
    changeMarker(type) {
      if (type === `intersection`) {
        this.classIf.map.intersection = !this.classIf.map.intersection;

        if (this.classIf.map.intersection) {
          this.setMarkers(interMarkers, map);
        } else {
          this.setMarkers(interMarkers, null);
        }
      } else if (type === `camera`) {
        this.classIf.map.camera = !this.classIf.map.camera;

        if (this.classIf.map.camera) {
          this.setMarkers(cameraMarkers, map);
        } else {
          this.setMarkers(cameraMarkers, null);
        }
      } else if (type === `avenue`) {
        this.classIf.map.avenue = !this.classIf.map.avenue;

        if (this.classIf.map.avenue) {
          this.setMarkers(avenueLine, map);
        } else {
          this.setMarkers(avenueLine, null);
        }
      } else if (type === `light`) {
        this.classIf.map.light = !this.classIf.map.light;

        if (this.classIf.map.light) {
          //this.setMarkers(avenueLine, map)
        } else {
          //this.setMarkers(avenueLine, null)
        }
      } else if (type === `its`) {
        this.classIf.map.its = !this.classIf.map.its;

        if (this.classIf.map.its) {
          //this.setMarkers(avenueLine, map)
        } else {
          //this.setMarkers(avenueLine, null)
        }
      }
    },

    setMarkers(markers = [], map) {
      markers.forEach((el) => {
        el.setMap(map);
      });
    },

    setTrafficOverlay(type) {
      let changeOverlay = "";
      if (type === "traffic") {
        this.classIf.overlay.traffic = !this.classIf.overlay.traffic;

        changeOverlay = kakao.maps.MapTypeId.TRAFFIC;
        if (this.classIf.overlay.traffic) {
          map.addOverlayMapTypeId(changeOverlay);
        } else {
          map.removeOverlayMapTypeId(changeOverlay);
        }
      } else if (type === "bicycle") {
        this.classIf.overlay.bicycle = !this.classIf.overlay.bicycle;

        changeOverlay = kakao.maps.MapTypeId.BICYCLE;
        if (this.classIf.overlay.bicycle) {
          map.addOverlayMapTypeId(changeOverlay);
        } else {
          map.removeOverlayMapTypeId(changeOverlay);
        }
      } else if (type === "roadview") {
        overlayOn = !overlayOn;
        this.classIf.overlay.roadview = !this.classIf.overlay.roadview;

        changeOverlay = kakao.maps.MapTypeId.ROADVIEW;
        if (this.classIf.overlay.roadview) {
          map.addOverlayMapTypeId(changeOverlay);

          //로드뷰 켜기
          let tempCenter = map.getCenter();
          window.setTimeout(() => {
            map.relayout();
            map.setCenter(tempCenter);
            roadViewMarker.setMap(map);
            roadViewMarker.setPosition(tempCenter);
            this.toggleRoadview(map.getCenter());
          }, 100);
        } else {
          map.removeOverlayMapTypeId(changeOverlay);
          let tempCenter = map.getCenter();
          window.setTimeout(() => {
            roadViewMarker.setMap(null);
            map.relayout();
            map.setCenter(tempCenter);
          }, 100);
        }
      } else if (type === "label") {
        this.classIf.overlay.label = !this.classIf.overlay.label;

        changeOverlay = kakao.maps.MapTypeId.OVERLAY;
        if (this.classIf.overlay.label) {
          map.addOverlayMapTypeId(changeOverlay);
        } else {
          map.removeOverlayMapTypeId(changeOverlay);
        }
      }
    },

    toggleRoadview(position) {
      roadViewClient.getNearestPanoId(position, 50, function (panoId) {
        if (panoId === null) {
          //toggleMapWrapper(true, position);
        } else {
          //toggleMapWrapper(false, position);
          roadView.setPanoId(panoId, position);
        }
      });
    },

    // viewAllModeWsConnect(chIndex) {
    // 	try {
    // 		if (IMG_WS_BUS.viewAllModeWs[chIndex].img === null) {
    // 			if (!this.GET_DEV_MODE)
    // 				IMG_WS_BUS.viewAllModeWsImgClient(chIndex) // 연결
    // 			else IMG_WS_BUS.viewAllModeWs[chIndex].img = `ㅋㅋㅋ`
    // 		}
    // 	} catch (e) {
    // 		console.log(e)
    // 	}
    // },

    // viewAllModeWsDisconnect(chIndex) {
    // 	try {
    // 		if (IMG_WS_BUS.viewAllModeWs[chIndex].img !== null) {
    // 			if (!this.GET_DEV_MODE)
    // 				IMG_WS_BUS.viewAllModeWs[chIndex].img.close() // 해제
    // 			else IMG_WS_BUS.viewAllModeWs[chIndex].img = null
    // 		}
    // 	} catch (e) {
    // 		console.log(e)
    // 	}
    // },

    changeCenter(e) {
      let currentintersection;
      let lat, lng;

      this.intersection.some((el) => {
        if (el.IntersectionSeq === e) {
          currentintersection = el;
          return true;
        }
      });

      lat = currentintersection["Latitude"];
      lng = currentintersection["Longitude"];

      map.setCenter(new kakao.maps.LatLng(lat, lng));
      map.setLevel(3);
    },
    changeSignalPhase(idx, phase) {
      for (let i in phaseMarkers[idx]) {
        phaseMarkers[idx][i].setMap(null);
      }

      phaseMarkers[idx][phase].setMap(map);
    },

    closeOverlay(idx) {
      interOverlays[idx].setMap(null);
    },
    closeOverlayCamera(idx) {
      // this.viewAllModeWsDisconnect(idx)
      cameraOverlays[idx].setMap(null);
    },
    getNodeLink() {
      axios
        .post(`/api/nodelink/all`, { Region: this.getHostName })
        .then((res) => {
          if (res.data.success) {
            let data = res.data.data;
            this.drawNodeLink(data);
            this.drawTrafficLight();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    drawNodeLink(data) {
      if (!signalLink[2]) signalLink[2] = { 1: [], 2: [], 3: [], 4: [] };
      if (!nodeLink[2]) nodeLink[2] = { 1: [], 2: [], 3: [], 4: [] };

      for (let i = 0; i < 5; i++) {
        nodeLink[2][1][i + 1] = [];
        nodeLink[2][2][i + 1] = [];
        nodeLink[2][3][i + 1] = [];
        nodeLink[2][4][i + 1] = [];
      }

      //link
      data[1].forEach((el) => {
        let linePath = [];
        el["geom2"][0].forEach((el, idx) => {
          linePath.push(new kakao.maps.LatLng(el["y"], el["x"]));
        });

        var polyline = new kakao.maps.Polyline({
          path: linePath,
          strokeWeight: 4,
          strokeColor: "#E2EFDA",
          strokeOpacity: 0.3,
          strokeStyle: "solid",
        });
        polyline.setMap(map);

        var infoContent = `<div style="background:#343a4090; padding:5px; border:1px solid #39a5d9; font-size:14px">link<br> gid : ${el["gid"]}<br> id : ${el["id"]}</div>`;
        var infowindow = new kakao.maps.CustomOverlay({
          content: infoContent,
          position: polyline.getPath()[0],
          //xAnchor: 0.5,
          yAnchor: 1.5,
        });

        kakao.maps.event.addListener(polyline, "mouseover", function (e) {
          infowindow.setPosition(e.latLng);
          infowindow.setMap(map);
        });

        kakao.maps.event.addListener(polyline, "mouseout", function () {
          infowindow.setMap(null);
        });

        if (el["id"] === "A2219W000137") signalLink[2][1][0] = polyline;
        if (el["id"] === "A2219W000213") signalLink[2][1][1] = polyline;
        if (el["id"] === "A2219W000215") signalLink[2][1][2] = polyline;
        if (el["id"] === "A2219W000217") signalLink[2][1][3] = polyline;
        if (el["id"] === "A2219W000216") signalLink[2][1][4] = polyline;
        if (el["id"] === "A2219W000214") signalLink[2][1][5] = polyline;
        if (el["id"] === "A2219W000140") signalLink[2][1][6] = polyline;

        if (el["id"] === "A2219W000138") signalLink[2][2][0] = polyline;
        if (el["id"] === "A2219W000158") signalLink[2][2][1] = polyline;
        if (el["id"] === "A2219W000297") signalLink[2][2][2] = polyline;
        if (el["id"] === "A2219W000161") signalLink[2][2][3] = polyline;
        if (el["id"] === "A2219W000160") signalLink[2][2][4] = polyline;
        if (el["id"] === "A2219W000159") signalLink[2][2][5] = polyline;
        if (el["id"] === "A2219W000028") signalLink[2][2][6] = polyline;

        if (el["id"] === "A2219W000136") signalLink[2][3][0] = polyline;
        if (el["id"] === "A2219W000151") signalLink[2][3][1] = polyline;
        if (el["id"] === "A2219W000296") signalLink[2][3][2] = polyline;
        if (el["id"] === "A2219W000229") signalLink[2][3][3] = polyline;
        if (el["id"] === "A2219W000228") signalLink[2][3][4] = polyline;
        if (el["id"] === "A2219W000227") signalLink[2][3][5] = polyline;
        if (el["id"] === "A2219W000009") signalLink[2][3][6] = polyline;

        if (el["id"] === "A2219W000135") signalLink[2][4][0] = polyline;
        if (el["id"] === "A2219W000144") signalLink[2][4][1] = polyline;
        if (el["id"] === "A2219W000143") signalLink[2][4][2] = polyline;
        if (el["id"] === "A2219W000142") signalLink[2][4][3] = polyline;
        if (el["id"] === "A2219W000145") signalLink[2][4][4] = polyline;
        if (el["id"] === "A2219W000156") signalLink[2][4][5] = polyline;
        if (el["id"] === "A2219W000157") signalLink[2][4][6] = polyline;
        if (el["id"] === "A2219W000141") signalLink[2][4][7] = polyline;

        /////////////////////////////////////////////////////////////////////////////////
        if (el["id"] === "A2219W000222") nodeLink[2][1][1].push(polyline);
        if (el["id"] === "A2219W000221") nodeLink[2][1][2].push(polyline);
        if (el["id"] === "A2219W000220") nodeLink[2][1][3].push(polyline);
        if (el["id"] === "A2219W000219") nodeLink[2][1][4].push(polyline);
        if (el["id"] === "A2219W000218") nodeLink[2][1][5].push(polyline);

        if (el["id"] === "A2219W000162") nodeLink[2][2][1].push(polyline);
        if (el["id"] === "A2219W000163") nodeLink[2][2][2].push(polyline);
        if (el["id"] === "A2219W000164") nodeLink[2][2][3].push(polyline);
        if (el["id"] === "A2219W000165") nodeLink[2][2][4].push(polyline);
        if (el["id"] === "A2219W000166") nodeLink[2][2][5].push(polyline);

        if (el["id"] === "A221AW003466") nodeLink[2][3][1].push(polyline);
        if (el["id"] === "A221AW003465") nodeLink[2][3][2].push(polyline);
        if (el["id"] === "A221AW003464") nodeLink[2][3][3].push(polyline);
        if (el["id"] === "A221AW003463") nodeLink[2][3][4].push(polyline);
        if (el["id"] === "A221AW003462") nodeLink[2][3][5].push(polyline);

        if (el["id"] === "A2219W000150") nodeLink[2][4][1].push(polyline);
        if (el["id"] === "A2219W000149") nodeLink[2][4][2].push(polyline);
        if (el["id"] === "A2219W000148") nodeLink[2][4][3].push(polyline);
        if (el["id"] === "A2219W000147") nodeLink[2][4][4].push(polyline);
        if (el["id"] === "A2219W000146") nodeLink[2][4][5].push(polyline);
        /////////////////////////////////////////////////////////////////////////////////
        if (el["id"] === "A2219W000023") nodeLink[2][1][1].push(polyline);
        if (el["id"] === "A2219W000022") nodeLink[2][1][2].push(polyline);
        if (el["id"] === "A2219W000021") nodeLink[2][1][3].push(polyline);
        if (el["id"] === "A2219W000020") nodeLink[2][1][4].push(polyline);
        if (el["id"] === "A2219W000019") nodeLink[2][1][5].push(polyline);

        if (el["id"] === "A2219W000014") nodeLink[2][2][1].push(polyline);
        if (el["id"] === "A2219W000015") nodeLink[2][2][2].push(polyline);
        if (el["id"] === "A2219W000016") nodeLink[2][2][3].push(polyline);
        if (el["id"] === "A2219W000017") nodeLink[2][2][4].push(polyline);
        if (el["id"] === "A2219W000018") nodeLink[2][2][5].push(polyline);

        if (el["id"] === "A221AW003455") nodeLink[2][3][1].push(polyline);
        if (el["id"] === "A221AW003473") nodeLink[2][3][2].push(polyline);
        if (el["id"] === "A221AW003474") nodeLink[2][3][3].push(polyline);
        if (el["id"] === "A221AW003475") nodeLink[2][3][4].push(polyline);
        if (el["id"] === "A221AW003476") nodeLink[2][3][5].push(polyline);

        if (el["id"] === "A2219W000005") nodeLink[2][4][1].push(polyline);
        if (el["id"] === "A2219W000004") nodeLink[2][4][2].push(polyline);
        if (el["id"] === "A2219W000003") nodeLink[2][4][3].push(polyline);
        if (el["id"] === "A2219W000002") nodeLink[2][4][4].push(polyline);
        if (el["id"] === "A2219W000001") nodeLink[2][4][5].push(polyline);
        // /////////////////////////////////////////////////////////////////////////////////
        if (el["id"] === "A2219W000033") nodeLink[2][1][1].push(polyline);
        if (el["id"] === "A2219W000032") nodeLink[2][1][2].push(polyline);
        if (el["id"] === "A2219W000031") nodeLink[2][1][3].push(polyline);
        if (el["id"] === "A2219W000030") nodeLink[2][1][4].push(polyline);
        if (el["id"] === "A2219W000029") nodeLink[2][1][5].push(polyline);

        if (el["id"] === "A221AW001344") nodeLink[2][2][1].push(polyline);
        if (el["id"] === "A221AW001345") nodeLink[2][2][2].push(polyline);
        if (el["id"] === "A221AW001346") nodeLink[2][2][3].push(polyline);
        if (el["id"] === "A221AW001347") nodeLink[2][2][4].push(polyline);
        if (el["id"] === "A221AW001351") nodeLink[2][2][5].push(polyline);

        if (el["id"] === "A221AW003454") nodeLink[2][3][2].push(polyline);
        if (el["id"] === "A221AW003453") nodeLink[2][3][3].push(polyline);
        if (el["id"] === "A221AW003452") nodeLink[2][3][4].push(polyline);
        if (el["id"] === "A221AW003451") nodeLink[2][3][5].push(polyline);

        if (el["id"] === "A221AW001332") nodeLink[2][4][1].push(polyline);
        if (el["id"] === "A221AW001329") nodeLink[2][4][2].push(polyline);
        if (el["id"] === "A221AW001327") nodeLink[2][4][3].push(polyline);
        if (el["id"] === "A221AW001324") nodeLink[2][4][4].push(polyline);
        if (el["id"] === "A221AW001320") nodeLink[2][4][5].push(polyline);
      });

      //node
      data[0].forEach((el) => {
        let linePath = [];
        linePath.push(
          new kakao.maps.LatLng(el["geom2"]["y"], el["geom2"]["x"])
        );

        var circle = new kakao.maps.Circle({
          center: linePath[0],
          radius: 0.5,
          strokeWeight: 0.5,
          strokeColor: "#ff7a00",
          strokeOpacity: 1,
          strokeStyle: "solid",
          fillColor: "#ff7a00",
          fillOpacity: 0.5,
          zIndex: 100,
        });
        circle.setMap(map);

        var infoContent = `<div style="background:#343a4090; padding:5px; border:1px solid #39a5d9; font-size:14px">node<br> gid : ${el["gid"]}<br> id : ${el["id"]}</div>`;
        var infowindow = new kakao.maps.CustomOverlay({
          content: infoContent,
          position: circle.getPosition(),
          //xAnchor: 0.5,
          yAnchor: 1.5,
        });

        kakao.maps.event.addListener(circle, "mouseover", function (e) {
          infowindow.setPosition(e.latLng);
          infowindow.setMap(map);
        });

        kakao.maps.event.addListener(circle, "mouseout", function () {
          infowindow.setMap(null);
        });
      });
    },
    drawTrafficLight() {
      let locationSet = [
        { latitude: 37.39223584380437, longitude: 126.95520729597519 },
        { latitude: 37.39248459274351, longitude: 126.9547907263513 },
        { latitude: 37.392833855043726, longitude: 126.95509542332918 },
        { latitude: 37.39257159342955, longitude: 126.95552188306853 },
      ];
      let rotate = [
        "rotate(157deg)",
        "rotate(248deg)",
        "rotate(337deg)",
        "rotate(67deg)",
      ];

      for (let i = 0; i < 4; i++) {
        let imageSrc = `/img/icon/signal/light-04.png`;
        let imageSize = new kakao.maps.Size(0, 0);
        let imageOption = new kakao.maps.Point(0 / 0);
        let markerImage = new kakao.maps.MarkerImage(
          imageSrc,
          imageSize,
          imageOption
        );
        let trafficLight = new kakao.maps.Marker({
          position: new kakao.maps.LatLng(
            locationSet[i].latitude,
            locationSet[i].longitude
          ),
          image: markerImage,
          clickable: true,
        });
        trafficLight.a.children[0].style.transform = rotate[i];
        trafficLight.setMap(map);

        if (!trafficLights[2]) trafficLights[2] = {};
        trafficLights[2][i + 1] = trafficLight;
      }
    },
    changeSignalLink(idx, phase) {
      //초기화
      // let defaultOption = {
      //   endArrow : false,
      //   strokeWeight: 4,
      //   strokeColor: '#E2EFDA',
      //   strokeOpacity: 0.3,
      //   strokeStyle: 'solid'
      // }
      // for(var approch in signalLink[idx]){
      //   signalLink[idx][approch].forEach(el => {
      //     if(el !== undefined){
      //       el.setOptions(defaultOption)
      //     }

      //   })
      // }
      if (signalInterval[idx] !== null) {
        window.clearInterval(signalInterval[idx]);
        signalInterval[idx] = null;
        signalPlayLink[idx].forEach((el) => {
          el.setMap(null);
        });
        signalPlayLink[idx] = [];
        signalActiveLink[idx] = [];
      }

      let cnt = 1;
      // let option = {
      // 	endArrow: true,
      // 	strokeWeight: 4,
      // 	strokeColor: '#00ffef',
      // 	strokeOpacity: 1,
      // 	strokeStyle: 'solid',
      // 	zIndex: 10,
      // }

      if (phase === 1) {
        signalActiveLink[idx].push(signalLink[idx][4][0]);
        signalActiveLink[idx].push(signalLink[idx][4][1]);
        signalActiveLink[idx].push(signalLink[idx][4][2]);
        signalActiveLink[idx].push(signalLink[idx][4][3]);
        signalActiveLink[idx].push(signalLink[idx][4][4]);
        signalActiveLink[idx].push(signalLink[idx][4][5]);
        signalActiveLink[idx].push(signalLink[idx][4][6]);

        signalActiveLink[idx].push(signalLink[idx][1][6]);
        signalActiveLink[idx].push(signalLink[idx][2][6]);
      } else if (phase === 2) {
        signalActiveLink[idx].push(signalLink[idx][2][3]);
        signalActiveLink[idx].push(signalLink[idx][2][4]);
        signalActiveLink[idx].push(signalLink[idx][2][5]);
        signalActiveLink[idx].push(signalLink[idx][4][3]);
        signalActiveLink[idx].push(signalLink[idx][4][4]);
        signalActiveLink[idx].push(signalLink[idx][4][5]);
        signalActiveLink[idx].push(signalLink[idx][4][6]);
      } else if (phase === 3) {
        signalActiveLink[idx].push(signalLink[idx][2][0]);
        signalActiveLink[idx].push(signalLink[idx][2][1]);
        signalActiveLink[idx].push(signalLink[idx][2][2]);
        signalActiveLink[idx].push(signalLink[idx][2][3]);
        signalActiveLink[idx].push(signalLink[idx][2][4]);
        signalActiveLink[idx].push(signalLink[idx][2][5]);

        signalActiveLink[idx].push(signalLink[idx][3][6]);
        signalActiveLink[idx].push(signalLink[idx][4][7]);
      } else if (phase === 4) {
        signalActiveLink[idx].push(signalLink[idx][1][3]);
        signalActiveLink[idx].push(signalLink[idx][1][4]);
        signalActiveLink[idx].push(signalLink[idx][1][5]);
        signalActiveLink[idx].push(signalLink[idx][3][3]);
        signalActiveLink[idx].push(signalLink[idx][3][4]);
        signalActiveLink[idx].push(signalLink[idx][3][5]);
      } else if (phase === 5) {
        signalActiveLink[idx].push(signalLink[idx][1][0]);
        signalActiveLink[idx].push(signalLink[idx][1][1]);
        signalActiveLink[idx].push(signalLink[idx][1][2]);
        signalActiveLink[idx].push(signalLink[idx][3][1]);
        signalActiveLink[idx].push(signalLink[idx][3][2]);

        signalActiveLink[idx].push(signalLink[idx][1][6]);
        signalActiveLink[idx].push(signalLink[idx][2][6]);
        signalActiveLink[idx].push(signalLink[idx][3][6]);
        signalActiveLink[idx].push(signalLink[idx][4][7]);
      }

      signalActiveLink[idx].forEach((el) => {
        let path = el
          .getPath()
          .slice(0, Math.round(el.getPath().length / 15) * cnt);
        var polyline = new kakao.maps.Polyline({
          path: path,
          endArrow: true,
          strokeWeight: 4,
          strokeColor: "#00ffef",
          strokeOpacity: 1,
          strokeStyle: "solid",
          zIndex: 10,
        });
        polyline.setMap(map);
        signalPlayLink[idx].push(polyline);
      });
      cnt++;

      signalInterval[idx] = window.setInterval(() => {
        signalPlayLink[idx].forEach((el, idx2) => {
          el.setPath(
            signalActiveLink[idx][idx2]
              .getPath()
              .slice(
                0,
                Math.round(signalActiveLink[idx][idx2].getPath().length / 15) *
                  cnt
              )
          );
        });

        if (cnt === 15) {
          cnt = 1;
        } else {
          cnt++;
        }
      }, 300);
    },
    changeNodeLink(idx, state) {
      let commonOption = {
        strokeWeight: 5,
        strokeColor: "#00B050",
        strokeOpacity: 1,
        strokeStyle: "solid",
      };

      state.forEach((approch) => {
        if (approch.Channel === 0) {
          approch.Lane.forEach((lane) => {
            if (lane.Occupancy > 60) commonOption.strokeColor = "#FF412D";
            else if (lane.Occupancy > 40) commonOption.strokeColor = "#FFDA3B";
            else if (lane.Occupancy > 20) commonOption.strokeColor = "#FFFF21";
            else commonOption.strokeColor = "#00D255";
            nodeLink[idx][3][lane.Number].forEach((el) => {
              el.setOptions(commonOption);
            });
          });
        } else if (approch.Channel === 1) {
          approch.Lane.forEach((lane) => {
            if (lane.Occupancy > 60) commonOption.strokeColor = "#FF412D";
            else if (lane.Occupancy > 40) commonOption.strokeColor = "#FFDA3B";
            else if (lane.Occupancy > 20) commonOption.strokeColor = "#FFFF21";
            else commonOption.strokeColor = "#00D255";
            nodeLink[idx][1][lane.Number].forEach((el) => {
              el.setOptions(commonOption);
            });
          });
        } else if (approch.Channel === 2) {
          approch.Lane.forEach((lane) => {
            if (lane.Occupancy > 60) commonOption.strokeColor = "#FF412D";
            else if (lane.Occupancy > 40) commonOption.strokeColor = "#FFDA3B";
            else if (lane.Occupancy > 20) commonOption.strokeColor = "#FFFF21";
            else commonOption.strokeColor = "#00D255";
            nodeLink[idx][4][lane.Number].forEach((el) => {
              el.setOptions(commonOption);
            });
          });
        } else if (approch.Channel === 3) {
          approch.Lane.forEach((lane) => {
            if (lane.Occupancy > 60) commonOption.strokeColor = "#FF412D";
            else if (lane.Occupancy > 40) commonOption.strokeColor = "#FFDA3B";
            else if (lane.Occupancy > 20) commonOption.strokeColor = "#FFFF21";
            else commonOption.strokeColor = "#00D255";
            nodeLink[idx][2][lane.Number].forEach((el) => {
              el.setOptions(commonOption);
            });
          });
        }
      });
    },
    changeTrafficLight(idx, phase) {
      var level = map.getLevel();

      let width = 0;
      let height = 0;

      switch (level) {
        case 0:
          width = 76;
          height = 21;
          break;
        case 1:
          width = 76;
          height = 21;
          break;
        case 2:
          width = 51;
          height = 14;
          break;
        case 3:
          width = 51;
          height = 14;
          break;
        case 4:
          width = 25;
          height = 7;
          break;
        default:
          width = 0;
          height = 0;
      }

      let imageSrc1 = `/img/icon/signal/light-00.png`;
      let imageSrc2 = `/img/icon/signal/light-01.png`;
      let imageSrc3 = `/img/icon/signal/light-02.png`;
      let imageSrc4 = `/img/icon/signal/light-03.png`;
      let imageSrc5 = `/img/icon/signal/light-04.png`;
      let imageSrc6 = `/img/icon/signal/light-05.png`;
      let imageSize = new kakao.maps.Size(width, height);
      let imageOption = new kakao.maps.Point(width / 2, height / 2);
      let markerImage1 = new kakao.maps.MarkerImage(
        imageSrc1,
        imageSize,
        imageOption
      );
      let markerImage2 = new kakao.maps.MarkerImage(
        imageSrc2,
        imageSize,
        imageOption
      );
      let markerImage3 = new kakao.maps.MarkerImage(
        imageSrc3,
        imageSize,
        imageOption
      );
      let markerImage4 = new kakao.maps.MarkerImage(
        imageSrc4,
        imageSize,
        imageOption
      );
      let markerImage5 = new kakao.maps.MarkerImage(
        imageSrc5,
        imageSize,
        imageOption
      );
      let markerImage6 = new kakao.maps.MarkerImage(
        imageSrc6,
        imageSize,
        imageOption
      );

      if (phase === 1) {
        trafficLights[idx][1].setImage(markerImage5);
        trafficLights[idx][3].setImage(markerImage5);

        window.setTimeout(() => {
          trafficLights[idx][1].setImage(markerImage1);
          trafficLights[idx][2].setImage(markerImage1);
          trafficLights[idx][3].setImage(markerImage1);
          trafficLights[idx][4].setImage(markerImage3);
          this.changeSignalLink(idx, phase);
        }, 3000);
      } else if (phase === 2) {
        trafficLights[idx][4].setImage(markerImage6);

        window.setTimeout(() => {
          trafficLights[idx][1].setImage(markerImage1);
          trafficLights[idx][2].setImage(markerImage2);
          trafficLights[idx][3].setImage(markerImage1);
          trafficLights[idx][4].setImage(markerImage2);
          this.changeSignalLink(idx, phase);
        }, 3000);
      } else if (phase === 3) {
        trafficLights[idx][4].setImage(markerImage5);

        window.setTimeout(() => {
          trafficLights[idx][1].setImage(markerImage1);
          trafficLights[idx][2].setImage(markerImage3);
          trafficLights[idx][3].setImage(markerImage1);
          trafficLights[idx][4].setImage(markerImage1);
          this.changeSignalLink(idx, phase);
        }, 3000);
      } else if (phase === 4) {
        trafficLights[idx][2].setImage(markerImage5);

        window.setTimeout(() => {
          trafficLights[idx][1].setImage(markerImage2);
          trafficLights[idx][2].setImage(markerImage1);
          trafficLights[idx][3].setImage(markerImage2);
          trafficLights[idx][4].setImage(markerImage1);
          this.changeSignalLink(idx, phase);
        }, 3000);
      } else if (phase === 5) {
        trafficLights[idx][1].setImage(markerImage5);
        trafficLights[idx][3].setImage(markerImage5);

        window.setTimeout(() => {
          trafficLights[idx][1].setImage(markerImage4);
          trafficLights[idx][2].setImage(markerImage1);
          trafficLights[idx][3].setImage(markerImage4);
          trafficLights[idx][4].setImage(markerImage1);
          this.changeSignalLink(idx, phase);
        }, 3000);
      }
    },
    digitalTwinTest(data) {
      if (data.length > 0 && data[0].Vehicle && data[0].Vehicle.State) {
        data[0].Vehicle.State.forEach((el) => {
          twinObject.forEach((temp) => {
            temp.setMap(null);
          });
          twinObject = [];
          el.GPS.forEach((item) => {
            var marker = new kakao.maps.Marker({
              position: new kakao.maps.LatLng(item.Real[0], item.Real[1]),
              map: map,
            });
            twinObject.push(marker);
          });
        });
      }
    },
  },
  watch: {
    intersection: function (e) {
      let flag = false;
      const avenueSeq = "";
      getAvenueListAPI(avenueSeq).then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          this.avenue = data;
          flag = true;
          this.addAvenue(this.avenue);
        }
      });

      getTrafficGisAPI().then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          let vueF = this;
          let intervelHandler = window.setInterval(function () {
            if (flag) {
              data[0].forEach((el) => {
                vueF.avenue.some((x) => {
                  if (el.AvenueSeq === x.AvenueSeq) {
                    x.traffic = x.traffic || [];
                    x.traffic.push({
                      avenueName: el.AvenueName,
                      direction: el.StaticDirection,
                      type: el.DirectionType,
                      value: el.Value,
                    });
                    return true;
                  }
                });
              });

              data[1].forEach((el) => {
                vueF.intersection.some((x) => {
                  if (el.IntersectionSeq === x.IntersectionSeq) {
                    x.Phase = x.Phase || {};
                    x.Phase[el.PhasePattern] = el;
                    return true;
                  }
                });
              });
              vueF.addIntersection(vueF.intersection);
              clearInterval(intervelHandler);
            }
          }, 100);
        }
      });
    },

    // GET_VIDEO_SOURCE(protocol) {
    //   let ch = 0
    //   this.avenue.some((el,idx) => {
    //     if(el.CameraIP === this.cameraIP){
    //       ch = idx
    //       return true
    //     }
    //   })
    //   this.channel.items = []
    //   protocol.forEach((el,idx) =>{
    //     this.channel.items[idx]={
    //       img: null,
    //       ip: IMG_WS_BUS.ws.ip,
    //       port: String(9200 + el.Channel) ,
    //       reconnection: true
    //     }

    //     if(el.Address.indexOf(this.cameraIP) !== -1){
    //       if(!IMG_WS_BUS.viewAllModeWs[ch] || IMG_WS_BUS.viewAllModeWs[ch].img === null){
    //         IMG_WS_BUS.viewAllModeWs[ch] = {...this.channel.items[idx]}
    //         this.viewAllModeWsConnect(ch)
    //       }
    //     }
    //   })
    // }
  },

  destroyed() {},
};
</script>

<style>
#mapwrap {
  position: relative;
  overflow: hidden;
}
.dContainer {
  position: relative;
}
.dContainer.active > div {
  width: 50%;
  height: 100%;
}
.droadview {
  width: 50%;
  height: 300px;
  top: 0;
  right: 0;
  position: absolute;
  z-index: 0;
}
.dmap .radius_border {
  border: 1px solid #919191;
  border-radius: 5px;
}
.dmap .custom_typecontrol {
  position: absolute;
  top: 3px;
  right: 65px;
  overflow: hidden;
  width: 132px;
  height: 30px;
  margin: 0;
  padding: 0;
  z-index: 1;
  font-size: 12px;
  font-family: "Malgun Gothic", "맑은 고딕", sans-serif;
}
.dmap .custom_typecontrol span {
  display: block;
  width: 65px;
  height: 30px;
  float: left;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  color: black;
  background: #fff;
}
.dmap .custom_typecontrol .active {
  color: #fff;
  background: #39a5d9;
}
.dmap .custom_typecontrol .active:hover {
  color: #009fe3;
  color: #f5f5f5;
}
.dmap .category,
.category * {
  margin: 0;
  padding: 0;
  color: #000;
}
.dmap .category {
  position: absolute;
  overflow: hidden;
  top: 10px;
  left: 250px;
  width: fit-content;
  height: 50px;
  z-index: 10;
  border: 1px solid black;
  font-family: "Malgun Gothic", "맑은 고딕", sans-serif;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
}
.dmap .category2 {
  position: absolute;
  overflow: hidden;
  top: 10px;
  left: 550px;
  width: 202px;
}
.dmap .category .active {
  background: #39a5d9;
  color: #fff;
  border-left: 1px solid #8b959a;
  border-right: 1px solid #8b959a;
  width: 50px;
}
.dmap .category ul {
  padding: 0;
}
.dmap .category li {
  list-style: none;
  float: left;
  width: 50px;
  height: 48px;
  padding-top: 5px;
  cursor: pointer;
}
.dmap .category2 li {
  list-style: none;
  float: left;
}
.dmap .category .ico_comm {
  display: block;
  margin: 0 auto 2px;
  width: 24px;
  height: 24px;
}
.dmap .category .ico_coffee {
  background-position: -10px 0;
  background: url("../../../public/img/icon/gisInter.png") no-repeat;
}
.dmap .category .ico_store {
  background-position: -10px -36px;
  background: url("../../../public/img/icon/gisCamera.png") no-repeat;
}
.dmap .category .ico_carpark {
  background-position: -10px -72px;
  background: url("../../../public/img/icon/gisAvenue.png") no-repeat;
}
.dmap .category .ico_light {
  background-position: -10px -72px;
  background: url("../../../public/img/icon/gisTrafficLight.png") no-repeat;
}
.dmap .category .ico_its {
  background-position: -10px -72px;
  background: url("../../../public/img/icon/gisIts.png") no-repeat;
}
.dmap .category .ico_traffic {
  background-position: -10px -72px;
  background: url("../../../public/img/icon/gisTraffic.png") no-repeat;
}
.dmap .category .ico_bicycle {
  background-position: -10px -72px;
  background: url("../../../public/img/icon/gisBicycle.png") no-repeat;
}
.dmap .category .ico_roadview {
  background-position: -10px -72px;
  background: url("../../../public/img/icon/gisRoadview.png") no-repeat;
}
.dmap .category .ico_label {
  background-position: -10px -72px;
  background: url("../../../public/img/icon/gisLabel.png") no-repeat;
}
.dmap .legend {
  position: absolute;
  overflow: hidden;
  top: 10px;
  left: 800px;
  width: 300px;
  height: 50px;
  z-index: 10;
  border: 1px solid black;
  font-family: "Malgun Gothic", "맑은 고딕", sans-serif;
  text-align: center;
}
.dmap .legend table {
  width: 100%;
  color: #fff;
  font-size: 9px;
  background-color: #343a4070;
}
.dmap .legend table tr {
  font-size: 8px;
  background: none;
}
.dmap .legend table th,
.dmap .legend table td {
  padding: 3px;
  border: none;
}
.dmap .legend .box {
  width: 100%;
  height: 10px;
  display: block;
}
.dmap .legend .color1 {
  background-color: #e2efda;
}
.dmap .legend .color2 {
  background-color: #a9d08e;
}
.dmap .legend .color3 {
  background-color: #00b050;
}
.dmap .legend .color4 {
  background-color: #ffff00;
}
.dmap .legend .color5 {
  background-color: #ffc000;
}
.dmap .legend .color6 {
  background-color: #ff0000;
}
.dmap .legend .color7 {
  background-color: #ff0000;
}
.dmap .legend .color8 {
  background-color: #ff0000;
}
.dmap .playerBox {
  display: inline-block;
  background-color: #000;
  position: relative;
  border: 2px #009fe3 solid; /* box-shadow: 0 0 10px rgb(160 206 78); */
  box-shadow: 0px 0px 10px rgb(0 0 0);
  margin: 8px;
}
.dmap .label table tr td {
  border: none;
  padding: 0;
}
#map > div:first-child > div > div:last-child > div {
  background: none !important;
}
</style>
