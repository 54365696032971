import axios from "axios";
import router from "../../router";
import { encryptValue } from "../../../public/js/encrypt";

const loginStore = {
  namespaced: true,
  state: {
    url: `/api/traffic/user`,
    userAuth: 0,
  },
  getters: {
    getUserAuth: (state) => {
      return state.userAuth;
    },
  },
  mutations: {
    setUserAuth: (state, payload) => {
      state.userAuth = payload;
    },
  },
  actions: {
    changeUserAuth({ commit }, payload) {
      commit("setUserAuth", payload);
    },
    AC_TOKEN_CHECK({ state }, payload) {
      // const userid = sessionStorage.getItem(`access_id`) ?? ''
      const accesstoken = sessionStorage.getItem(`accesstoken`) ?? "";
      const standardtoken = sessionStorage.getItem(`standardtoken`) ?? "";
      const menuLevel = payload.menuLevel
        ? encryptValue(payload.menuLevel)
        : "";
      const region = payload.Region ? payload.Region : "tmaas";
      return axios
        .post(
          state.url,
          { menuLevel: menuLevel, Region: region },
          {
            headers: {
              accesstoken,
              standardtoken,
            },
          }
        )
        .then((r) => {
          switch (r.status) {
            case 262:
              alert("사용시간이 만료되었습니다.");
              break;
            case 265:
              alert("중복로그인이 감지되었습니다.");
              break;
          }
          return r.data;
        })
        .catch((e) => {
          console.log(e);
          axios
            .post("/api/traffic/user/saveErrorLog", {
              Region: region,
              errorLog: e.message,
            })
            .then(() => {
              router.push("/error");
            });
        });
    },

    AC_LOGIN({ state }, payload) {
      const accesstoken = sessionStorage.getItem(`accesstoken`) ?? "";
      const standardtoken = sessionStorage.getItem(`standardtoken`) ?? "";
      return axios
        .post(
          `${state.url}/login`,
          { ...payload },
          {
            headers: {
              accesstoken,
              standardtoken,
            },
          }
        )
        .then((r) => {
          if (r.data.success) {
            sessionStorage.setItem(`access_id`, payload.user.userid);
            sessionStorage.setItem(`accesstoken`, r.data.accesstoken);
            sessionStorage.setItem(`standardtoken`, r.data.standardtoken);
            state.userAuth = r.data.userAuth;
            if (r.status === 222) {
              return r;
            }
            return true;
          }

          switch (r.status) {
            case 223:
              return r;
            case 221:
              return r;
            default:
              return false;
          }
        })
        .catch((e) => {
          console.log("inner login error");
          axios
            .post("/api/traffic/user/saveErrorLog", {
              Region: payload.Region ? payload.Region : "tmaas",
              errorLog: e.message,
            })
            .then(() => {
              router.push("/error");
            });
        });
    },

    AC_LOGOUT() {
      return sessionStorage.removeItem(`access_token`);
    },
  },
};

export default loginStore;
