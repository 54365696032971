<template>
	<div class="select-wrap">
		<div class="select-box" v-for="data in tableData" :key="data.id">
			<div class="select-box__header">
				<div
					:class="[
						data.status === 'Available'
							? 'select-box__circle available'
							: 'select-box__circle inUsed',
					]"
				></div>
				{{ data.volume }} GB
			</div>
			<div class="select-box__body">
				<span class="select-box__price">₩ {{ data.price | comma }}</span>
				<!-- <span>per / month</span>s -->
			</div>
			<div class="select-box__btnwrap">
				<button
					@click="() => selectBtnHandler(data.volume, data.price)"
					:class="[
						data.status === 'Available'
							? 'manage-status metering-status-change'
							: 'manage-status metering-status-used',
					]"
					:disabled="data.status === 'Available' ? false : true"
				>
					{{ data.status }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		tableData: {
			type: Object,
		},
	},
	data() {
		return {}
	},
	methods: {
		selectBtnHandler(volume, price) {
			this.$emit('priceDialogHandler', volume, price)
		},
	},
}
</script>
