<template>
	<div class="all"></div>
</template>
<script>
export default {
	data() {
		return {
			//menu: false
		}
	},
	created() {},

	components: {},
}
</script>
<style>
.all p {
	width: 1000px;
	height: 500px;
	display: inline-block;
}
</style>
