<template>
	<div class="">
		<v-dialog max-width="520" v-model="isDialog" no-click-animation persistent>
			<DialogComponent
				@eventHandler="updatePassword"
				@closehandler="dialogCloseHandler"
				:dialogOption="dialogOption"
				:quaterCheck="this.getQuaterCheck"
				:submitMode="dialogSubmit"
			/>
		</v-dialog>
		<v-overlay :value="GET_PROGRESS_SHOW">
			<v-progress-circular
				:size="150"
				:width="8"
				indeterminate
				color="primary accent-3"
				><span class="text-h5 white--text"></span>
			</v-progress-circular>
		</v-overlay>
		<div class="header" v-if="this.menu">
			<h1 class="logo">
				<router-link to="/"
					><img
						:class="[
							getHostName === 'tmaas' || getHostName === 'localhost'
								? 'basic-logo'
								: '',
						]"
						:src="logoSrc"
				/></router-link>
			</h1>
			<div class="top">
				<div class="user">
					<p>
						Welcome
						<span @click="userModify(userID, 'current')"
							>{{ this.userID
							}}<userInfo
								:user="user"
								:config="config"
								@init-user="userCloseHandler"
								:isSelf="true"
							></userInfo
						></span>
					</p>
					<a @click="logOut" class="btnStyle01 wg btnLogOut">Logout</a>
					<br />
					<p class="getTime">
						<v-icon dark small>mdi-alarm {{ currentTime }}</v-icon>
						{{ currentTime }}
					</p>
				</div>
			</div>
			<div class="gnbArea">
				<ul class="gnb">
					<li class="on" v-if="this.getHostName !== 'tmaas'">
						<router-link to="/AllIntersection">
							<span class="ico icon1"></span>
							<span>교통정보 분석</span>
						</router-link>
						<ul class="depth2">
							<li>
								<router-link to="/AllIntersection"
									>전체 교차로 분석</router-link
								>
							</li>
							<li>
								<router-link to="/Intersection">개별 교차로 분석</router-link>
							</li>
							<li><router-link to="/Avenue">접근로별 분석</router-link></li>
							<!-- <li><router-link to="/TimeSeries">시계열 분석</router-link></li> -->
							<li>
								<router-link to="/CompareIntersection"
									>교차로 비교 분석</router-link
								>
							</li>
							<li>
								<router-link to="/CompareAvenue">접근로 비교 분석</router-link>
							</li>
							<li>
                <router-link to="/signReport">전후 영향 분석</router-link>
              </li>
							<li>
                <router-link to="/dateReport">월간 리포트</router-link>
              </li>
						</ul>
					</li>
					<li v-if="this.getHostName !== 'tmaas'">
						<!-- <router-link to="/Camera"> -->
						<router-link to="/EtcInfo">
							<span class="ico icon3"></span>
							<span>LOS 분석</span>
						</router-link>
						<ul class="depth2">
							<li>
								<router-link to="/EtcInfo"
									>교통량보정/신호정보/도로정보</router-link
								>
							</li>
						</ul>
					</li>
					<li>
						<router-link
							:to="userLevel >= 100 ? '/Users' : '/SystemInfo'"
							v-if="userLevel >= 10"
						>
							<span class="ico icon4"></span>
							<span>시스템 관리</span>
						</router-link>
						<ul class="depth2">
							<li v-if="userLevel >= 10">
								<router-link to="/Users">운영자 관리</router-link>
							</li>
							<!-- <li v-if="this.getHostName !== 'tmaas'">
								<router-link to="/Setting">교차로/접근로 관리</router-link>
							</li> -->
							<li v-if="this.getHostName !== 'tmaas'">
								<router-link to="/SystemInfo">장비 상태정보</router-link>
							</li>
							<li v-if="this.getHostName !== 'tmaas'">
								<router-link to="/SystemEvent">시스템 이벤트</router-link>
							</li>
							<li v-if="this.getHostName !== 'tmaas'">
								<router-link to="/EventInfo">돌발검지 이벤트</router-link>
							</li>
							<li v-if="userLevel >= 100 && this.getHostName !== 'tmaas'">
								<router-link to="/meteringUser">월 사용량</router-link>
							</li>
							<li
								v-if="
									userLevel >= 100 &&
									(this.getHostName === 'tmaas' ||
										this.getHostName === 'localhost')
								"
							>
								<router-link to="/managemantView">지자체 사용량</router-link>
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
		<div class="wrap" style="">
			<div class="contents" style="">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
const globalStore = 'globalStore'
import userInfo from '@/components/Dialog/UserInfo.vue'
// import intersectionList from '@/components/Tree/IntersectionList.vue'
import { userInfoMixins } from '../mixins/userInfoMixins'
import { dialogMixins } from '../mixins/dialogMixins'
import DialogComponent from '../components/DialogComponent.vue'
import { encryptPassword } from '../../public/js/encrypt'
import { passwordUpdateAPI } from '../api/trafficAPI'

export default {
	mixins: [userInfoMixins, dialogMixins],
	data() {
		return {
			timeInter: null,
			userLevel: '',
			currentTime: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
				.toISOString()
				.replace('T', ' ')
				.substr(0, 19),
			menu: false,
			page: {
				totalRow: 0,
				pageBlock: 10,
				scale: 10,
				startPage: 1,
				totalPage: 1,
			},
			userLog: [],
		}
	},
	created() {
		let getNow = this
		this.timeInter = window.setInterval(function () {
			;(getNow.currentTime = new Date(
				Date.now() - new Date().getTimezoneOffset() * 60000,
			)
				.toISOString()
				.replace('T', ' ')
				.substr(0, 19)),
				10000
		})
	},
	mounted() {
		this.userLevel = this.getUserAuth
		this.menu = this.getMenu
		this.userID = sessionStorage.access_id
		this.init()
		this.getUserList()
		this.quaterCheckHandler()
		// this.getUserLog()
	},
	destroyed() {},
	components: {
		// intersectionList,
		userInfo,
		DialogComponent,
	},

	computed: {
		...mapGetters(globalStore, [
			`getMenu`,
			`GET_DIS_CARD`,
			`GET_PROGRESS_SHOW`,
			`getHostName`,
			'getQuaterCheck',
		]),
		...mapGetters('loginStore', ['getUserAuth']),
		logoSrc() {
			let pathName = ''
			if (this.getHostName === 'localhost' || this.getHostName === 'tmaas') {
				pathName = 'laonroad'
			} else {
				pathName = this.getHostName
			}

			const imgFullPath = require(`@/assets/logo/${pathName}-logo.png`)
			return imgFullPath
		},
	},
	methods: {
		selectIntersection(e) {
			let tas = window.open(`http://${e['ControllerIP']}:9300`, 'tas', '')

			if (this.userLevel >= 100) {
				window.setTimeout(function () {
					tas.postMessage('123', `http://${e['ControllerIP']}:9300`)
				}, 300)
			}
		},
		quaterCheckHandler() {
			if (this.getQuaterCheck.isQuater) {
				this.dialogSubmit = true
				this.dialogOption.mode = 'update'
				this.dialogOption.title = '비밀번호 변경'
				this.dialogOption.body =
					'안전한 서비스 사용을 위해 주기적으로 비밀번호를 변경해 주세요.'
				this.isDialog = true
			}
		},
		logOut() {
			sessionStorage.clear()
			this.$router.push('/login')
		},

		init() {
			// this.config.dialog.user = true
			this.config.mode.modify = false
		},
		updatePassword(modalParams) {
			this.initCheck()
			const apiParams = {
				userID: sessionStorage.getItem('access_id'),
				oldPassword: encryptPassword(modalParams.oldPassword),
				newPassword: encryptPassword(modalParams.newPassword),
				checkPassword: encryptPassword(modalParams.checkPassword),
			}
			passwordUpdateAPI(apiParams).then(res => {
				if (res.data.success) {
					this.changeDialogSuccess(true)
					return
				}

				switch (res.status) {
					case 220:
						this.dialogOption.validate = true
						break
					case 222:
						this.dialogOption.dupleCheck = true
						break
				}
			})
		},
	},
}
</script>
