<template>
  <div class="analysis">
    <p>접근로 비교 분석</p>
    <div class="leftPanel">
      <intersectionList
        @update="updateIntersection"
        @select="selectAvenue"
        :isCompareMode="true"
        :avenueSeq="48"
        :isChildrenMode="true"
      ></intersectionList>
    </div>
    <div class="midPanel">
      <div class="mTopPanel">
        <viewDataList
          title="비교 정보 선택"
          type="3"
          @select="selectAnalysis"
        ></viewDataList>
      </div>
      <div class="mBotPanel"></div>
    </div>
    <div class="rightPanel">
      <div class="searchPanel">
        <div class="fLeft">
          <span>조회 기간</span>
          <p>
            <v-menu
              v-model="config.datePicker.start"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                @input="config.datePicker.start = false"
              ></v-date-picker>
            </v-menu>
          </p>
          <p>
            <v-menu
              ref="menu"
              v-model="config.timePicker.start"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startTime"
                  prepend-icon="mdi-calendar-clock"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="startTime"
                @click:minute="$refs.menu.save(startTime)"
                ampm-in-title
                scrollable
              ></v-time-picker>
            </v-menu>
          </p>
          <p>~</p>
          <p>
            <v-menu
              v-model="config.datePicker.end"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                @input="config.datePicker.end = false"
              ></v-date-picker>
            </v-menu>
          </p>
          <p>
            <v-menu
              ref="menu2"
              v-model="config.timePicker.end"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endTime"
                  prepend-icon="mdi-calendar-clock"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="endTime"
                @click:minute="$refs.menu2.save(endTime)"
                ampm-in-title
                scrollable
              ></v-time-picker>
            </v-menu>
          </p>
          <a class="btnStyle01 wg searchBtn" @click="getData()">조회</a>
        </div>
      </div>
      <div class="chartPanel">
        <globalChart :chartData="this.chartData"></globalChart>
      </div>
    </div>
  </div>
</template>
<script>
import intersectionList from "@/components/Tree/IntersectionList.vue";
import viewDataList from "@/components/Tree/ViewDataList.vue";
import globalChart from "@/components/Chart/GlobalChart.vue";
import { mapGetters } from "vuex";
import { getIntersectionAnalysisAPI } from "../../api/index";

const globalStore = "globalStore";
export default {
  data() {
    return {
      startDate: "2023-11-16",
      endDate: "2023-11-16",
      startTime: "00:00",
      endTime: "23:59",
      config: {
        datePicker: {
          start: false,
          end: false,
        },
        timePicker: {
          start: false,
          end: false,
        },
      },
      intersection: [],
      analysis: 1,
      targetDate: "2021-01-21",
      chartData: {
        chartType: "barChart",
        data: [],
      },
      isFalse: true,
    };
  },
  components: {
    intersectionList,
    viewDataList,
    globalChart,
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(globalStore, ["getHostName"]),
  },
  watch: {
    analysis: function () {
      this.getData();
    },
  },
  methods: {
    getData(avenueSeq) {
      let path = "traffic";
      let url = "";
      let title = "교통량";
      let chartType = "barChart";
      let chartNum = 0;
      let isKeep = this.isFalse ? (this.isFalse = false) : true;
      let legend = "";
      let label = [];
      let unit = 3;

      this.intersection.forEach((el) => {
        el.Avenue.forEach((child) => {
          if (child.checked) {
            label.push(child.AvenueName);
          }
        });
      });

      switch (this.analysis) {
        case 1:
          url = "avenue/trafficByMovement";
          chartType = "barChart3";
          title = "회전 교통량";
          legend = ["직진", "좌회전", "우회전", "유턴"];
          unit = 4;
          break;
        case 2:
          url = "avenue/trafficByLane";
          chartType = "barChart3";
          title = "차로별 교통량";
          legend = [
            "1차선",
            "2차선",
            "3차선",
            "4차선",
            "5차선",
            "6차선",
            "7차선",
          ];
          unit = 4;
          break;
        case 3:
          url = "avenue/trafficByCarModel";
          chartType = "barChart3";
          title = "차종분류";
          legend = [
            "승용차",
            "버스",
            "소형트럭",
            "대형트럭",
            "특수차",
            "오토바이",
          ];
          unit = 4;
          break;
        case 4:
          path = "signal";
          url = "avenue/cycleData";
          chartType = "lineChart2";
          title = "초기 대기길이";
          break;
        case 5:
          path = "signal";
          url = "avenue/cycleData";
          chartType = "lineChart2";
          title = "종기 대기길이";
          break;
        case 6:
          path = "signal";
          url = "avenue/cycleData";
          chartType = "lineChart2";
          title = "점유율";
          break;
        case 7:
          url = "avenue/averageSpeed";
          chartType = "lineChart2";
          title = "속도";
          break;
        case 8:
          path = "los";
          url = "avenue/trafficByAvenueDelay";
          chartType = "stepLineChart2";
          title = "제어지체";
          break;
        case 9:
          path = "los";
          url = "avenue/trafficByAvenueDelay";
          chartType = "stepLineChart2";
          title = "서비스 수준";
          break;
      }

      isKeep = false;
      avenueSeq = "";
      this.intersection.forEach((el) => {
        el.Avenue.forEach((child) => {
          if (child.checked) {
            if (avenueSeq !== "") {
              avenueSeq += ",";
            }
            avenueSeq += child.AvenueSeq;
          }
        });
      });

      const apiParams = {
        avenueSeq: avenueSeq,
        unit: unit,
        startDate: `${this.startDate} ${this.startTime}`,
        endDate: `${this.endDate} ${this.endTime}:59`,
      };

      getIntersectionAnalysisAPI(path, url, apiParams)
        .then((res) => {
          if (res.data.success) {
            let data = res.data.data;
            this.chartData = {
              title: title,
              chartType: chartType,
              unit: unit,
              chartNum: chartNum,
              data: data,
              isKeep: isKeep,
              legend: legend,
              label: label,
            };
          }
        })
        .catch(function (error) {
          alert("error :", error);
        });
    },
    updateIntersection(e) {
      this.intersection = e;
      this.getData();
    },
    selectAvenue() {
      this.getData();
    },
    selectAnalysis(e) {
      this.analysis = e;
    },
  },
};
</script>
