<template>
	<v-card class="dialog-component__wrap" dark>
		<v-card-title>
			<span class="dialog-component__title">
				{{ dialogOption.title }}
			</span>
		</v-card-title>
		<v-card-text v-if="this.getDialogSuccess">
			<template v-if="this.dialogOption.mode === 'findId'">
				<p class="dialog-success__title">
					입력하신 정보와 일치하는 아이디입니다.
				</p>
				<div class="dialog-success__id">{{ dialogOption.body }}</div>
			</template>
			<template v-else>
				<lottie :width="140" class="lottie-loader" :options="options" />
				<div class="dialog-component__desc dialog-success">
					{{ successMessage }}
				</div>
			</template>
		</v-card-text>
		<v-card-text v-else>
			<div class="dialog-component__desc">
				{{ dialogOption.body }}
			</div>
			<template v-if="submitMode">
				<div class="dialog-component__body">
					<ul v-if="dialogOption.mode === 'update'">
						<li>
							<label for="old-password">기존 비밀번호</label>
							<input
								id="old-password"
								type="password"
								placeholder="기존 비밀번호를 입력해주세요."
								v-model="passwordValue.oldPassword"
							/>
						</li>
						<li>
							<label for="new-password">새로운 비밀번호</label>
							<input
								id="new-password"
								type="password"
								placeholder="새로운 비밀번호를 입력해주세요."
								v-model="passwordValue.newPassword"
							/>
							<p
								class="idCheck limit"
								v-if="
									!validateOption.pwValidate &&
									passwordValue.newPassword.length !== 0
								"
							>
								비밀번호는 연속되지 않은 대/소문자, 숫자, 특수문자를 포함해 10자
								이상이어야 합니다.
							</p>
						</li>
						<li>
							<label for="check-password">비밀번호 확인</label>
							<input
								id="check-password"
								type="password"
								placeholder="비밀번호를 다시 입력해주세요."
								v-model="passwordValue.passwordCheck"
							/>
							<p
								class="idCheck limit"
								v-if="
									!validateOption.rePwValidate &&
									passwordValue.passwordCheck.length !== 0
								"
							>
								비밀번호가 일치하지 않습니다.
							</p>
						</li>
					</ul>
					<ul
						v-else-if="
							dialogOption.mode === 'findPw' || dialogOption.mode === 'findId'
						"
					>
						<li v-if="dialogOption.mode === 'findPw'">
							<label for="user-id">ID</label>
							<input
								id="user-id"
								type="text"
								placeholder="아이디를 입력해주세요."
								v-model="findContentValue.userId"
							/>
						</li>
						<li v-else-if="dialogOption.mode === 'findId'">
							<label for="user-name">Name</label>
							<input
								id="user-name"
								type="text"
								placeholder="유저 명을 입력해주세요."
								v-model="findContentValue.userName"
							/>
						</li>
						<li>
							<label for="user-email">Email</label>
							<input
								id="user-email"
								type="text"
								placeholder="등록하신 Email을 입력해주세요."
								v-model="findContentValue.email"
							/>
						</li>
					</ul>
				</div>
				<div v-if="dialogOption.validate" class="dialog-component__duple">
					기존과 같은 비밀번호는 사용할 수 없습니다.
				</div>
				<div v-if="dialogOption.dupleCheck" class="dialog-component__duple">
					<span v-if="dialogOption.mode === 'update'"
						>기존 비밀번호를 다시 확인해주세요.</span
					>
					<span v-else-if="dialogOption.mode === 'findPw'"
						>ID 혹은 Email을 다시 확인해주세요.</span
					>
				</div>
				<div class="quater-checkbox__wrap" v-if="quaterCheck.isQuater">
					<input id="next-quater" type="checkbox" v-model="nextQuaterCheck" />
					<label for="next-quater"
						>{{ quaterCheck.nextDate }}일 후에 변경</label
					>
				</div>
			</template>
		</v-card-text>

		<v-card-actions class="dialog-component__actions">
			<v-btn color="amber" dark @click="closeBtnHandler"> Close </v-btn>
			<template v-if="submitMode && !getDialogSuccess">
				<v-btn color="success" :disabled="!disableChecker" @click="updateUser">
					Submit
				</v-btn>
			</template>
		</v-card-actions>
	</v-card>
</template>

<script>
import _ from 'lodash'
import { nextUpdateQuaterAPI } from '../api/trafficAPI/index'
import { mapGetters } from 'vuex'
import Lottie from 'vue-lottie/src/lottie.vue'
import animationData from '../assets/images/lottie/modalSuccess.json'

export default {
	name: 'DialogComponent',
	components: {
		lottie: Lottie,
	},
	props: {
		dialogOption: {
			type: Object,
			default() {
				return {
					title: '',
					body: '',
					validate: false,
					dupleCheck: false,
					mode: '',
				}
			},
		},
		submitMode: {
			type: Boolean,
			default: false,
		},
		quaterCheck: {
			type: Object,
			default: () => {
				return {
					isQuater: false,
					nextDate: '',
				}
			},
		},
	},
	data() {
		return {
			validateOption: {
				pwValidate: true,
				rePwValidate: true,
			},
			passwordValue: {
				oldPassword: '',
				newPassword: '',
				passwordCheck: '',
			},
			findContentValue: {
				userId: '',
				email: '',
				userName: '',
			},
			nextQuaterCheck: false,
			options: {
				animationData: animationData,
				loop: false,
			},
			successMessage: '',
		}
	},
	setup() {},
	created() {},
	mounted() {
		this.initOption()
	},
	unmounted() {},
	methods: {
		initOption() {
			this.validateOption = {
				pwValidate: true,
				rePwValidate: true,
			}
			this.passwordValue = {
				oldPassword: '',
				newPassword: '',
				passwordCheck: '',
			}
			this.findContentValue = {
				userId: '',
				email: '',
				userName: '',
			}
			this.nextQuaterCheck = false
		},
		closeBtnHandler() {
			if (this.nextQuaterCheck) {
				this.nextQuaterUpdate()
			} else {
				this.$emit('closehandler')
			}
			this.initOption()
		},
		passwordCountinutyChecker(password) {
			let firstCnt = 0
			let secondCnt = 0

			for (let i = 0; i < password.length - 2; i++) {
				const temp_1 = password.charAt(i)
				const temp_2 = password.charAt(i + 1)
				const temp_3 = password.charAt(i + 2)
				if (temp_1 === temp_2 && temp_2 === temp_3) {
					return false
				}
				if (
					temp_1.charCodeAt(0) - temp_2.charCodeAt(0) === 1 &&
					temp_2.charCodeAt(0) - temp_3.charCodeAt(0) === 1
				) {
					firstCnt++
				}
				if (
					temp_1.charCodeAt(0) - temp_2.charCodeAt(0) === -1 &&
					temp_2.charCodeAt(0) - temp_3.charCodeAt(0) === -1
				) {
					secondCnt++
				}
			}

			if (firstCnt > 0 || secondCnt > 0) {
				return false
			} else {
				return true
			}
		},
		passwordChecker(password) {
			const regex =
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/
			const wordCheck = this.passwordCountinutyChecker(password)
			const spaceRegex = /\s/g
			const check = regex.test(password) && wordCheck
			const spaceCheck = spaceRegex.test(password)

			if (spaceCheck) {
				this.validateOption.pwValidate = false
			} else if (password?.length > 0) {
				this.validateOption.pwValidate = check
			}
		},
		recheckPassword(password) {
			const checkTarget = this.passwordValue.newPassword === password
			if (!checkTarget) {
				this.validateOption.rePwValidate = false
			} else {
				this.validateOption.rePwValidate = true
			}
		},
		nextQuaterUpdate() {
			const apiParams = {
				userID: sessionStorage.getItem('access_id'),
			}
			nextUpdateQuaterAPI(apiParams).then(res => {
				if (res.data.success) {
					this.$emit('closehandler')
				}
			})
		},
		updateUser() {
			const params = {}
			switch (this.dialogOption.mode) {
				case 'update':
					params.newPassword = this.passwordValue.newPassword
					params.checkPassword = this.passwordValue.passwordCheck
					params.oldPassword = this.passwordValue.oldPassword
					break
				case 'findPw':
					params.userID = this.findContentValue.userId
					params.email = this.findContentValue.email
					break
				case 'findId':
					params.userName = this.findContentValue.userName
					params.email = this.findContentValue.email
			}
			this.$emit('eventHandler', params)
			// this.initOption();
		},
	},
	computed: {
		...mapGetters('globalStore', ['getDialogSuccess']),
		disableChecker() {
			let disableCheck = true
			switch (this.dialogOption.mode) {
				case 'update':
					disableCheck =
						this.validateOption.pwValidate &&
						this.validateOption.rePwValidate &&
						this.passwordValue.newPassword.length > 0 &&
						this.passwordValue.passwordCheck.length > 0
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.successMessage = '변경이 완료되었습니다.'
					break
				case 'findPw':
					disableCheck =
						this.findContentValue.userId.length > 0 &&
						this.findContentValue.email.length > 0
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.successMessage = '전송이 완료되었습니다.'
					break
				case 'findId':
					disableCheck =
						this.findContentValue.userName.length > 0 &&
						this.findContentValue.email.length > 0
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.successMessage = '조회가 완료되었습니다.'
					break
			}
			return disableCheck
		},
	},
	watch: {
		'passwordValue.newPassword': _.debounce(function (value) {
			this.passwordChecker(value)
		}, 100),
		'passwordValue.passwordCheck': _.debounce(function (value) {
			this.recheckPassword(value)
		}, 100),
	},
}
</script>
