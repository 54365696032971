import { render, staticRenderFns } from "./SignReport.vue?vue&type=template&id=22722ecc&scoped=true&"
import script from "./SignReport.vue?vue&type=script&lang=js&"
export * from "./SignReport.vue?vue&type=script&lang=js&"
import style0 from "./SignReport.vue?vue&type=style&index=0&id=22722ecc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22722ecc",
  null
  
)

export default component.exports