<template>
	<div class="gaugechart-wrap">
		<div class="meteringGaugeChart" ref="chartRender"></div>
	</div>
</template>

<script>
require('echarts/theme/infographic')
import * as echarts from 'echarts'
export default {
	props: {
		echartData: {
			type: Object,
			default() {
				return {}
			},
		},
	},
	components: {},
	data() {
		return {
			chart: null,
		}
	},
	setup() {},
	created() {},
	mounted() {
		this.renderChart(this.echartData)
	},
	unmounted() {},
	methods: {
		renderChart(option) {
			if (this.chart !== null) {
				this.chart.clear()
			}
			this.chart = echarts.init(this.$refs.chartRender, 'infographic')
			this.chart.setOption(option)
		},
	},
	watch: {
		echartData(value) {
			this.renderChart(value)
		},
	},
}
</script>
