<template>
  <div class="main">
    <section ref="fullpage" :options="fullPageOptions" id="fullpage">
      <div class="tab1 section">
        <div class="leftPanel">
          <div class="lTopPanel">
            <h1>스마트 교차로 통계</h1>
            <div class="rightBox">
              <p>
                <v-menu
                  v-model="config.datePicker.target"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="targetDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="targetDate"
                    @input="config.datePicker.target = false"
                  ></v-date-picker>
                </v-menu>
              </p>
            </div>
          </div>
          <div class="lMidPanel">
            <p class="type01">
              <span class="head"> 교통량 </span>
              <span>{{ this.info.totalTraffic | comma }}</span
              ><span>대</span>
            </p>
            <p class="type02">
              <span class="head"> 제어지체 </span>
              <span>평균 {{ this.info.avgDelay }}</span
              ><span>초</span>
            </p>
            <p class="type03">
              <span class="head dualHead"> 신호위반 </span>
              <span class="dualBody">
                <table>
                  <colgroup>
                    <col width="25%" />
                    <col width="25%" />
                    <col width="25%" />
                    <col width="25%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th class="type01">자동차</th>
                      <th class="type02">오토바이</th>
                      <th class="type03">꼬리물기</th>
                      <th class="type04">정지선위반</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td v-for="(item, idx) in info.violation" :key="idx">
                        {{ item | comma }}대
                      </td>
                    </tr>
                  </tbody>
                </table>
              </span>
            </p>
            <p class="type04">
              <span class="head dualHead"> 서비스 수준 </span>
              <span class="dualBody">
                <table>
                  <colgroup>
                    <col width="12.5%" />
                    <col width="12.5%" />
                    <col width="12.5%" />
                    <col width="12.5%" />
                    <col width="12.5%" />
                    <col width="12.5%" />
                    <col width="12.5%" />
                    <col width="12.5%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>A</th>
                      <th>B</th>
                      <th>C</th>
                      <th>D</th>
                      <th>E</th>
                      <th>F</th>
                      <th>FF</th>
                      <th>FFF</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td v-for="(item, idx) in info.los" :key="idx">
                        {{ item }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </span>
            </p>
          </div>
          <div class="lBotPanel">
            <div class="lBotPanel-sub">
              <p>
                <skeleton-graph v-if="isLoading" />
                <globalChart
                  v-show="!isLoading"
                  :chartData="this.chart.traffic"
                ></globalChart>
              </p>
              <p>
                <skeleton-graph v-if="isLoading" />
                <globalChart
                  v-show="!isLoading"
                  :chartData="this.chart.trafficByCarModel"
                ></globalChart>
              </p>
              <p>
                <!-- <div v-if="!isLoading">test</div> -->
                <skeleton-graph v-if="isLoading" />
                <globalChart
                  v-show="!isLoading"
                  :chartData="this.chart.delayOnLOS"
                />
              </p>
            </div>
            <label class="updateTime">업데이트 : {{ this.updateTime }}</label>
          </div>
        </div>
        <div class="rightPanel">
          <div class="rTopPanel">
            <h2>스마트 교차로</h2>
            <h1>장비 모니터링</h1>
            <table>
              <colgroup>
                <col width="40%" />
                <col width="30%" />
                <col width="30%" />
              </colgroup>
              <thead>
                <tr>
                  <th></th>
                  <th>정상</th>
                  <th>비정상</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in currentSytem" :key="idx">
                  <td>
                    <span><img :src="item.src" /></span>
                    <span>{{ item.text }}</span>
                  </td>
                  <td>{{ item.sCount }}개</td>
                  <td>{{ item.fCount }}개</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="splitLine"></div>
          <div class="rBotPanel">
            <table>
              <colgroup>
                <col width="40%" />
                <col width="60%" />
              </colgroup>
              <thead>
                <tr>
                  <th>발생시간</th>
                  <th>내용</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in systemLog" :key="idx">
                  <td>{{ item.registeredDate }}</td>
                  <td
                    class="textRight"
                    :class="{ conn: item.conn, disConn: item.disConn }"
                  >
                    {{ item.description }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="tab2 section">
        <div class="leftPanel" :class="{ active: classIf.panel.leftFold }">
          <intersectionList
            @update="updateIntersection"
            @select="selectIntersection"
            ref="inter"
          ></intersectionList>
          <span
            class="fold"
            @click="classIf.panel.leftFold = !classIf.panel.leftFold"
            >{{ classIf.panel.leftFold ? "&gt;&gt;" : "&lt;&lt;" }}</span
          >
        </div>
        <div class="midPanel" @wheel="mapWheel">
          <div class="map">
            <mapComponent
              :intersection="this.intersection"
              :intersectionSeq="this.intersectionSeq"
              ref="map"
              @select="changeIntersection"
            ></mapComponent>
          </div>
        </div>
        <div class="rightPanel" :class="{ active: classIf.panel.rightFold }">
          <div class="rTopPanel">
            <viewDataList
              title="교차로 상세 분석 차트"
              type="0"
              @select="selectAnalysis"
            ></viewDataList>
          </div>
          <div class="rBotPanel">
            <p class="datePanel">
              <span>조회 기간</span>
              <v-menu
                v-model="config.datePicker.start"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @input="config.datePicker.start = false"
                ></v-date-picker>
              </v-menu>
            </p>
            <!-- <p class="category">
            <span>전체화면</span>
            <span>테이블보기</span>
            <span>화면캡쳐</span>
            <span>다운로드</span>
          </p> -->
            <p class="category2">
              <input
                type="radio"
                name="time"
                id="hour"
                value="3"
                v-model="unit"
              />
              <label for="hour">1시간</label>
              <input
                type="radio"
                name="time"
                id="minute"
                value="2"
                v-model="unit"
              />
              <label for="minute">15분</label>
              <input
                type="radio"
                name="time"
                id="cycle"
                value="1"
                v-model="unit"
              />
              <label for="cycle">주기</label>
            </p>
            <p class="chartPanel">
              <globalChart :chartData="this.chartData"></globalChart>
            </p>
          </div>
          <span
            class="fold"
            @click="classIf.panel.rightFold = !classIf.panel.rightFold"
            >{{ classIf.panel.rightFold ? "&lt;&lt;" : "&gt;&gt;" }}</span
          >
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import intersectionList from "@/components/Tree/IntersectionList.vue";
import viewDataList from "@/components/Tree/ViewDataList.vue";
import globalChart from "@/components/Chart/GlobalChart.vue";
import mapComponent from "@/components/Gis/Map.vue";
import SkeletonGraph from "../../components/SkeletonGraph.vue";
import { mapGetters } from "vuex";
import { getMainChartAPI, getDashBoardInfoAPI } from "../../api/trafficAPI";
import { getMapChartDataAPI } from "../../api/index";

const globalStore = "globalStore";

export default {
  data() {
    return {
      inMove: false,
      inMoveDelay: 600,
      autoReader: null,
      fullPageOptions: {
        licenseKey: "8AE749BC-876E4BB3-99A2C21F-848A46A4",
        autoScrolling: true,
        scrollHorizontally: true,
      },
      startDate: "2023-11-16",
      endDate: "2023-11-16",
      picker: {
        start: false,
        end: false,
      },
      config: {
        datePicker: {
          target: false,
          start: false,
          end: false,
        },
      },
      classIf: {
        panel: {
          leftFold: false,
          rightFold: false,
        },
      },
      analysis: 1,
      unit: 3,
      info: {
        totalTraffic: "",
        avgDelay: "",
        violation: [0, 0, 0, 0],
        los: [0, 0, 0, 0, 0, 0, 0, 0],
      },
      chart: {
        traffic: null,
        trafficByCarModel: null,
        delayOnLOS: null,
      },
      currentSytem: [
        {
          src: require("@/assets/images/icon/main/Main_W_Camera.png"),
          text: "카메라 통신",
          sCount: 0,
          fCount: 0,
        },
        {
          src: require("@/assets/images/icon/main/Main_W_Controller.png"),
          text: "제어기 통신",
          sCount: 0,
          fCount: 0,
        },
        {
          src: require("@/assets/images/icon/main/Main_W_Optic.png"),
          text: "광전송 장비",
          sCount: 0,
          fCount: 0,
        },
        {
          src: require("@/assets/images/icon/main/Main_W_Device.png"),
          text: "상태감시장치",
          sCount: 0,
          fCount: 0,
        },
      ],
      systemLog: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      categoryType: {
        1: "제어기",
        2: "광전송 장비",
        3: "상태감시장치",
        4: "카메라",
        5: "LOS",
        6: "",
        11: "신호제어기",
      },
      intersectionSeq: 1,
      intersection: [],
      avenueList: [],
      // targetDate : new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0,10),,
      targetDate: "2023-11-16",
      updateTime: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 19)
        .replace("T", " "),
      vScroll: {
        isMoving: false,
        scrollY: 0,
      },
      chartData: {
        chartType: "barChart",
        data: [],
      },
      isLoading: false,
    };
  },
  components: {
    intersectionList,
    viewDataList,
    globalChart,
    mapComponent,
    SkeletonGraph,
  },
  created() {},
  beforeMount() {},
  mounted() {
    window.scrollTo(0, 0);
    window.addEventListener("mousewheel", this.scrollTopOrBottom, {
      passive: false,
    });
    if (this.getHostName !== "tmaas") {
      this.init(this.isLoading);
      this.initInput();
      let vueF = this;
      this.autoReader = window.setInterval(function () {
        vueF.init();
      }, 30000);
      this.getData();
    } else {
      return;
    }
  },
  destroyed() {
    clearInterval(this.autoReader);
    window.removeEventListener("mousewheel", this.scrollTopOrBottom, {
      passive: false,
    });
  },
  methods: {
    init(loadingStatus) {
      if (this.getHostName === "tmaas") {
        return;
      }
      this.getMainInfo();
      this.getMainChart(loadingStatus);
      this.updateTime = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 19)
        .replace("T", " ");
    },
    scrollTopOrBottom(event) {
      if (event.wheelDelta < 30 && !this.inMove) {
        this.scrollDown();
      } else if (event.wheelDelta > 30 && !this.inMove) {
        this.scrollUp();
      }
      event.preventDefault();
      return false;
      // window.scrollTo({
      //   top: 0,
      //   left: 0,
      //   behavior: "smooth",
      // });
    },
    scrollDown() {
      if (window.scrollY > 1000) {
        return false;
      }
      this.inMove = true;
      window.scrollTo({
        top: 1100,
        left: 0,
        behavior: "smooth",
      });
      setTimeout(() => {
        this.inMove = false;
      }, this.inMoveDelay);
    },
    scrollUp() {
      if (window.scrollY <= 0) {
        return false;
      }
      this.inMove = true;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      setTimeout(() => {
        this.inMove = false;
      }, this.inMoveDelay);
    },
    getMainInfo() {
      const startDate = this.targetDate;
      const endDate = this.targetDate + " 23:59:59";
      const tokenRefresh = false;
      getDashBoardInfoAPI(startDate, endDate, tokenRefresh).then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          data[0].forEach((el) => {
            this.info.totalTraffic = el["Count"];
          });
          data[1].forEach((el) => {
            this.info.avgDelay = el["Delay"];
          });
          data[2].forEach((el) => {
            this.info.violation[el["Category"] - 1] = el["Count"];
          });
          var temp = { A: 0, B: 1, C: 2, D: 3, E: 4, F: 5, FF: 6, FFF: 7 };
          data[3].forEach((el) => {
            this.info.los[temp[el["LevelOfService"]]] = el["Count"];
          });

          // data[4].unshift(data[4].splice(3, 1)[0])
          data[4].forEach((el, idx) => {
            if (el && el.Category) {
              this.currentSytem[idx].sCount = el.IsConnTrue;
              this.currentSytem[idx].fCount = el.IsConnFalse;
            }
          });

          this.systemLog = [];
          data[5].forEach((el) => {
            let rowLog = {
              registeredDate: el.RegisteredDate,
              description: `${el.IntersectionName ? el.IntersectionName : ""} ${
                this.categoryType[el.Category]
              } ${el.Description}`,
              conn: el.Description.indexOf("확인") !== -1 ? true : false,
              disConn: el.Description.indexOf("없음") !== -1 ? true : false,
            };
            this.systemLog.push(rowLog);
          });

          this.avenueList = data[6];
        }
      });
    },
    initInput() {
      this.dialogOption = {
        title: "",
        body: "",
        validate: false,
        dupleCheck: false,
        mode: "",
      };
    },
    getMainChart(loadingStatus) {
      const startDate = this.targetDate;
      const endDate = this.targetDate + " 23:59:59";
      if (loadingStatus !== undefined) {
        this.isLoading = true;
      }
      getMainChartAPI(startDate, endDate).then((res) => {
        if (loadingStatus !== undefined) {
          this.isLoading = false;
        }
        if (res.avenueData?.length > 0) {
          this.chart.traffic = {
            title: "교차로 접근로 교통량",
            chartType: "stackChart",
            unit: 4,
            chartNum: 0,
            legend: [
              "북    ",
              "동   ",
              "남   ",
              "서",
              "북동",
              "남동",
              "남서",
              "북서",
            ],
            data: res.avenueData,
          };
        }

        if (res.carModelData?.length > 0) {
          this.chart.trafficByCarModel = {
            title: "교차로 차종 교통량",
            chartType: "stackChart",
            unit: 4,
            chartNum: 0,
            legend: [
              "승용차    ",
              "버스      ",
              "소형트럭",
              "대형트럭",
              "특수차   ",
              "오토바이",
            ],
            data: res.carModelData,
          };
        }

        if (res.delayData?.length > 0) {
          this.chart.delayOnLOS = {
            title: "제어지체 및 서비스 수준(LOS)",
            chartType: "losChart",
            unit: 4,
            chartNum: 0,
            data: res.delayData,
          };
        }
      });
    },
    getData() {
      if (this.getHostName === "tmaas") {
        return;
      }
      let path = "";
      let url = "";
      let title = "교통량";
      let chartType = "barChart";
      let chartNum = 0;
      let legend = [];
      let label = [];
      let checked = "checked";

      switch (this.analysis) {
        case 1:
          url = "each/traffic";
          chartType = "barChart2";
          title = "교차로 교통량";
          break;
        case 2:
          url = "each/trafficByAvenue";
          chartType = "lineChart";
          title = "접근로별 교통량";
          this.avenueList.forEach((el) => {
            if (el.IntersectionSeq === this.intersectionSeq)
              legend.push(el.AvenueName);
          });
          break;
        case 3:
          url = "each/trafficByCarModel";
          chartType = "lineChart";
          title = "차종분류";
          legend = [
            "승용차",
            "버스",
            "소형트럭",
            "대형트럭",
            "특수차",
            "오토바이",
          ];
          break;
        case 4:
          url = "each/trafficByDelay";
          chartType = "stepLineChart";
          title = "교차로 제어지체";
          break;
        case 5:
          url = "each/trafficByAvenueDelay";
          chartType = "stepLineChart";
          title = "접근로 제어지체";
          this.avenueList.forEach((el) => {
            if (el.IntersectionSeq === this.intersectionSeq)
              label.push(el.AvenueName);
          });
          break;
        case 6:
          url = "each/trafficByDelay";
          chartType = "stepLineChart";
          title = "교차로 서비스 수준";
          break;
        case 7:
          url = "each/trafficByAvenueDelay";
          chartType = "stepLineChart";
          title = "접근로 서비스 수준";
          this.avenueList.forEach((el) => {
            if (el.IntersectionSeq === this.intersectionSeq)
              label.push(el.AvenueName);
          });
          break;
      }

      if (
        parseInt(this.unit) === 3 ||
        parseInt(this.unit) === 2 ||
        parseInt(this.unit) === 1
      ) {
        if (url === "avenue/cycleData") {
          path = `/api/signal/analysis/${url}`;
        } else {
          path = `/api/traffic/analysis/${url}`;
        }
      }

      if (
        this.analysis === 4 ||
        this.analysis === 5 ||
        this.analysis === 6 ||
        this.analysis === 7
      ) {
        path = `api/los/analysis/${url}`;
      }
      const intersectionParams =
        this.intersectionSeq === 0 ? 1 : this.intersectionSeq;
      const unitParams = parseInt(this.unit);
      const startDateParams = this.startDate;
      const endDateParams =
        parseInt(this.unit) === 1
          ? this.start + " 00:59:59"
          : this.startDate + " 23:59:59";
      getMapChartDataAPI(
        intersectionParams,
        unitParams,
        startDateParams,
        endDateParams,
        path
      )
        .then((res) => {
          if (res.data.success) {
            let data = res.data.data;
            this.chartData = {
              title: title,
              chartType: chartType,
              unit: parseInt(this.unit),
              cahrtNum: chartNum,
              data: data,
              legend: legend,
              label: label,
              checked: checked,
            };
          }
        })
        .catch(function (error) {
          alert("error :", error);
        });
    },

    selectAnalysis(e) {
      this.analysis = e;
    },
    updateIntersection(e) {
      this.intersection = e;
    },
    selectIntersection(e) {
      this.intersectionSeq = e.IntersectionSeq;
      this.$refs.map.changeCenter(e.IntersectionSeq);
    },
    changeIntersection(e) {
      this.$refs.inter.changeIntersection(e);
      this.intersectionSeq = e;
    },
    mapWheel(e) {
      e.preventDefault();
      e.stopPropagation();
    },
  },
  computed: {
    ...mapGetters(globalStore, ["getHostName"]),
  },
  watch: {
    intersectionSeq: function () {
      this.getData();
    },
    analysis: function () {
      this.getData();
    },
    unit: function () {
      this.getData();
    },
    startDate: function () {
      this.getData();
    },
    targetDate: function () {
      this.init();
    },
  },
};
</script>
