<template>
  <seciton class="policy-body">
    <article class="policy-main">
      <div class="policy-title__wrapper">
        <div class="title-text__wrapper">
          <div class="title-header__text">
            <p>TMaaS</p>
            <h1>개인 정보 이용 내역</h1>
          </div>
          <p>
            "정보 통신망 이용 촉진 및 정보보호 등에 관한 개인정보 보호법
            제39조의8 (개인정보 이용내역 통지)"에 의거하여 TMaaS 고객님의 개인
            정보 이용 내역을 다음과 같이 안내드립니다.
          </p>
        </div>
      </div>
      <div class="policy-table__wrapper">
        <h2>개인정보 수집·이용 항목 및 목적</h2>
        <p>
          * TMaaS는 수집한 고객님의 개인정보를 다음의 목적을 위해 활용합니다
        </p>
        <table>
          <th class="th-first" colspan="2">수집목적</th>
          <th colspan="2">수집항목</th>
          <tr>
            <td rowspan="2">회원관리</td>
            <td rowspan="2">TMaaS 서비스 회원 생성</td>
            <td>필수</td>
            <td>성명, 단체명, 아이디, 비밀번호, 이메일</td>
          </tr>
          <tr>
            <td>필수</td>
            <td>TMaaS 서비스 관리자 전화번호</td>
          </tr>
          <tr>
            <td rowspan="2">재화 또는 서비스 제공</td>
            <td>환불 계좌 관리</td>
            <td>선택</td>
            <td>계좌번호, 은행명, 예금주명</td>
          </tr>
          <tr>
            <td>안내사항 고지</td>
            <td>필수</td>
            <td>이메일</td>
          </tr>
        </table>
      </div>
      <div class="policy-detail__wrapper">
        <h2>세부 사항</h2>
        <div class="policy-detail__buttons">
          <button class="privacy-button" @click="() => openPdf('privacy')">개인정보 처리방침</button>
          <button class="term-button" @click="() => openPdf('term')">이용약관</button>
        </div>
      </div>
      <div class="policy-footer__wrapper">
        <span>
          * TMaaS는 고객님의 개인정보를 소중히 여기고 관련법규에 따라 안전하게
          보호합니다.
        </span>
      </div>
    </article>
  </seciton>
</template>
<script>
export default {
  name: "Policy",
  components: {},
  data() {
    return {
      sampleData: "",
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    openPdf(mode) {
      let pdfPage;
      let title;
      switch (mode) {
        case "privacy":
          pdfPage = window.open("/storage/privacy_policy.pdf");
          title = "개인정보 처리방침";
          break;
        case "term":
          pdfPage = window.open("/storage/terms_of_use.pdf");
          title = "이용약관";
          break;
        // no default
      }
      window.setTimeout(() => {
        pdfPage.document.title = title;
      }, 100);
    },
  },
};
</script>
<style scoped>
.policy-body {
  display: flex;
  justify-content: center;
}

h1,
.title-header__text > p {
  font-size: 2.5rem;
  font-weight: bold;
}

.title-header__text > p {
  color: #39a5d9;
}

.policy-main {
  width: 50rem;
  padding: 3rem 0;
}

.policy-title__wrapper {
  display: flex;
  border-bottom: 1px solid #fff;
  margin-bottom: 2rem;
}

.policy-title__wrapper img {
  width: 30%;
}

.title-text__wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.title-text__wrapper > p {
  font-size: 1.1rem;
  line-height: 130%;
  margin-bottom: 2rem;
}

td {
  padding: 1rem;
}

th {
  font-weight: bold;
  background-color: #5e5e5e;
}

table,
th {
  border: 1px solid #444;
}

.policy-table__wrapper {
  margin-bottom: 2rem;
}

.policy-detail__wrapper > h2,
.policy-table__wrapper > h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.policy-detail__wrapper {
  margin-bottom: 2rem;
}

.policy-detail__buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.policy-detail__buttons button {
  border-radius: 6px;
  padding: 1.2rem;
  font-weight: bold;
  font-size: 1.125rem;
}

.policy-detail__buttons button:hover {
  opacity: 0.6;
}

.privacy-button {
  background-color: #525fe1;
}

.term-button {
  background-color: #525fe1;
}

.policy-footer__wrapper {
  padding: 3rem 2rem;
  background-color: #2d2e2f;
}
</style>
