<template>
  <div class="sqlPanel">
    <h1>Sign up</h1>
    <label for="id">아이디</label>
    <div class="input_row">
      <input type="text" id="id" v-model="user.userId" />
    </div>
    <label for="name">이름</label>
    <div class="input_row">
      <input type="text" id="name" v-model="user.name" />
    </div>
    <label for="password">비밀번호</label>
    <div class="input_row">
      <input
        type="password"
        id="password"
        v-model="user.password"
        v-on:keyup.enter="signUp"
      />
    </div>
    <button v-on:click="signUp">가입하기</button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
const globalStore = "globalStore";

export default {
  created() {
    // 컴포넌트가 생성될 때, /api/movies에 요청을 보냅니다.
    // this.$http.get(`/api/movies`)
    //     .then((response) => {
    //       this.movies = response.data
    //     })
  },
  data() {
    return {
      user: {
        userId: "",
        name: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapGetters(globalStore, [`getHostName`]),
  },
  methods: {
    signUp() {
      axios
        .post(`/api/traffic/user/signUp`, {
          user: this.user,
          Region: this.getHostName,
        })
        .then((res) => {
          if (res.data.success == true) {
            alert(res.data.message);
            this.$router.push("/login");
          }
          if (res.data.success == false) {
            alert(res.data.message);
          }
        })
        .catch(function (error) {
          alert("error :", error);
        });
    },
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
  color: #f0ebd8;
  margin-bottom: 50px;
  font-style: italic;
  font-size: 30px;
  font-family: arial;
  letter-spacing: 1px;
}
.sqlPanel {
  width: 400px;
  height: 300px;
  color: #f0ebd8;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -150px;
  margin-left: -200px;
}
.sqlPanel label {
  font-size: 10px;
}
.sqlPanel input {
  background: none;
  height: 30px;
  color: #f0ebd8;
  width: 100%;
}
.sqlPanel input:focus {
  border-color: none;
  box-shadow: none;
  outline: none;
  outline-color: none;
}
.sqlPanel button {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  background: #748cab;
  border: none;
  margin-top: 10px;
}
.sqlPanel button:hover {
  background: #3e5c76;
  cursor: pointer;
}
.input_row {
  border-bottom: 1px solid #748cab;
  margin-bottom: 10px;
}
.input_row span {
  width: 15%;
  display: inline-block;
}
</style>
